import { useState, useEffect } from 'react';
import { 
  Box,
  Grid, 
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { WEBSITE_NAME, getThumbnailPath } from "../App";
import { getLocale } from '../i18n/locales';
import { getActors, getVideos } from '../utils/api'; // Add getVideos import

function ActorsPage() {
  const [actors, setActors] = useState([]);
  const [videos, setVideos] = useState([]); // Add videos state
  const [sortBy, setSortBy] = useState('relevance');
  const navigate = useNavigate();
  const { lng } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch both actors and videos data
        const [actorsData, videosData] = await Promise.all([
          getActors(),
          getVideos()
        ]);
        setActors(actorsData);
        setVideos(videosData);
      } catch (error) {
        console.error('Error loading data:', error);
        setActors([]);
        setVideos([]);
      }
    };

    fetchData();
  }, []);

  // Add this function to count videos per actor
  const getActorVideoCount = (actorId) => {
    return videos.filter(video => video.actors && video.actors.includes(actorId)).length;
  };

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  const handleActorClick = (actor) => {
    const slug = generateSlug(actor.name);
    navigate(`/${lng}/actor/${slug}`);
  };

  const calculateAge = (birthDate) => {
    if (!birthDate) return null;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const getSortedActors = () => {
    // First separate actors by gender (default to female/1 if not specified)
    const femaleActors = actors.filter(actor => !actor.gender || actor.gender === 1);
    const maleActors = actors.filter(actor => actor.gender === 2);
  
    // Sort each group according to selected sort option
    const sortFunction = (a, b) => {
      switch (sortBy) {
        case 'age-desc':
          const ageA1 = calculateAge(a.birthDate) || 0;
          const ageB1 = calculateAge(b.birthDate) || 0;
          return ageB1 - ageA1;
        case 'age-asc':
          const ageA2 = calculateAge(a.birthDate) || 0;
          const ageB2 = calculateAge(b.birthDate) || 0;
          return ageA2 - ageB2;
        case 'name':
          return a.name.localeCompare(b.name);
        case 'relevance':
        default:
          return a.id - b.id;
      }
    };
  
    const sortedFemaleActors = [...femaleActors].sort(sortFunction);
    const sortedMaleActors = [...maleActors].sort(sortFunction);
  
    return { femaleActors: sortedFemaleActors, maleActors: sortedMaleActors };
  };

  // Create a reusable function for rendering actor info
  const renderActorInfo = (actor) => {
    const age = actor.birthDate ? `${calculateAge(actor.birthDate)} ${t('actors.yearsOld')}` : t('actors.noInformation');
    const videoCount = getActorVideoCount(actor.id);
    const yearsActive = actor.yearsActive ? `${actor.yearsActive}` : '';
    
    let infoText = age;
    
    // Add dot separator and video count if there are videos
    if (videoCount > 0) {
      infoText += ` • ${videoCount} ${videoCount === 1 ? t('nav.videos') : t('nav.videos')}`;
    }
    
    // Add years active with separator if it exists
    if (yearsActive) {
      infoText += ` • ${yearsActive}`;
    }
    
    return infoText;
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Helmet>
        <title>{`${t('meta.actors.title')}`}</title>
        <meta name="description" content={t('meta.actors.description')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.actors.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.actors.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.actors.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.actors.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.actors.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.actors.description'),
            "numberOfItems": actors.length,
            "hasPart": actors.slice(0, 10).map(actor => ({
              "@type": "Person",
              "name": actor.name,
              "image": `${window.location.origin}${actor.photo}`,
              ...(actor.birthDate && { "birthDate": actor.birthDate }),
              "gender": actor.gender === 1 ? "Female" : "Male"
            }))
          })}
        </script>
      </Helmet>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile, row on larger screens
        gap: { xs: 2, sm: 0 }, // Add gap between elements on mobile
        justifyContent: { sm: 'space-between' }, 
        alignItems: { xs: 'stretch', sm: 'center' },
        mb: 3 
      }}>
        <Typography 
          variant="h4" 
          sx={{
            fontSize: { xs: '1.5rem', sm: '2.125rem' }, // Smaller font on mobile
            mb: { xs: 0, sm: 1.5 } // Remove bottom margin on mobile
          }}
        >
          {t('nav.actors')}
        </Typography>
        
        <FormControl 
          size="small" 
          sx={{ 
            minWidth: { xs: '100%', sm: 200 } // Full width on mobile
          }}
        >
          <Select
            value={sortBy}
            onChange={handleSortChange}
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              '& .MuiSelect-icon': { color: 'text.secondary' }
            }}
          >
            <MenuItem value="relevance">{t('actors.sortOptions.relevance')}</MenuItem>
            <MenuItem value="name">{t('actors.sortOptions.alphabetical')}</MenuItem>
            <MenuItem value="age-desc">{t('actors.sortOptions.oldestFirst')}</MenuItem>
            <MenuItem value="age-asc">{t('actors.sortOptions.youngestFirst')}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Female Actors Section */}
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
        {t('actors.female')}
      </Typography>
      <Grid container spacing={3}>
        {getSortedActors().femaleActors.map((actor) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={actor.id}>
            <Card>
              <CardActionArea
                component="a" 
                href={`/${lng}/actor/${generateSlug(actor.name)}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleActorClick(actor);
                }}
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <CardMedia
                  component="img"
                  image={getThumbnailPath(actor.photo)}
                  alt={actor.name}
                  sx={{
                    width: '100%',
                    aspectRatio: '3/4',
                    objectFit: 'cover'
                  }}
                />
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {actor.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {renderActorInfo(actor)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Male Actors Section */}
      {getSortedActors().maleActors.length > 0 && (
        <>
          <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
            {t('actors.male')}
          </Typography>
          <Grid container spacing={3}>
            {getSortedActors().maleActors.map((actor) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={actor.id}>
                <Card>
                  <CardActionArea
                    component="a" 
                    href={`/${lng}/actor/${generateSlug(actor.name)}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleActorClick(actor);
                    }}
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={getThumbnailPath(actor.photo)}
                      alt={actor.name}
                      sx={{
                        width: '100%',
                        aspectRatio: '3/4',
                        objectFit: 'cover'
                      }}
                    />
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {actor.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {renderActorInfo(actor)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

export default ActorsPage;