import React, { useState, useRef, useEffect } from 'react';
import { 
  Box,
  Typography,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  useTheme,
  Avatar
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ImageIcon from '@mui/icons-material/Image';
import TextsmsIcon from '@mui/icons-material/Textsms';
import VideocamIcon from '@mui/icons-material/Videocam';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { getThumbnailPath } from '../App';

const OnlyFansRow = ({ 
  title,
  posts,
  actors,
  lng,
  itemWidth = 300,
  itemsToShow = 4
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const [hoveredPost, setHoveredPost] = useState(null);
  const [favorites, setFavorites] = useState({});
  const containerRef = useRef(null);
  
  // Load favorites from localStorage
  useEffect(() => {
    const favoriteOnlyFansPosts = JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');
    const favoriteMap = {};
    favoriteOnlyFansPosts.forEach(id => {
      favoriteMap[id] = true;
    });
    setFavorites(favoriteMap);
  }, []);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = itemWidth * (direction === 'left' ? -1 : 1);
      const newPosition = container.scrollLeft + scrollAmount;
      
      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
    }
  };

  const checkScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      const hasHorizontalScroll = container.scrollWidth > container.clientWidth;
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(hasHorizontalScroll && container.scrollLeft < container.scrollWidth - container.clientWidth - 1);
    }
  };

  useEffect(() => {
    checkScrollButtons();
  }, [posts]);

  const handleActorClick = (actorId, event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const actor = actors.find(a => a.id === actorId);
    if (actor) {
      const slug = actor.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      navigate(`/${lng}/actor/${slug}`);
    }
  };

  const handlePostClick = (post) => {
    // Find the actor for this post
    const actor = actors.find(a => a.id === post.user_id);
    if (actor) {
      // Generate actor slug
      const actorSlug = actor.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      // Navigate to the correct URL format
      navigate(`/${lng}/onlyfans/${actorSlug}/${post.id}`);
    } else {
      // Fallback in case actor is not found
      navigate(`/${lng}/onlyfans/posts/${post.id}`);
    }
  };
  
  const handleFavoriteToggle = (postId, event) => {
    event.stopPropagation();
    
    const favoriteOnlyFansPosts = JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');
    let newFavorites;
    
    if (favorites[postId]) {
      newFavorites = favoriteOnlyFansPosts.filter(id => id !== postId);
    } else {
      newFavorites = [...favoriteOnlyFansPosts, postId];
    }
    
    localStorage.setItem('favoriteOnlyFansPosts', JSON.stringify(newFavorites));
    
    // Update state
    setFavorites(prevFavorites => ({
      ...prevFavorites,
      [postId]: !prevFavorites[postId]
    }));
    
    // Dispatch storage event for other tabs
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'favoriteOnlyFansPosts',
      newValue: JSON.stringify(newFavorites)
    }));
  };

  const getPostPreview = (post) => {
    if (post.videos && post.videos.length > 0) {
      // Video post
      const thumbnail = post.thumbnail ? getThumbnailPath(post.thumbnail) : null;
      return (
        <Box sx={{ position: 'relative', paddingTop: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          {thumbnail ? (
            <CardMedia
              component="img"
              image={thumbnail}
              alt={`OnlyFans post ${post.id}`}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
              }}
            >
              <VideocamIcon sx={{ fontSize: 60, color: 'rgba(255, 255, 255, 0.7)' }} />
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              borderRadius: '50%',
              width: 36,
              height: 36,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <VideocamIcon />
          </Box>
        </Box>
      );
    } else if (post.images && post.images.length > 0) {
      // Image post
      return (
        <Box sx={{ position: 'relative', paddingTop: '100%' }}>
          <CardMedia
            component="img"
            image={getThumbnailPath(post.images[0])}
            alt={`OnlyFans post ${post.id}`}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
          {post.images.length > 1 && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: 'white',
                borderRadius: '50%',
                width: 36,
                height: 36,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <ImageIcon />
                <Typography
                  sx={{
                    position: 'absolute',
                    top: -2,
                    right: -8,
                    fontSize: '0.7rem',
                    fontWeight: 'bold',
                    backgroundColor: 'primary.main',
                    color: 'black',
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {post.images.length}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      );
    } else {
      // Text post
      return (
        <Box 
          sx={{ 
            height: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            px: 3,
            py: 2,
            minHeight: 200
          }}
        >
          <Box sx={{ 
            maxHeight: '100%', 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
          }}>
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              {post.text || "..."}
            </Typography>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ position: 'relative', mb: 4 }}>
      {title && (
        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PlayArrowIcon sx={{ color: 'primary.main' }} />
          {title}
        </Typography>
      )}

      <Box sx={{ position: 'relative' }}>
        {/* Left shadow overlay */}
        {showLeftButton && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: 50,
              zIndex: 2,
              pointerEvents: 'none',
              background: 'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0))',
            }}
          />
        )}

        {/* Right shadow overlay */}
        {showRightButton && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              width: 50,
              zIndex: 2,
              pointerEvents: 'none',
              background: 'linear-gradient(to left, rgba(0,0,0,0.3), rgba(0,0,0,0))',
            }}
          />
        )}

        {/* Posts container */}
        <Box
          ref={containerRef}
          onScroll={checkScrollButtons}
          sx={{
            display: 'flex',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',
            gap: 2,
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            pb: 1,
          }}
        >
          {posts.map((post) => {
            const actor = actors.find(a => a.id === post.user_id);
            return (
              <Card
                key={post.id}
                onClick={() => handlePostClick(post)}
                onMouseEnter={() => setHoveredPost(post.id)}
                onMouseLeave={() => setHoveredPost(null)}
                sx={{
                  flex: `0 0 ${itemWidth}px`,
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  transition: 'all 0.2s ease',
                  borderRadius: 2,
                  border: '1px solid rgba(255, 255, 255, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.03)',
                    transform: 'translateY(-2px)',
                    boxShadow: theme.shadows[8]
                  }
                }}
              >
                {/* Actor header */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    p: 1.5,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
                  }}
                >
                  <Avatar 
                    src={actor ? getThumbnailPath(actor.photo) : ''} 
                    alt={actor ? actor.name : 'User'}
                    onClick={(e) => actor && handleActorClick(actor.id, e)}
                    sx={{ 
                      width: 40, 
                      height: 40,
                      border: '2px solid',
                      borderColor: 'primary.main',
                      cursor: 'pointer'
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="subtitle2" 
                      component="a"
                      href={actor ? `/${lng}/actor/${actor.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}` : '#'}
                      onClick={(e) => actor && handleActorClick(actor.id, e)}
                      sx={{ 
                        fontWeight: 600,
                        cursor: 'pointer',
                        color: 'white',
                        textDecoration: 'none',
                        '&:hover': {
                          color: 'primary.main'
                        }
                      }}
                    >
                      {actor ? actor.name : 'Unknown User'}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ 
                        display: 'block',
                        mt: 0.5 // Add margin-top to create space between name and date
                      }}
                    >
                      {post.date && format(new Date(post.date), 'MMM d, yyyy')}
                    </Typography>
                  </Box>
                  
                  <IconButton
                    onClick={(e) => handleFavoriteToggle(post.id, e)}
                    size="small"
                    sx={{
                      color: favorites[post.id] ? 'primary.main' : 'text.secondary',
                    }}
                  >
                    {favorites[post.id] ? <FavoriteIcon /> : <FavoriteOutlinedIcon />}
                  </IconButton>
                </Box>

                {/* Post content preview */}
                {getPostPreview(post)}

                {/* Post footer */}
                <CardContent sx={{ p: 2, pt: 1.5 }}>
                  {post.text && (
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        mb: 1
                      }}
                    >
                      {post.text}
                    </Typography>
                  )}
                  
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mt: 0.5
                  }}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 0.5
                    }}>
                      {post.videos && post.videos.length > 0 ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <VideocamIcon fontSize="small" color="primary" />
                          <Typography variant="caption">
                            {post.videos.length} video{post.videos.length > 1 ? 's' : ''}
                          </Typography>
                        </Box>
                      ) : post.images && post.images.length > 0 ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <ImageIcon fontSize="small" color="primary" />
                          <Typography variant="caption">
                            {post.images.length} image{post.images.length > 1 ? 's' : ''}
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <TextsmsIcon fontSize="small" color="primary" />
                          <Typography variant="caption">Text post</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        </Box>

        {/* Left scroll button */}
        {showLeftButton && (
          <IconButton
            onClick={() => handleScroll('left')}
            sx={{
              position: 'absolute',
              left: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.9)',
              },
              width: 40,
              height: 40
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        {/* Right scroll button */}
        {showRightButton && (
          <IconButton
            onClick={() => handleScroll('right')}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.9)',
              },
              width: 40,
              height: 40
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default OnlyFansRow;
