// src/config/adsConfig.js
const adsConfig = (() => {
    // Private Variable inside closure
    let adsEnabled = true;
  
    return {
      // Public getter only
      isAdsEnabled: () => adsEnabled,
      
      // Internal setter that can only be used within the app code
      _setAdsEnabled: (value) => {
        if (process.env.NODE_ENV === 'development') {
          adsEnabled = value;
        }
      }
    };
  })();
  
  Object.freeze(adsConfig);
  export default adsConfig;