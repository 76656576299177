import React from 'react';
import { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Popover,
  TextField,
  Stack,
  Chip,
  InputAdornment,
  Tab,
  Tabs,
  Button
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { WEBSITE_NAME, getThumbnailPath, SITE_CONFIG } from '../App';
import VideoGrid from '../common/VideoGrid';
import { getLocale } from '../i18n/locales';
import { getVideos, getActors, getCategories } from '../utils/api';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CategoryIcon from '@mui/icons-material/Category';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function VideosPage() {
  // Keep existing state
  const [videos, setVideos] = useState([]);
  const [actors, setActors] = useState([]);
  const [visibleVideos, setVisibleVideos] = useState([]);
  const [sortBy, setSortBy] = useState('relevance');
  const [sortedVideos, setSortedVideos] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [categorySearch, setCategorySearch] = useState('');
  
  // Age filter state
  const [selectedAges, setSelectedAges] = useState([]);
  const [availableAges, setAvailableAges] = useState([]);
  const [ageSearch, setAgeSearch] = useState('');
  
  // Replace separate filter state with combined one
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeFilterTab, setActiveFilterTab] = useState(0);
  
  const { t } = useTranslation();
  const { lng } = useParams();
  
  const VIDEOS_PER_LOAD = 12;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [videoData, actorData] = await Promise.all([
          getVideos(),
          getActors(),
          getCategories() // Still need to call this but don't need to destructure
        ]);
        
        setVideos(videoData);
        const randomSorted = [...videoData].sort(() => Math.random() - 0.5);
        setSortedVideos(randomSorted);
        setVisibleVideos(randomSorted.slice(0, VIDEOS_PER_LOAD));
        setActors(actorData);

        // Get unique categories that have videos
        const videoCategories = new Set();
        videoData.forEach(video => {
          video.categories?.forEach(category => {
            videoCategories.add(category);
          });
        });
        setAvailableCategories([...videoCategories].sort());
        
        // Calculate unique actor ages in videos
        if (SITE_CONFIG) {
          const ages = new Set();
          
          videoData.forEach(video => {
            if (video.actors && video.uploadDate) {
              video.actors.forEach(actorId => {
                const actor = actorData.find(a => a.id === actorId);
                if (actor && actor.birthDate) {
                  const birthDate = new Date(actor.birthDate);
                  const uploadDate = new Date(video.uploadDate);
                  let age = uploadDate.getFullYear() - birthDate.getFullYear();
                  const monthDiff = uploadDate.getMonth() - birthDate.getMonth();
                  
                  if (monthDiff < 0 || (monthDiff === 0 && uploadDate.getDate() < birthDate.getDate())) {
                    age--;
                  }
                  
                  ages.add(age);
                }
              });
            }
          });
          
          // Convert to array and sort numerically
          const agesArray = [...ages].sort((a, b) => a - b);
          setAvailableAges(agesArray);
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setVideos([]);
        setActors([]);
        setSortedVideos([]);
        setVisibleVideos([]);
        setAvailableCategories([]);
        setAvailableAges([]);
      }
    };

    fetchData();
  }, []);

  const handleLoadMore = () => {
    if (visibleVideos.length >= sortedVideos.length) {
      return; // No more videos to load
    }

    const currentLength = visibleVideos.length;
    const nextVideos = sortedVideos.slice(0, currentLength + VIDEOS_PER_LOAD);
    setVisibleVideos(nextVideos);
  };

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortBy(value);
    
    let newSortedVideos = [...videos];
    
    // Apply filters
    newSortedVideos = filterVideosByCategories(newSortedVideos);
    newSortedVideos = filterVideosByAges(newSortedVideos);
    
    switch (value) {
      case 'newest':
        newSortedVideos.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
        break;
      case 'oldest':  
        newSortedVideos.sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate));
        break;
      case 'relevance':
      default:
        newSortedVideos.sort(() => Math.random() - 0.5);
    }

    setSortedVideos(newSortedVideos);
    setVisibleVideos(newSortedVideos.slice(0, VIDEOS_PER_LOAD));
  };

  // Helper function to filter videos by selected categories
  const filterVideosByCategories = (videosToFilter) => {
    if (selectedCategories.length === 0) {
      return videosToFilter;
    }
    
    return videosToFilter.filter(video => 
      selectedCategories.every(category => video.categories?.includes(category))
    );
  };
  
  // Helper function to filter videos by selected ages
  const filterVideosByAges = (videosToFilter) => {
    if (selectedAges.length === 0) {
      return videosToFilter;
    }
    
    return videosToFilter.filter(video => {
      if (!video.actors || !video.uploadDate) {
        return false;
      }
      
      return video.actors.some(actorId => {
        const actor = actors.find(a => a.id === actorId);
        if (!actor || !actor.birthDate) {
          return false;
        }
        
        const birthDate = new Date(actor.birthDate);
        const uploadDate = new Date(video.uploadDate);
        let age = uploadDate.getFullYear() - birthDate.getFullYear();
        const monthDiff = uploadDate.getMonth() - birthDate.getMonth();
        
        if (monthDiff < 0 || (monthDiff === 0 && uploadDate.getDate() < birthDate.getDate())) {
          age--;
        }
        
        return selectedAges.includes(age);
      });
    });
  };

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  // Combined filter handlers
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev => {
      const updated = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];
      
      // Apply filters immediately
      const filteredVideos = [...videos];
      
      // Use the updated category selection directly
      const withCategoryFilter = filterVideosByCategoriesWithSelection(filteredVideos, updated);
      const withBothFilters = filterVideosByAges(withCategoryFilter);
      
      // Apply current sort
      let sortedResults = [...withBothFilters];
      switch (sortBy) {
        case 'newest':
          sortedResults.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
          break;
        case 'oldest':  
          sortedResults.sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate));
          break;
        case 'relevance':
        default:
          sortedResults.sort(() => Math.random() - 0.5);
      }
      
      setSortedVideos(sortedResults);
      setVisibleVideos(sortedResults.slice(0, VIDEOS_PER_LOAD));
      
      return updated;
    });
  };
  
  // Helper function to filter videos using a specific category selection
  const filterVideosByCategoriesWithSelection = (videosToFilter, categorySelection) => {
    if (categorySelection.length === 0) {
      return videosToFilter;
    }
    
    return videosToFilter.filter(video => 
      categorySelection.every(category => video.categories?.includes(category))
    );
  };
  
  // Age filter handlers - Remove references to separate age filter UI controls
  const handleAgeToggle = (age) => {
    setSelectedAges(prev => {
      const updated = prev.includes(age)
        ? prev.filter(a => a !== age)
        : [...prev, age];
      
      // Apply filters immediately
      const filteredVideos = [...videos];
      const withCategoryFilter = filterVideosByCategories(filteredVideos);
      
      // Use the updated age selection directly
      const withBothFilters = filterVideosByAgesWithSelection(withCategoryFilter, updated);
      
      // Apply current sort
      let sortedResults = [...withBothFilters];
      switch (sortBy) {
        case 'newest':
          sortedResults.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
          break;
        case 'oldest':  
          sortedResults.sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate));
          break;
        case 'relevance':
        default:
          sortedResults.sort(() => Math.random() - 0.5);
      }
      
      setSortedVideos(sortedResults);
      setVisibleVideos(sortedResults.slice(0, VIDEOS_PER_LOAD));
      
      return updated;
    });
  };
  
  // Handle age search input change
  const handleAgeSearch = (event) => {
    setAgeSearch(event.target.value);
  };

  // Handle category search input change
  const handleCategorySearch = (event) => {
    setCategorySearch(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveFilterTab(newValue);
  };

  // Helper function to filter videos using a specific age selection
  const filterVideosByAgesWithSelection = (videosToFilter, ageSelection) => {
    if (ageSelection.length === 0) {
      return videosToFilter;
    }
    
    return videosToFilter.filter(video => {
      if (!video.actors || !video.uploadDate) {
        return false;
      }
      
      return video.actors.some(actorId => {
        const actor = actors.find(a => a.id === actorId);
        if (!actor || !actor.birthDate) {
          return false;
        }
        
        const birthDate = new Date(actor.birthDate);
        const uploadDate = new Date(video.uploadDate);
        let age = uploadDate.getFullYear() - birthDate.getFullYear();
        const monthDiff = uploadDate.getMonth() - birthDate.getMonth();
        
        if (monthDiff < 0 || (monthDiff === 0 && uploadDate.getDate() < birthDate.getDate())) {
          age--;
        }
        
        return ageSelection.includes(age);
      });
    });
  };

  // Filter categories based on search
  const filteredCategories = availableCategories.filter(category =>
    category.toLowerCase().includes(categorySearch.toLowerCase())
  );
  
  // Filter ages based on search
  const filteredAges = availableAges.filter(age =>
    age.toString().includes(ageSearch)
  );

  return (
    <Box sx={{ pt: 2, pb: 4 }}>
      <Helmet>
        <title>{`${t('meta.videos.title')}`}</title>
        <meta name="description" content={t('meta.videos.description')} />
        <meta name="author" content="Mia Khalifa" />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.videos.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.videos.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.videos.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.videos.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org VideoGallery markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.videos.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.videos.description'),
            "numberOfItems": videos.length,
            "hasPart": videos.slice(0, 10).map(video => ({
              "@type": "VideoObject",
              "name": video.title,
              "description": video.description,
              "thumbnailUrl": `${window.location.origin}${video.thumbnail}`,
              "uploadDate": video.uploadDate,
              "duration": video.duration
            }))
          })}
        </script>
      </Helmet>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' }, // Untereinander auf mobil, nebeneinander ab sm
        gap: { xs: 2, sm: 0 }, // Abstand zwischen Elementen auf mobil
        justifyContent: { sm: 'space-between' }, 
        alignItems: { xs: 'stretch', sm: 'center' },
        mb: 3 
      }}>
        <Typography 
          variant="h4" 
          sx={{
            fontSize: { xs: '1.5rem', sm: '2.125rem' }, // Kleinere Schrift auf Mobilgeräten
            mb: { xs: 0, sm: 1.5 } // Margin bottom nur auf Desktop
          }}
        >
          {t('nav.videos')}
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          alignItems: 'center',
          width: { xs: '100%', sm: 'auto' }
        }}>
          <FormControl 
            size="small" 
            sx={{ 
              flex: { xs: 1, sm: 'none' },
              width: { sm: 200 }
            }}
          >
            <Select
              value={sortBy}
              onChange={handleSortChange}
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '& .MuiSelect-icon': { color: 'text.secondary' }
              }}
            >
              <MenuItem value="relevance">{t('common.sortOptions.relevance')}</MenuItem>
              <MenuItem value="newest">{t('common.sortOptions.newest')}</MenuItem>
              <MenuItem value="oldest">{t('common.sortOptions.oldest')}</MenuItem>
            </Select>
          </FormControl>
          
          {/* Combined filter button - changed to IconButton */}
          <IconButton
            onClick={handleFilterClick}
            color={(selectedCategories.length > 0 || selectedAges.length > 0) ? "primary" : "default"}
            size="medium"
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              border: (selectedCategories.length > 0 || selectedAges.length > 0) 
                ? '1px solid' 
                : '1px solid rgba(255, 255, 255, 0.23)',
              borderColor: (selectedCategories.length > 0 || selectedAges.length > 0) 
                ? 'primary.main' 
                : 'rgba(255, 255, 255, 0.23)',
              position: 'relative',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)'
              }
            }}
          >
            <FilterListIcon />
            {(selectedCategories.length > 0 || selectedAges.length > 0) && (
              <Box 
                component="span" 
                sx={{ 
                  position: 'absolute',
                  top: -4,
                  right: -4,
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  borderRadius: '50%',
                  width: 16,
                  height: 16,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '0.65rem'
                }}
              >
                {selectedCategories.length + selectedAges.length}
              </Box>
            )}
          </IconButton>
        </Box>
      </Box>

      {/* Display both filter chips */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        {selectedCategories.length > 0 && (
          <Box sx={{ mt: 2, mb: 0 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {t('common.selectedCategories')}:
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {selectedCategories.map(category => (
                <Chip
                  key={category}
                  label={category}
                  onDelete={() => handleCategoryToggle(category)}
                  color="primary"
                />
              ))}
            </Stack>
          </Box>
        )}
        
        {SITE_CONFIG && selectedAges.length > 0 && (
          <Box sx={{ mt: 2, mb: 0 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {t('common.selectedAges')}:
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {selectedAges.map(age => (
                <Chip
                  key={age}
                  label={`${age} ${t('common.yearsOld')}`}
                  onDelete={() => handleAgeToggle(age)}
                  color="primary"
                />
              ))}
            </Stack>
          </Box>
        )}
      </Box>

      {/* Combined filter popover with tabs */}
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { width: { xs: 'calc(100% - 32px)', sm: 400 } }
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Tabs 
            value={activeFilterTab} 
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mb: 2
            }}
          >
            <Tab 
              icon={<CategoryIcon fontSize="small" />} 
              iconPosition="start"
              label={t('common.filterByCategory')}
              sx={{ 
                textTransform: 'none',
                minHeight: 48
              }}
            />
            {SITE_CONFIG && (
              <Tab 
                icon={<AccessTimeIcon fontSize="small" />}
                iconPosition="start"
                label={t('common.filterByAge')}
                sx={{ 
                  textTransform: 'none',
                  minHeight: 48
                }}
              />
            )}
          </Tabs>

          {/* Category filter tab content */}
          <Box 
            role="tabpanel"
            hidden={activeFilterTab !== 0}
            sx={{ p: 2 }}
          >
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                size="small"
                placeholder={t('common.searchCategories')}
                value={categorySearch}
                onChange={handleCategorySearch}
                sx={{ mb: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  ...(categorySearch && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setCategorySearch('')}
                          edge="end"
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }),
                }}
              />
            </Box>
            <Stack 
              direction="row" 
              spacing={1} 
              sx={{ 
                flexWrap: 'wrap', 
                gap: 1,
                maxHeight: 200,
                overflow: 'auto'
              }}
            >
              {filteredCategories.map(category => (
                <Chip
                  key={category}
                  label={category}
                  onClick={() => handleCategoryToggle(category)}
                  color={selectedCategories.includes(category) ? "primary" : "default"}
                  variant={selectedCategories.includes(category) ? "filled" : "outlined"}
                />
              ))}
            </Stack>
            {filteredCategories.length === 0 && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  textAlign: 'center',
                  mt: 2 
                }}
              >
                {t('common.noMatchingCategories')}
              </Typography>
            )}
          </Box>

          {/* Age filter tab content */}
          {SITE_CONFIG && (
            <Box 
              role="tabpanel"
              hidden={activeFilterTab !== 1}
              sx={{ p: 2 }}
            >
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={t('common.searchAges')}
                  value={ageSearch}
                  onChange={handleAgeSearch}
                  sx={{ mb: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    ...(ageSearch && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setAgeSearch('')}
                            edge="end"
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }),
                  }}
                />
              </Box>
              <Stack 
                direction="row" 
                spacing={1} 
                sx={{ 
                  flexWrap: 'wrap', 
                  gap: 1,
                  maxHeight: 200,
                  overflow: 'auto'
                }}
              >
                {filteredAges.map(age => (
                  <Chip
                    key={age}
                    label={`${age}`}
                    onClick={() => handleAgeToggle(age)}
                    color={selectedAges.includes(age) ? "primary" : "default"}
                    variant={selectedAges.includes(age) ? "filled" : "outlined"}
                  />
                ))}
              </Stack>
              {filteredAges.length === 0 && (
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    textAlign: 'center',
                    mt: 2 
                  }}
                >
                  {t('common.noMatchingAges')}
                </Typography>
              )}
            </Box>
          )}
          
          {/* Apply and reset buttons */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            p: 2,
            pt: 1,
            borderTop: 1,
            borderColor: 'divider'
          }}>
            <Button 
              size="small"
              onClick={() => {
                let updated;
                if (activeFilterTab === 0) {
                  updated = [];
                  setSelectedCategories(updated);
                } else {
                  updated = [];
                  setSelectedAges(updated);
                }
                
                // Apply updated filters to refresh the videos
                let filteredVideos = [...videos];
                
                // Use only the remaining active filter
                if (activeFilterTab === 0) { // We've reset categories
                  filteredVideos = filterVideosByAges(filteredVideos);
                } else { // We've reset ages
                  filteredVideos = filterVideosByCategories(filteredVideos);
                }
                
                // Apply current sort
                let sortedResults = [...filteredVideos];
                switch (sortBy) {
                  case 'newest':
                    sortedResults.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
                    break;
                  case 'oldest':  
                    sortedResults.sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate));
                    break;
                  case 'relevance':
                  default:
                    sortedResults.sort(() => Math.random() - 0.5);
                }
                
                setSortedVideos(sortedResults);
                setVisibleVideos(sortedResults.slice(0, VIDEOS_PER_LOAD));
              }}
              sx={{ mr: 1 }}
            >
              {t('common.reset')}
            </Button>
            <Button 
              variant="contained" 
              size="small"
              onClick={handleFilterClose}
            >
              {t('common.apply')}
            </Button>
          </Box>
        </Box>
      </Popover>
      
      {/* No results message */}
      {sortedVideos.length === 0 && (selectedCategories.length > 0 || selectedAges.length > 0) && (
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 8,
            px: 2,
            textAlign: 'center'
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t('common.noMatchingResults')}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3, maxWidth: 500 }}>
            {t('common.tryDifferentFilters')}
          </Typography>
          <Button 
            variant="outlined" 
            onClick={() => {
              // Reset all filters
              setSelectedCategories([]);
              setSelectedAges([]);
              
              // Reset to original videos with current sort
              let resetVideos = [...videos];
              switch (sortBy) {
                case 'newest':
                  resetVideos.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
                  break;
                case 'oldest':  
                  resetVideos.sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate));
                  break;
                case 'relevance':
                default:
                  resetVideos.sort(() => Math.random() - 0.5);
              }
              
              setSortedVideos(resetVideos);
              setVisibleVideos(resetVideos.slice(0, VIDEOS_PER_LOAD));
            }}
          >
            {t('common.resetAllFilters')}
          </Button>
        </Box>
      )}
      
      {/* Only show VideoGrid when there are results */}
      {sortedVideos.length > 0 && (
        <VideoGrid
          videos={sortedVideos.map(video => ({
            ...video,
            thumbnail: getThumbnailPath(video.thumbnail)
          }))}
          actors={actors}
          lng={lng}
          getTimeAgo={getTimeAgo}
          infiniteScroll={true}
          onLoadMore={handleLoadMore}
          showPagination={false}
          visibleVideos={visibleVideos.map(video => ({
            ...video,
            thumbnail: getThumbnailPath(video.thumbnail)
          }))}
        />
      )}
    </Box>
  );
}

export default VideosPage;