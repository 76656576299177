import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Stack, Avatar, Modal, Divider, CircularProgress, Button, IconButton, Menu, MenuItem, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { useNavigate, useLocation } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import XIcon from '@mui/icons-material/X';
import RedditIcon from '@mui/icons-material/Reddit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import adsConfig from '../config/adsConfig'; // Import adsConfig

const OnlyFansTimeline = ({ posts, actors, lng }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook
  const [visiblePosts, setVisiblePosts] = useState([]);
  const POSTS_PER_LOAD = 5; // Number of posts to load each time
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [favorites, setFavorites] = useState(() => {
    return JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');
  });
  const [adPosts, setAdPosts] = useState([]); // Add state for ad posts
  const [loadedAdIndices, setLoadedAdIndices] = useState(new Set());  // Track which ad containers are initialized

  // Intersection Observer Hook
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '100px 0px' // Load earlier before user scrolls
  });

  // Add helper functions
  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  const handleActorClick = (actor, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (actor) {
      navigate(`/${lng}/actor/${generateActorSlug(actor)}`);
    }
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'MMMM dd, yyyy', {
      locale: lng === 'de' ? de : undefined
    });
  };

  // Initial load and sort handling
  useEffect(() => {
    setVisiblePosts(posts.slice(0, POSTS_PER_LOAD));
    window.scrollTo(0, 0);
  }, [posts]);
  
  useEffect(() => {
    return () => {
      // Cleanup ads when component unmounts
      document.querySelectorAll('script[data-ad-script="timeline-provider"]').forEach(script => script.remove());
      document.querySelectorAll('script[data-ad-script="timeline-initializer"]').forEach(script => script.remove());
      document.querySelectorAll('ins.eas6a97888e2').forEach(ins => ins.remove());
      setLoadedAdIndices(new Set());
    };
  }, []);

  // Load more posts when scrolling
  useEffect(() => {
    if (inView && visiblePosts.length < posts.length) {
      const nextChunk = posts.slice(
        visiblePosts.length,
        visiblePosts.length + POSTS_PER_LOAD
      );
      setVisiblePosts(prev => [...prev, ...nextChunk]);
    }
  }, [inView, posts, visiblePosts.length]);

  useEffect(() => {
    if (!adsConfig.isAdsEnabled()) return;

    // Create ad posts array
    const createAdPosts = () => {
      const adArray = [];
      const totalPosts = posts.length;
      
      // Insert ad every 2-3 posts randomly
      for (let i = 2; i < totalPosts; i += Math.random() < 0.5 ? 2 : 3) {
        adArray.push(i);
      }
      
      setAdPosts(adArray);
    };

    createAdPosts();
  }, [posts]);

  const handlePostClick = (post) => {
    const actor = actors.find(a => a.id === post.user_id);
    const actorSlug = generateActorSlug(actor);
    
    // Store current scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    
    navigate(`/${lng}/onlyfans/${actorSlug}/${post.id}`, {
      state: {
        from: 'timeline',
        scrollPosition: scrollPosition
      }
    });
  };

  const handleShareClick = (post, e) => {
    e.stopPropagation();
    setSelectedPost(post);
    setShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setShareModalOpen(false);
    setSelectedPost(null);
  };

  const handleCopyLink = () => {
    if (!selectedPost) return;
    
    const actor = actors.find(a => a.id === selectedPost.user_id);
    const url = `${window.location.origin}/${lng}/onlyfans/${generateActorSlug(actor)}/${selectedPost.id}`;
    
    navigator.clipboard.writeText(url).then(() => {
      handleCloseShareModal();
    });
  };

  const handleShareX = () => {
    if (!selectedPost) return;
    
    const actor = actors.find(a => a.id === selectedPost.user_id);
    const url = `${window.location.origin}/${lng}/onlyfans/${generateActorSlug(actor)}/${selectedPost.id}`;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`);
    handleCloseShareModal();
  };

  const handleShareReddit = () => {
    if (!selectedPost) return;
    
    const actor = actors.find(a => a.id === selectedPost.user_id);
    const url = `${window.location.origin}/${lng}/onlyfans/${generateActorSlug(actor)}/${selectedPost.id}`;
    window.open(`https://reddit.com/submit?url=${encodeURIComponent(url)}`);
    handleCloseShareModal();
  };

  // Add function to handle favorites
  const handleFavoriteClick = (post, e) => {
    e.stopPropagation();
    const favoritesPosts = JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');
    const isCurrentlyFavorite = favoritesPosts.includes(post.id);
    
    let newFavorites;
    if (isCurrentlyFavorite) {
      newFavorites = favoritesPosts.filter(id => id !== post.id);
    } else {
      newFavorites = [...favoritesPosts, post.id];
    }
    
    localStorage.setItem('favoriteOnlyFansPosts', JSON.stringify(newFavorites));
    setFavorites(newFavorites);
  
    // Dispatch event for cross-tab updates
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'favoriteOnlyFansPosts',
      newValue: JSON.stringify(newFavorites)
    }));
  };

  const initializeAd = (el, index) => {
    if (!el || loadedAdIndices.has(index)) return;
    
    try {
      // Clear container
      el.innerHTML = '';
      
      // Add provider script
      const providerScript = document.createElement('script');
      providerScript.src = 'https://a.magsrv.com/ad-provider.js';
      providerScript.async = true;
      providerScript.setAttribute('data-ad-script', `timeline-provider-${index}`);
      el.appendChild(providerScript);
      
      // Add ad tag
      const ins = document.createElement('ins');
      ins.className = 'eas6a97888e2';
      ins.dataset.zoneid = '5533872';
      ins.style.display = 'block';
      el.appendChild(ins);
      
      // Add initializer script
      const initScript = document.createElement('script');
      initScript.setAttribute('data-ad-script', `timeline-initializer-${index}`);
      initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
      el.appendChild(initScript);
      
      // Mark this ad index as loaded
      setLoadedAdIndices(prev => new Set([...prev, index]));
    } catch (error) {
      console.warn('Failed to initialize timeline ad:', error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', bgcolor: 'background.paper', borderRadius: 1 }}>
      {visiblePosts.map((post, index) => {
        const actor = actors.find(a => a.id === post.user_id);
        
        return (
          <React.Fragment key={post.id}>
            {index > 0 && <Divider />}
            <Box 
                onClick={() => handlePostClick(post)}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(66, 142, 191, 0.08)', // #428ebf with 8% opacity
                    transition: 'background-color 0.2s ease'
                  },
                  borderRadius: 2,
                  p: 2
                }}
              >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {/* Make Avatar container non-clickable by removing component="a" */}
                <Box
                  sx={{ 
                    mr: 2,
                    cursor: 'default' // Change cursor to default
                  }}
                >
                  <Avatar 
                    src={actor?.photo} 
                    alt={actor?.name}
                    sx={{ 
                      width: 48, 
                      height: 48,
                    }}
                  />
                </Box>
                
                <Box sx={{ flex: 1 }}>
                  {/* Keep only actor name clickable */}
                  <Box
                    component="a"
                    href={actor ? `/${lng}/actor/${generateActorSlug(actor)}` : '#'}
                    onClick={(e) => actor && handleActorClick(actor, e)}
                    sx={{
                      color: '#4dadea',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      display: 'inline-block', // Change to inline-block
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        display: 'inline-flex', // Change to inline-flex
                        alignItems: 'center', 
                        gap: 0.5,
                        fontWeight: 'bold'
                      }}
                    >
                      {actor?.name || `User ${post.user_id}`}
                    </Typography>
                  </Box>
                  
                  {/* Move verification icon outside the clickable area */}
                  <Box
                    component="img"
                    src="/images/onlyfans_approved.svg"
                    alt="Verified"
                    sx={{ 
                      width: 16,
                      height: 16,
                      opacity: 0.8,
                      marginLeft: 0.5,
                      verticalAlign: 'middle'
                    }}
                  />
                  
                  {/* Date is not clickable */}
                  <Typography variant="caption" color="text.secondary" display="block">
                    {formatDate(post.date)}
                  </Typography>
                </Box>
              </Box>

              {/* Post text */}
              {post.text && (
                <Typography sx={{ mb: 2 }}>
                  {post.text.length > 150 
                    ? `${post.text.substring(0, 150)}...` 
                    : post.text
                  }
                </Typography>
              )}

              {/* Images */}
              {post.images && post.images.length > 0 && (
                <Box sx={{ 
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: post.images.length === 1 ? '1fr' : 
                        post.images.length === 2 ? '1fr 1fr' : 
                        'repeat(2, 1fr)',
                  },
                  gap: 1,
                  mb: 2
                }}>
                  {post.images.slice(0, Math.min(3, post.images.length)).map((img, index) => (
                    <CardMedia
                      key={index}
                      component="img"
                      image={img}
                      sx={{
                        borderRadius: 1,
                        height: post.images.length === 1 ? 400 : 200, // Square height for single image
                        width: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center center', // Center the image
                        gridColumn: post.images.length === 1 ? 'span 2' : 'auto',
                        aspectRatio: post.images.length === 1 ? '1/1' : '16/9', // Square for single image
                        ...(post.images.length === 1 && {
                          maxWidth: 400, // Max width for single image to maintain square
                          margin: '0 auto', // Center single image
                        })
                      }}
                    />
                  ))}
                  
                  {post.images.length > 3 && (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        const actor = actors.find(a => a.id === post.user_id);
                        const actorSlug = generateActorSlug(actor);
                        navigate(`/${lng}/onlyfans/${actorSlug}/${post.id}`);
                      }}
                      sx={{
                        position: 'relative',
                        height: 200,
                        borderRadius: 1,
                        cursor: 'pointer',
                        overflow: 'hidden',
                        '&:hover': {
                          '& .overlay': {
                            backgroundColor: 'rgba(0,0,0,0.7)',
                          }
                        }
                      }}
                    >
                      {/* Blurred background image */}
                      <Box
                        component="img"
                        src={post.images[3]}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          filter: 'blur(8px)',
                          transform: 'scale(1.1)', // Prevent blur edges from showing
                        }}
                      />
                      
                      {/* Dark overlay */}
                      <Box
                        className="overlay"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(0,0,0,0.6)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'background-color 0.3s ease'
                        }}
                      >
                        <Typography 
                          variant="h4" 
                          sx={{ 
                            color: 'white',
                            fontWeight: 'bold',
                            textShadow: '0 2px 4px rgba(0,0,0,0.3)'
                          }}
                        >
                          +{post.images.length - 3}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}

              {/* Videos */}
              {post.videos && post.videos.length > 0 && (
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                  {post.videos.map((video, index) => (
                    <Box 
                      key={index} 
                      sx={{ 
                        width: '100%',
                        position: 'relative',
                        aspectRatio: '16/9',
                        cursor: 'pointer',
                        '&:hover .playButton': {
                          transform: 'translate(-50%, -50%) scale(1.1)',
                          backgroundColor: 'rgba(0, 0, 0, 0.9)'
                        }
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={post.thumbnail}
                        alt="Video thumbnail"
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 1
                        }}
                      />
                      <Box
                        className="playButton"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: { xs: 48, md: 60 },
                          height: { xs: 48, md: 60 },
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'all 0.2s ease',
                          border: '3px solid white'
                        }}
                      >
                        <PlayArrowIcon 
                          sx={{ 
                            fontSize: { xs: 32, md: 40 },
                            color: 'primary.main'
                          }} 
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-start', 
                gap: 1, 
                mt: 1.5 
              }}>
                <Button
                  startIcon={<ShareIcon />}
                  onClick={(e) => handleShareClick(post, e)}
                  sx={{
                    color: 'text.secondary',
                    textTransform: 'none',
                    minWidth: { xs: '36px', sm: 'auto' }, // Smaller width on mobile
                    padding: { xs: '6px', sm: '6px 16px' }, // Less padding on mobile
                    '& .MuiButton-startIcon': {
                      margin: { xs: '0', sm: '0 8px 0 -4px' }, // No margin on mobile
                    },
                    '& .MuiButton-startIcon > *:first-of-type': {
                      fontSize: '20px', // Icon size
                    },
                    '&:hover': {
                      // Only show hover effect on devices larger than mobile
                      '@media (min-width: 600px)': {
                        color: '#4dadea',
                        bgcolor: 'rgba(77, 173, 234, 0.08)'
                      }
                    }
                  }}
                >
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {t('videoPage.share.button')}
                  </Box>
                </Button>

                <Button
                  startIcon={favorites.includes(post.id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  onClick={(e) => handleFavoriteClick(post, e)}
                  sx={{
                    color: favorites.includes(post.id) ? '#4dadea' : 'text.secondary',
                    textTransform: 'none',
                    minWidth: { xs: '36px', sm: 'auto' }, // Smaller width on mobile
                    padding: { xs: '6px', sm: '6px 16px' }, // Less padding on mobile
                    '& .MuiButton-startIcon': {
                      margin: { xs: '0', sm: '0 8px 0 -4px' }, // No margin on mobile
                    },
                    '& .MuiButton-startIcon > *:first-of-type': {
                      fontSize: '20px', // Icon size
                    },
                    '&:hover': {
                      // Only show hover effect on devices larger than mobile
                      '@media (min-width: 600px)': {
                        color: '#4dadea',
                        bgcolor: 'rgba(77, 173, 234, 0.08)'
                      }
                    }
                  }}
                >
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {favorites.includes(post.id) ? t('favorites.remove') : t('favorites.add')}
                  </Box>
                </Button>
              </Box>
            </Box>
            {/* Add ad post after regular post if needed */}
            {adsConfig.isAdsEnabled() && adPosts.includes(index) && (
              <>
                <Divider />
                <Box 
                  sx={{ 
                    p: 2,
                    bgcolor: 'background.paper',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 250,
                    position: 'relative'
                  }}
                >
                  <Box ref={el => initializeAd(el, index)} />
                </Box>
              </>
            )}
          </React.Fragment>
        );
      })}

      {visiblePosts.length < posts.length && (
        <Box
          ref={loadMoreRef}
          sx={{ display: 'flex', justifyContent: 'center', p: 4, minHeight: '100px' }}
        >
          <CircularProgress />
        </Box>
      )}

      <Modal 
        open={shareModalOpen} 
        onClose={handleCloseShareModal}
        aria-labelledby="share-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: '90%', sm: 400 },
            bgcolor: 'background.paper',
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02))',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
            p: 3,
            outline: 'none'
          }}
        >
          <Typography 
            id="share-modal-title" 
            variant="h6" 
            component="h2" 
            gutterBottom
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: '#4dadea',
              fontWeight: 500,
              pb: 2,
              borderBottom: 1,
              borderColor: 'divider'
            }}
          >
            <ShareIcon /> {t("onlyFans.share.title")}
          </Typography>

          <List sx={{ pt: 1 }}>
            {[
              { 
                icon: <ContentCopyIcon />, 
                text: t("videoPage.share.copyLink"),
                onClick: handleCopyLink 
              },
              { 
                icon: <XIcon />, 
                text: t("videoPage.share.shareOnX"),
                onClick: handleShareX 
              },
              { 
                icon: <RedditIcon />, 
                text: t("videoPage.share.shareOnReddit"),
                onClick: handleShareReddit 
              }
            ].map((option, index) => (
              <ListItem 
                key={index}
                button 
                onClick={option.onClick}
                sx={{ 
                  borderRadius: 1,
                  mb: index < 2 ? 1 : 0,
                  cursor: 'pointer', // Add this line
                  '&:hover': {
                    bgcolor: 'rgba(77, 173, 234, 0.08)',
                    '& .MuiListItemIcon-root': {
                      color: '#4dadea'
                    }
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                  {option.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={option.text}
                  primaryTypographyProps={{
                    sx: { fontWeight: 500 }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </Box>
  );
};

export default OnlyFansTimeline;