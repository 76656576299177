import { ThemeProvider, createTheme } from '@mui/material';
import { Container, CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Home from './components/Home';
import VideoPage from './components/VideoPage';
import WatchLater from './components/WatchLater';
import ActorsPage from './components/ActorsPage';
import ActorDetailPage from './components/ActorDetailPage';
import VideosPage from './components/VideosPage';
import CategoriesPage from './components/CategoriesPage';
import CategoryVideosPage from './components/CategoryVideosPage';
import GalleriesPage from './components/GalleriesPage';
import GalleryViewPage from './components/GalleryViewPage';
import Breadcrumbs from './components/Breadcrumbs';
import ScrollToTop from './components/ScrollToTop';
import ErrorPage from './components/ErrorPage';
import OnlyFansPage from './components/OnlyFansPage';
import Footer from './components/Footer';
import Box from '@mui/material/Box';
import HistoryPage from './components/HistoryPage';
import './App.css';
import './i18n';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useLocation } from 'react-router-dom';
import ProgressBar from './components/ProgressBar';
import FavoritesPage from './components/FavoritesPage'; // Add this import
import OnlyFansPostPage from './components/OnlyFansPostPage';
import adsConfig from './config/adsConfig';

// Global website name constant
export const WEBSITE_NAME = 'MiaKhalifa.TV';
export const DRAWER_WIDTH = 300; // Match this with your Navbar drawer width

export const VIEWS_CONFIG = {
  get enabled() {
    return !adsConfig.isAdsEnabled();
  }
};

export const CAROUSEL_CONFIG = {
  VIDEO_COUNT: 5,  // Number of videos to show in carousel
  SLIDE_DURATION: 10000  // Duration per slide in milliseconds
};

export const VIDEO_GRID_CONFIG = {
  PREVIEW_DELAY: 500 // 1 second in milliseconds
};

export const SITE_CONFIG = {
  get useOwnSite() {
    return !adsConfig.isAdsEnabled();
  }
};

export const getJsonBasePath = () => {
  return SITE_CONFIG.useOwnSite ? '/own_site/json' : '/json';
};

// Add this after the existing getJsonBasePath function
export const getBasePath = () => {
  return SITE_CONFIG.useOwnSite ? '/own_site' : '';
};

// Add this function to handle thumbnail paths
export const getThumbnailPath = (thumbnailPath) => {
  if (!thumbnailPath) return '';
  // Remove leading slash from thumbnailPath if it exists
  const cleanPath = thumbnailPath.startsWith('/') ? thumbnailPath.slice(1) : thumbnailPath;
  return `${getBasePath()}/${cleanPath}`;
};

// Add this to your App.js where other configuration constants are defined
export const HOSTERS_CONFIG = {
  direct: true,    // Enable or disable direct hoster
  mktv: true,      // Enable or disable mktv hoster
  voe: true,       // Enable or disable voe.sx hoster
  streamtape: true, // Enable or disable streamtape hoster
  vidoza: false     // Enable or disable vidoza hoster
};

// Add this helper function to filter available hosters
export const getEnabledHosters = (hosters) => {
  if (!hosters || !Array.isArray(hosters)) return [];
  
  return hosters.filter(hoster => {
    const hosterKey = hoster.name.toLowerCase().replace('.sx', ''); // Normalize "voe.sx" to "voe"
    return HOSTERS_CONFIG[hosterKey] === true;
  });
};

// Configure NProgress
NProgress.configure({ 
  showSpinner: false,
  trickleSpeed: 100,
  easing: 'ease',
  speed: 500
});

function App() {
  const { i18n } = useTranslation();
  
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#d89e00',
      },
      secondary: {
        main: '#dc004e',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600, // Add custom breakpoint
      },
    },
  });

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route 
            path="/" 
            element={<Navigate to={`/${i18n.language}`} replace />} 
          />
          <Route
            path="/:lng/*"
            element={
              <MainLayout theme={theme} />
            }
          />
          <Route 
            path="*" 
            element={<Navigate to="/404" replace />} 
          />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

// Create a new MainLayout component that uses useLocation
function MainLayout({ theme }) {
  const location = useLocation();
  const isHomePage = location.pathname.split('/').length <= 2;

  return (
    <>
      <ProgressBar />
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet titleTemplate={`%s - ${WEBSITE_NAME}`} defaultTitle={WEBSITE_NAME}>
          <meta name="description" content="Mia Khalifa video streaming website" />
        </Helmet>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}>
          <Navbar />
          <Container maxWidth="xl" sx={{ 
            mt: 10,
            mb: 4, 
            flex: 1,
            pl: { xl: `${DRAWER_WIDTH}px` },
            mx: 'auto'
          }}>
            {!isHomePage && <Breadcrumbs />}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="video/:slug" element={<VideoPage />} />
              <Route path="watch-later" element={<WatchLater />} />
              <Route path="actors" element={<ActorsPage />} />
              <Route path="actor/:actorSlug" element={<ActorDetailPage />} />
              <Route path="actor/:actorSlug/:tab/:pageParam" element={<ActorDetailPage />} />
              <Route path="actor/:actorSlug/:tab" element={<ActorDetailPage />} /> {/* Add this line for tab navigation */}
              <Route path="videos" element={<VideosPage />} />
              <Route path="categories" element={<CategoriesPage />} />
              <Route path="category/:categorySlug" element={<CategoryVideosPage />} />
              <Route path="galleries" element={<GalleriesPage />} />
              <Route path="gallery/:gallerySlug" element={<GalleryViewPage />} />
              <Route path="onlyfans" element={<OnlyFansPage />} />
              <Route path="history" element={<HistoryPage />} />
              <Route path="favorites" element={<FavoritesPage />} />
              <Route path="onlyfans/:actorSlug/:postId" element={<OnlyFansPostPage />} />
              <Route 
                path="*" 
                element={
                  <ErrorPage 
                    code={404}
                    title="Page Not Found"
                    message="The page you are looking for does not exist."
                  />
                }
              />
            </Routes>
          </Container>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
