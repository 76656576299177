import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Pagination,
  IconButton,
  CircularProgress,
  Button,
  Modal
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PhotoIcon from '@mui/icons-material/Photo';
import { keyframes } from '@mui/system';
import { VIDEO_GRID_CONFIG, VIEWS_CONFIG, SITE_CONFIG, getJsonBasePath } from '../App'; // Add this import at the top
import adsConfig from '../config/adsConfig';
import { useInView } from 'react-intersection-observer'; // Add this import

const VideoGrid = ({ 
  videos, 
  actors,
  title,
  page,
  onPageChange,
  videosPerPage = 6,
  showPagination = true,
  gridItemProps = { xs: 12, sm: 6, md: 4 }, // Default grid item props
  lng,
  getTimeAgo,
  isFavoritesView = false,
  infiniteScroll = false,
  onLoadMore = () => {}
}) => {
  const navigate = useNavigate();

  // Welches Video spielt aktuell das Preview?
  const [hoveredVideo, setHoveredVideo] = useState(null);
  // Welches Video ist in „1-Sekunden-Warteschleife“ (Bar läuft)?
  const [preHoverVideo, setPreHoverVideo] = useState(null);
  // New state to track if user is hovering the photo icon
  const [isHoveringPhotoIcon, setIsHoveringPhotoIcon] = useState(false);
  // New state for mobile preview control
  const [mobilePreviewVideo, setMobilePreviewVideo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [apiViewCounts, setApiViewCounts] = useState({});

  // Timer-Ref, um das setTimeout abzubrechen
  const timeoutRef = useRef(null);

  // Beim Unmount Timer canceln
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Check if device is mobile on component mount and window resize
  useEffect(() => {
    const checkIsMobile = () => {
      // Check for both screen size AND touch capability for better device detection
      const hasTouchScreen = !!(
        ('ontouchstart' in window) || 
        (navigator.maxTouchPoints > 0) || 
        (navigator.msMaxTouchPoints > 0)
      );
      
      // Consider both traditional mobile width breakpoint AND touch capability
      setIsMobile(window.matchMedia('(max-width: 1024px)').matches || hasTouchScreen);
    };
    
    checkIsMobile(); // Check initially
    window.addEventListener('resize', checkIsMobile); // Add resize listener
    
    return () => {
      window.removeEventListener('resize', checkIsMobile); // Clean up
    };
  }, []);

  // Pagination
  const effectiveVideosPerPage = videosPerPage;
  const pageCount = Math.ceil(videos.length / effectiveVideosPerPage);
  const startIndex = (page - 1) * effectiveVideosPerPage;
  const [visibleVideos, setVisibleVideos] = useState(videosPerPage);
  const displayedVideos = infiniteScroll 
    ? videos.slice(0, visibleVideos) // Zeige alle bisher geladenen Videos
    : videos.slice(startIndex, startIndex + effectiveVideosPerPage); // Normale Pagination

  const handleVideoClick = (video) => {
    const slug = video.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    navigate(`/${lng}/video/${slug}`);
  };

  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  const handleActorClick = (actorId, event) => {
    event.stopPropagation();
    const actor = actors.find(a => a.id === actorId);
    const slug = generateActorSlug(actor);
    navigate(`/${lng}/actor/${slug}`);
  };

  const handleFavoriteRemove = (videoId, event) => {
    event.stopPropagation(); // Prevent video click
    const favoriteVideos = JSON.parse(localStorage.getItem('favoriteVideos') || '[]');
    const newFavorites = favoriteVideos.filter(id => id !== videoId);
    localStorage.setItem('favoriteVideos', JSON.stringify(newFavorites));
  
    // Dispatch storage event for updates across tabs
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'favoriteVideos',
      newValue: JSON.stringify(newFavorites)
    }));
  };

  // Modified MouseEnter => Only start bar when hovering directly over the thumbnail
  const handleMouseEnter = (video, isThumbArea = false) => {
    // Don't start video preview if hovering over photo icon or not on thumbnail area
    if (!video.previewVideo || isHoveringPhotoIcon || !isThumbArea) return;

    // Falls dieses Video schon im Hovered- oder Pre-Hover-Zustand ist => kein Neustart
    if (hoveredVideo === video.id || preHoverVideo === video.id) {
      return;
    }

    // Anderen Timer abbrechen
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setPreHoverVideo(video.id);

    // In 1 Sekunde => Video starten
    timeoutRef.current = setTimeout(() => {
      setHoveredVideo(video.id);
      setPreHoverVideo(null);
      timeoutRef.current = null;
    }, VIDEO_GRID_CONFIG.PREVIEW_DELAY);
  };

  // MouseLeave => Alles abbrechen
  const handleMouseLeave = () => {
    // Don't stop video if just moving to photo icon
    if (isHoveringPhotoIcon) return;
    
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setPreHoverVideo(null);
    setHoveredVideo(null);
  };

  // Views
  const getVideoViews = (videoId) => {
    if (SITE_CONFIG.useOwnSite) {
      // First try to get from API data
      if (apiViewCounts && apiViewCounts[videoId] !== undefined) {
        return apiViewCounts[videoId];
      }
    }
    
    // Fall back to localStorage if API data not available or not enabled
    const views = JSON.parse(localStorage.getItem('videoViews') || '{}');
    return views[videoId] || 0;
  };

  // Keyframe-Animation (Fülle von 0% auf 100% in 1s)
  const fillAnimation = keyframes`
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  `;

  // Add this for infinite scroll
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '100px 0px'
  });

  // Add effect for infinite scroll
  useEffect(() => {
    if (inView && infiniteScroll && visibleVideos < videos.length) {
      // Lade die nächste Gruppe von Videos
      setVisibleVideos(prev => Math.min(prev + videosPerPage, videos.length));
      onLoadMore();
    }
  }, [inView, infiniteScroll, videos.length, visibleVideos, onLoadMore]);

  // Add state for preview image functionality
  const [previewImage, setPreviewImage] = useState(null);
  const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [currentPreviewImage, setCurrentPreviewImage] = useState(null);

  // Add new functions for handling preview images
  const handlePreviewMouseEnter = (e, previewImagePath) => {
    e.stopPropagation(); // Stop propagation to prevent card's onMouseEnter
    setIsHoveringPhotoIcon(true); // Mark that we're hovering the photo icon
    
    // Stop any video preview that might be playing
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setPreHoverVideo(null);
    setHoveredVideo(null);
    
    // Only set preview for desktop - exclude mobile devices
    if (!isMobile && window.matchMedia('(min-width: 900px)').matches) {
      // Determine if there's more space on the left or right
      const windowWidth = window.innerWidth;
      const clickX = e.clientX;
      const isMoreSpaceOnRight = clickX < windowWidth / 2;
      
      setPreviewImage(previewImagePath);
      setPreviewPosition({
        x: e.clientX,
        y: e.clientY,
        isRight: isMoreSpaceOnRight // Store whether to show on right side
      });
    }
  };

  const handlePreviewMouseMove = (e) => {
    e.stopPropagation(); // Stop propagation
    if (previewImage && !isMobile && window.matchMedia('(min-width: 900px)').matches) {
      // Maintain the same side during movement but update coordinates
      setPreviewPosition(prev => ({
        x: e.clientX,
        y: prev.y,
        isRight: prev.isRight
      }));
    }
  };

  // Improve the preview click handler to ensure it never opens the video
  const handlePreviewClick = (e, previewImagePath) => {
    e.preventDefault(); // Prevent default behavior
    e.stopPropagation(); // Prevent video click
    
    // Immediately clear any hover preview on mobile
    if (isMobile) {
      setPreviewImage(null);
    }
    
    // Immediately set state to open modal regardless of device
    setCurrentPreviewImage(previewImagePath);
    setOpenPreviewModal(true);
    
    // Return false to be extra safe against event bubbling
    return false;
  };

  const handlePreviewMouseLeave = (e) => {
    e.stopPropagation(); // Stop propagation
    setIsHoveringPhotoIcon(false); // No longer hovering the photo icon
    setPreviewImage(null);
  };

  const handleClosePreviewModal = () => {
    setOpenPreviewModal(false);
    setCurrentPreviewImage(null);
  };

  // Handle mobile preview toggle
  const handleMobilePreviewToggle = (e, video) => {
    e.preventDefault();
    e.stopPropagation();
    
    setMobilePreviewVideo(mobilePreviewVideo === video.id ? null : video.id);
  };

  const fetchViewCounts = async (videoIds) => {
    // Only fetch from API when SITE_CONFIG.useOwnSite is true
    if (!SITE_CONFIG.useOwnSite) return;
    
    try {
      const response = await fetch('http://192.168.178.54:8000/views');
      
      if (!response.ok) {
        console.log(`Error fetching view counts, status: ${response.status}`);
        return;
      }
    
      // Try to parse the response as JSON
      let viewsData;
      try {
        const text = await response.text();
        
        // Check if the response starts with HTML
        if (text.trim().startsWith('<!DOCTYPE') || text.trim().startsWith('<html')) {
          console.log("Received HTML instead of JSON. Please check the API endpoint.");
          return;
        }
        
        viewsData = JSON.parse(text);
      } catch (parseError) {
        console.log("Failed to parse view counts response as JSON:", parseError);
        return;
      }
      
      // Create a map of videoId -> viewCount
      const viewCountMap = {};
      
      // Handle both array and object response formats, similar to VideoPage.js
      if (Array.isArray(viewsData)) {
        // Handle array format
        videoIds.forEach(videoId => {
          const existingEntry = viewsData.find(entry => entry.video_id === videoId.toString());
          if (existingEntry) {
            viewCountMap[videoId] = existingEntry.views;
          }
        });
      } else {
        // Handle object format
        videoIds.forEach(videoId => {
          const videoIdStr = videoId.toString();
          if (viewsData && viewsData[videoIdStr] !== undefined) {
            viewCountMap[videoId] = viewsData[videoIdStr];
          }
        });
      }
      
      setApiViewCounts(viewCountMap);
    } catch (error) {
      console.error("Error fetching view counts:", error);
    }
  };

  useEffect(() => {
    if (videos && videos.length > 0 && SITE_CONFIG.useOwnSite) {
      // Extract all video IDs
      const videoIds = videos.map(video => video.id);
      fetchViewCounts(videoIds);
    }
  }, [videos]); // Only re-run when videos change

  return (
    <Box sx={{ pt: 2 }}>
      {title && (
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      )}

      <Grid container spacing={3}>
        {displayedVideos.map((item) => (
          <Grid item {...gridItemProps} key={item.id}>
            <Card
              component="a"
              href={`/${lng}/video/${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '')}`}
              onClick={(e) => {
                e.preventDefault();
                handleVideoClick(item);
              }}
              // Remove the mouse enter/leave handlers from the Card component
              sx={{ 
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.2s ease',
                textDecoration: 'none', // Remove underline from link
                color: 'inherit', // Inherit text color
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  boxShadow: (theme) => theme.shadows[8],
                }
              }}
            >
              {/* Add the mouse enter/leave handlers specifically to the thumbnail area */}
              <Box 
                sx={{ position: 'relative', aspectRatio: '16/9' }}
                onMouseEnter={() => !isMobile && handleMouseEnter(item, true)}
                onMouseLeave={() => !isMobile && handleMouseLeave()}
              >
                
                {/*
                  Nur dann Balken anzeigen, wenn 
                  preHoverVideo === video.id (wir sind in der 1s-Wartephase)
                  UND
                  hoveredVideo !== video.id (Video läuft noch nicht)
                */}
                {!isMobile && item.previewVideo && preHoverVideo === item.id && hoveredVideo !== item.id && (
                  <Box 
                    sx={{ 
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: 3, 
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      overflow: 'hidden',
                      zIndex: 2 
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        backgroundColor: 'primary.main',
                        animation: `${fillAnimation} ${VIDEO_GRID_CONFIG.PREVIEW_DELAY/1000}s linear forwards`
                      }}
                    />
                  </Box>
                )}

                {/* HD badge for videos with MKTV hoster */}
                {item.hosters && item.hosters.some(hoster => hoster.name === 'mktv') && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'primary.main',
                      color: 'black',
                      padding: '2px 6px',
                      borderRadius: 1,
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      zIndex: 2,
                      boxShadow: '0 2px 4px rgba(0,0,0,0.5)'
                    }}
                  >
                    HD
                  </Box>
                )}

                {/* Wenn hoveredVideo === video.id => Vorschauvideo */}
                {((isMobile && mobilePreviewVideo === item.id) || 
                  (!isMobile && hoveredVideo === item.id)) && item.previewVideo ? (
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline // Prevents iOS Safari from going fullscreen
                    disablePictureInPicture // Disables PiP on supported browsers
                    disableRemotePlayback // Disables casting
                    controlsList="nodownload nofullscreen noremoteplayback" // Additional controls restrictions
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  >
                    <source src={item.previewVideo} type="video/mp4" />
                  </video>
                ) : (
                  <CardMedia
                    component="img"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    image={item.thumbnail}
                    alt={item.title}
                  />
                )}

                {/* Add Favorite Remove Button */}
                {isFavoritesView && (
                  <IconButton
                    onClick={(e) => handleFavoriteRemove(item.id, e)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      color: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                      }
                    }}
                  >
                    <FavoriteIcon />
                  </IconButton>
                )}

                {/* Dauer nur, wenn kein Preview läuft */}
                {!(hoveredVideo === item.id && item.previewVideo) && item.duration && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 8,
                      right: 8,
                      zIndex: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        padding: '2px 4px',
                        borderRadius: 1,
                        fontSize: '0.875rem'
                      }}
                    >
                      {item.duration}
                    </Typography>
                  </Box>
                )}

                {/* Mobile preview toggle button - show if video has preview and on mobile/touch device */}
                {isMobile && item.previewVideo && (
                  <IconButton
                    onClick={(e) => handleMobilePreviewToggle(e, item)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      color: mobilePreviewVideo === item.id ? 'primary.main' : 'white',
                      zIndex: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                      }
                    }}
                  >
                    {mobilePreviewVideo === item.id ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M8 5v14l11-7z"/>
                      </svg>
                    )}
                  </IconButton>
                )}
              </Box>

              <CardContent sx={{ flexGrow: 1 }}>
                <Typography 
                  gutterBottom 
                  variant="h6" 
                  component="h2" 
                  noWrap
                  sx={{
                    transition: 'text-decoration 0.2s ease',
                    '.MuiCard-root:hover &': {
                      textDecoration: 'underline',
                    }
                  }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'primary.main' }} noWrap>
                  {item.actors.map((actorId, index) => {
                    const actor = actors.find(a => a.id === actorId);
                    const slug = generateActorSlug(actor);
                    
                    return (
                      <React.Fragment key={actorId}>
                        <Box
                          component="a" 
                          href={`/${lng}/actor/${slug}`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleActorClick(actorId, e);
                          }}
                          sx={{
                            color: 'primary.main',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {actor?.name || 'Unknown Actor'}
                        </Box>
                        {index < item.actors.length - 1 && ', '}
                      </React.Fragment>
                    );
                  })}
                </Typography>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 0.5
                  }}
                >
                  <Typography variant="caption" color="text.secondary">
                    {getTimeAgo(item.uploadDate)}
                  </Typography>
                  {/* Views count - Only show if VIEWS_CONFIG.enabled is true */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    {VIEWS_CONFIG.enabled && (
                      <>
                        <VisibilityIcon sx={{ fontSize: '0.9em', color: 'text.secondary' }} />
                        <Typography variant="caption" color="text.secondary">
                          {getVideoViews(item.id).toLocaleString()}
                        </Typography>
                      </>
                    )}
                    
                    {/* Add preview image button if previewImage is available - moved from thumbnail */}
                    {item.previewImage && (
                      <Box 
                        onClick={(e) => e.stopPropagation()} // Extra layer to stop propagation
                        onTouchEnd={(e) => e.stopPropagation()} // Handle touch events explicitly
                        sx={{ 
                          display: 'inline-block' // Make sure this Box captures events
                        }}
                      >
                        <IconButton 
                          aria-label="preview image"
                          onClick={(e) => handlePreviewClick(e, item.previewImage)}
                          onMouseEnter={(e) => handlePreviewMouseEnter(e, item.previewImage)}
                          onMouseMove={handlePreviewMouseMove}
                          onMouseLeave={handlePreviewMouseLeave}
                          onTouchStart={(e) => e.stopPropagation()} // Handle touch start
                          sx={{
                            padding: '2px',
                            color: 'text.secondary',
                            '&:hover': {
                              color: 'primary.main',
                              backgroundColor: 'transparent'
                            },
                            ml: 0.5,
                            zIndex: 10 // Ensure button is above other elements
                          }}
                          size="small"
                        >
                          <PhotoIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pagination or Infinite Scroll */}
      {infiniteScroll ? (
        <Box 
          ref={loadMoreRef}
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            p: 4,
            minHeight: '100px'
          }}
        >
          {videos.length > displayedVideos.length && (
            <CircularProgress />
          )}
        </Box>
      ) : (
        showPagination && pageCount > 1 && (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Pagination 
              count={pageCount} 
              page={page} 
              onChange={onPageChange}
              color="primary"
              size="large" 
              sx={{
                '& .MuiPagination-ul': {
                  gap: { xs: '2px', sm: '8px' }, // Reduzierter Abstand auf Mobilgeräten
                },
                '& .MuiPaginationItem-root': {
                  minWidth: { xs: '30px', sm: '40px' }, // Kleinere Buttons auf Mobilgeräten
                  height: { xs: '30px', sm: '40px' }, // Kleinere Buttons auf Mobilgeräten
                  fontSize: { xs: '0.875rem', sm: '1rem' }, // Kleinere Schrift auf Mobilgeräten
                }
              }}
            />
          </Box>
        )
      )}

      {infiniteScroll && (
        <Box 
          ref={loadMoreRef}
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            p: 4,
            minHeight: '100px'
          }}
        >
          {visibleVideos < videos.length && (
            <CircularProgress />
          )}
        </Box>
      )}

      {/* Floating preview image for desktop */}
      {previewImage && (
        <Box
          sx={{
            position: 'fixed',
            ...(previewPosition.isRight 
              ? { left: `${previewPosition.x + 20}px` } // Show on the right of cursor
              : { right: `${window.innerWidth - previewPosition.x + 20}px` }), // Show on the left of cursor
            top: `${previewPosition.y - 200}px`,
            zIndex: 9999,
            maxWidth: '800px',
            maxHeight: '700px',
            minWidth: '350px',
            boxShadow: '0 8px 30px rgba(0,0,0,0.7)',
            border: '3px solid rgba(216, 158, 0, 0.8)',
            borderRadius: 2,
            overflow: 'hidden',
            pointerEvents: 'none',
            backgroundColor: 'black',
          }}
        >
          <img
            src={previewImage}
            alt="Preview"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              display: 'block',
            }}
          />
        </Box>
      )}

      {/* Modal for mobile preview - improved structure */}
      <Modal
        open={openPreviewModal}
        onClose={handleClosePreviewModal}
        aria-labelledby="preview-image-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}
        disableAutoFocus // Prevent auto focusing which might cause issues
        disableEnforceFocus // Don't enforce focus inside modal
        disableRestoreFocus // Don't restore focus after closing
      >
        <Box 
          onClick={(e) => e.stopPropagation()} // Ensure clicks inside don't propagate
          sx={{
            position: 'relative',
            maxWidth: '95vw',
            maxHeight: '90vh',
            backgroundColor: 'background.paper',
            borderRadius: 1,
            overflow: 'hidden',
            p: 1,
            outline: 'none',
            boxShadow: 24,
          }}
        >
          <img
            src={currentPreviewImage}
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              display: 'block',
              objectFit: 'contain',
            }}
            onClick={(e) => e.stopPropagation()} // Extra protection
          />
          <Button 
            onClick={(e) => {
              e.stopPropagation();
              handleClosePreviewModal();
            }}
            variant="contained"
            sx={{ 
              mt: 1,
              width: '100%',
              textTransform: 'none',
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default VideoGrid;
