import { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid,
  Card,
  CardContent,
  TextField,
  CircularProgress 
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { WEBSITE_NAME } from '../App';
import { getLocale } from '../i18n/locales';
import { getCategories, getVideos } from '../utils/api';

function CategoriesPage() {
  const [categoriesWithCount, setCategoriesWithCount] = useState({});  // Initialize as empty object
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [categories, videos] = await Promise.all([
          getCategories(),
          getVideos()
        ]);

        // Count videos per category and group by first letter
        const categoriesWithCounts = categories.reduce((acc, category) => {
          const count = videos.filter(video => 
            video.categories.includes(category)
          ).length;
          if (count > 0) {
            const firstLetter = category.charAt(0).toUpperCase();
            if (!acc[firstLetter]) {
              acc[firstLetter] = [];
            }
            acc[firstLetter].push({
              name: category,
              count: count
            });
          }
          return acc;
        }, {});

        // Sort categories within each letter group
        Object.keys(categoriesWithCounts).forEach(letter => {
          categoriesWithCounts[letter].sort((a, b) => 
            a.name.localeCompare(b.name)
          );
        });

        setCategoriesWithCount(categoriesWithCounts);
        setLoading(false);
      } catch (error) {
        console.error('Error loading categories:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryClick = (category) => {
    const slug = category
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace any non-alphanumeric chars with hyphen
      .replace(/^-+|-+$/g, '');    // Remove leading/trailing hyphens
    navigate(`/${lng}/category/${slug}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ pt: 2, color: 'error.main' }}>
        <Typography>Error loading categories: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ pt: 2, pb: 4 }}>
      <Helmet>
        <title>{`${t('meta.categories.title')}`}</title>
        <meta name="description" content={t('meta.categories.description')} />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.categories.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.categories.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.categories.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.categories.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.categories.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.categories.description'),
            "numberOfItems": Object.values(categoriesWithCount).flat().length,
            "hasPart": Object.values(categoriesWithCount).flat().slice(0, 10).map(category => ({
              "@type": "Thing",
              "name": category.name,
              "url": `${window.location.origin}/${lng}/category/${category.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`,
              "numberOfItems": category.count
            }))
          })}
        </script>
      </Helmet>
      <Typography variant="h4" gutterBottom>
        {t('nav.categories')}
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        placeholder={t('search.placeholder')}
        value={searchQuery}
        onChange={handleSearch}
        sx={{ 
          mb: 4,
          maxWidth: '400px', // Begrenzt die maximale Breite
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.03)', // Subtiler Hintergrund
            height: '45px', // Reduzierte Höhe
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.1)', // Hellerer Border
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.2)', // Hover Effekt
            },
          },
          '& .MuiInputBase-input': {
            padding: '8px 14px', // Kleineres Padding
            fontSize: '0.9rem', // Kleinere Schriftgröße
          }
        }}
      />

      {Object.keys(categoriesWithCount)
        .sort()
        .filter(letter => {
          if (!searchQuery) return true;
          return categoriesWithCount[letter]?.some(category =>
            category.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .map(letter => (
          <Box key={letter} sx={{ mb: 4 }}>
            <Typography 
              variant="h3" 
              sx={{ 
                mb: 2,
                color: 'primary.main',
                borderBottom: '2px solid',
                borderColor: 'primary.main',
                width: 'fit-content',
                px: 2
              }}
            >
              {letter}
            </Typography>
            <Grid container spacing={2}>
              {categoriesWithCount[letter]?.filter(category =>
                !searchQuery || category.name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map(category => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={category.name}>
                  <Card 
                    component="a"
                    href={`/${lng}/category/${category.name.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '')}`}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      handleCategoryClick(category.name); // Keep the existing navigation logic
                    }}
                    sx={{ 
                      cursor: 'pointer',
                      background: 'rgba(255, 255, 255, 0.05)',
                      transition: 'all 0.2s',
                      textDecoration: 'none', // Remove default link underline
                      color: 'inherit', // Inherit text color
                      display: 'block', // Add this to maintain Card layout
                      '&:hover': {
                        backgroundColor: 'rgba(216, 158, 0, 0.1)',
                        transform: 'translateY(-2px)'
                      },
                      // Reset link styles
                      '&:link, &:visited, &:hover, &:active': {
                        textDecoration: 'none',
                        color: 'inherit'
                      }
                    }}
                  >
                    <CardContent sx={{
                      display: 'flex',
                      alignItems: 'center', // Vertically center content
                      height: '100%', // Take full height of card
                      padding: '12px !important', // Override default padding
                    }}>
                      <Typography 
                        variant="h6" 
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%' // Take full width
                        }}
                      >
                        <span>{category.name}</span>
                        <Typography 
                          component="span"
                          sx={{ 
                            color: 'primary.main',
                            backgroundColor: 'rgba(216, 158, 0, 0.1)',
                            borderRadius: '12px',
                            px: 1.5,
                            py: 0.5,
                            fontSize: '0.8em'
                          }}
                        >
                          {category.count}
                        </Typography>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
      ))}
    </Box>
  );
}

export default CategoriesPage;