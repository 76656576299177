import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tabs, Tab, Grid, CardMedia, FormControl, Select, MenuItem, CircularProgress } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OnlyFansTimeline from '../common/OnlyFansTimeline';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { WEBSITE_NAME } from "../App";
import { getLocale } from '../i18n/locales';
import { getOnlyFans, getActors } from '../utils/api'; // Add this import
import { getThumbnailPath } from '../App';

function OnlyFansPage() {
  const [posts, setPosts] = useState([]);
  const [actors, setActors] = useState([]);
  const [activeTab, setActiveTab] = useState('timeline');
  const [sortBy, setSortBy] = useState('newest'); // Default sort: newest first
  const { lng } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [visibleVideos, setVisibleVideos] = useState([]);
  const [visibleImages, setVisibleImages] = useState([]);
  const ITEMS_PER_LOAD = 12; // Number of items to load each time
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Add intersection observer hook 
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '100px 0px' // Load earlier before reaching bottom
  });

  // Extract videos and images from posts
  const videos = posts.reduce((acc, post) => {
    if (post.videos && post.videos.length > 0) {
      const postVideos = post.videos.map(video => ({
        id: `${post.id}-${video}`,
        title: post.text || 'Untitled',
        uploadDate: post.date,
        actors: [post.user_id],
        thumbnail: getThumbnailPath(post.thumbnail || post.images?.[0] || '/images/video-placeholder.jpg'),
        previewVideo: video
      }));
      return [...acc, ...postVideos];
    }
    return acc;
  }, []);

  const images = posts.reduce((acc, post) => {
    if (post.images && post.images.length > 0) {
      // Get random image from post
      const randomIndex = Math.floor(Math.random() * post.images.length);
      const randomImage = post.images[randomIndex];
      
      // Create single image object with additional info
      const postImage = {
        id: `${post.id}-${randomIndex}`,
        src: getThumbnailPath(randomImage),
        date: post.date,
        actor: actors.find(a => a.id === post.user_id)?.name || 'Unknown',
        remainingCount: post.images.length - 1, // How many more images exist
        postId: post.id,
        userId: post.user_id
      };
      return [...acc, postImage];
    }
    return acc;
  }, []);

  // Get sorted posts
  const getSortedPosts = useCallback(() => {
    const sortedPosts = [...posts];
    switch (sortBy) {
      case 'newest':
        return sortedPosts.sort((a, b) => {
          // First compare dates
          const dateCompare = new Date(b.date) - new Date(a.date);
          // If dates are equal, sort by ID in descending order
          if (dateCompare === 0) {
            return b.id - a.id; // Higher ID comes first
          }
          return dateCompare;
        });
      case 'oldest':
        return sortedPosts.sort((a, b) => {
          // First compare dates  
          const dateCompare = new Date(a.date) - new Date(b.date);
          // If dates are equal, sort by ID in descending order
          if (dateCompare === 0) {
            return b.id - a.id; // Higher ID comes first
          }
          return dateCompare;
        });
      case 'relevance':
        return sortedPosts.sort((a, b) => b.id - a.id); // Higher ID comes first
      default:
        return sortedPosts;
    }
  }, [posts, sortBy]);

  // Handle sort change
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [postsData, actorsData] = await Promise.all([
          getOnlyFans(),
          getActors()
        ]);
        
        // Transform posts to use getThumbnailPath for images and thumbnails
        const transformedPosts = postsData.posts.map(post => ({
          ...post,
          images: post.images?.map(img => getThumbnailPath(img)),
          thumbnail: post.thumbnail ? getThumbnailPath(post.thumbnail) : undefined
        }));

        // Transform actors to use getThumbnailPath for photos
        const transformedActors = actorsData.map(actor => ({
          ...actor,
          photo: getThumbnailPath(actor.photo)
        }));

        setPosts(transformedPosts);
        setActors(transformedActors);
      } catch (error) {
        console.error('Error loading OnlyFans data:', error);
        setPosts([]);
        setActors([]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (location.state?.scrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, location.state.scrollPosition);
      }, 0);
    }
  }, [location.state]);

  useEffect(() => {
    // Initial load
    if (videos.length > 0 && visibleVideos.length === 0) {
      setVisibleVideos(videos.slice(0, ITEMS_PER_LOAD));
    }
    if (images.length > 0 && visibleImages.length === 0) {
      setVisibleImages(images.slice(0, ITEMS_PER_LOAD));
    }
  }, [videos, images]);

  const loadMoreVideos = useCallback(() => {
    if (!isLoadingMore && visibleVideos.length < videos.length) {
      setIsLoadingMore(true);
      const nextVideos = videos.slice(0, visibleVideos.length + ITEMS_PER_LOAD);
      setVisibleVideos(nextVideos);
      setIsLoadingMore(false);
    }
  }, [visibleVideos.length, videos, isLoadingMore]);

  const loadMoreImages = useCallback(() => {
    if (!isLoadingMore && visibleImages.length < images.length) {
      setIsLoadingMore(true);
      const nextImages = images.slice(0, visibleImages.length + ITEMS_PER_LOAD);
      setVisibleImages(nextImages);
      setIsLoadingMore(false);
    }
  }, [visibleImages.length, images, isLoadingMore]);

  useEffect(() => {
    if (inView && !isLoadingMore) {
      if (activeTab === 'videos') {
        loadMoreVideos();
      } else if (activeTab === 'images') {
        loadMoreImages();
      }
    }
  }, [inView, activeTab, loadMoreVideos, loadMoreImages, isLoadingMore]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ pt: 2, pb: 4 }}>
      <Helmet>
        <title>{`${t('meta.onlyFans.title')}`}</title>
        <meta name="description" content={t('meta.onlyFans.description')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.onlyFans.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.onlyFans.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.onlyFans.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.onlyFans.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.onlyFans.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.onlyFans.description'),
            "numberOfItems": posts.length,
            "hasPart": posts.slice(0, 10).map(post => {
              const actor = actors.find(a => a.id === post.user_id);
              const actorSlug = actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
              
              return {
                "@type": "SocialMediaPosting",
                "datePublished": new Date(post.date).toISOString(),
                "author": {
                  "@type": "Person",
                  "name": actor?.name,
                  "url": actor ? `${window.location.origin}/${lng}/actor/${actorSlug}` : undefined
                },
                ...(post.text && { "text": post.text }),
                ...(post.images?.[0] && { "image": `${window.location.origin}${post.images[0]}` })
              };
            })
          })}
        </script>
      </Helmet>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTab-root': {
              color: 'text.secondary',
              '&.Mui-selected': {
                color: '#4dadea'
              }
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#4dadea'
            }
          }}
        >
          <Tab 
            label={t('onlyFans.tabs.timeline')} 
            value="timeline" 
          />
          <Tab 
            label={t('onlyFans.tabs.videos')} 
            value="videos" 
          />
          <Tab 
            label={t('onlyFans.tabs.images')} 
            value="images" 
          />
        </Tabs>
      </Box>

      {activeTab === 'timeline' && (
        <>
          <Box sx={{ mb: 2, ml: 2 }}>
            <FormControl 
              size="small" 
              sx={{ 
                minWidth: 200,
                '& .MuiSelect-select': {
                  color: '#4dadea'
                }
              }}
            >
              <Select
                value={sortBy}
                onChange={(event) => setSortBy(event.target.value)}
                sx={{
                  bgcolor: 'rgba(77, 173, 234, 0.08)',
                  '& .MuiSelect-icon': { 
                    color: '#4dadea' 
                  }
                }}
              >
                <MenuItem value="newest">{t('common.sortOptions.newest')}</MenuItem>
                <MenuItem value="oldest">{t('common.sortOptions.oldest')}</MenuItem>
                <MenuItem value="relevance">{t('common.sortOptions.relevance')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <OnlyFansTimeline 
            posts={getSortedPosts()} // This will now update when sortBy changes
            actors={actors}
            lng={lng}
          />
        </>
      )}

      {activeTab === 'videos' && (
        <Grid container spacing={2}>
          {visibleVideos.map((video) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={video.id}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  aspectRatio: '1',
                  cursor: 'pointer',
                  '&:hover': {
                    '& .playButton': {
                      transform: 'translate(-50%, -50%) scale(1.1)',
                      backgroundColor: 'rgba(0, 0, 0, 0.9)'
                    }
                  }
                }}
                onClick={() => {
                  // Extract the original post ID from the video.id (remove the video filename part)
                  const postId = video.id.split('-')[0];
                  // Find the actor for this video
                  const actor = actors.find(a => a.id === video.actors[0]);
                  if (actor) {
                    const actorSlug = actor.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
                    navigate(`/${lng}/onlyfans/${actorSlug}/${postId}`);
                  }
                }}
              >
                <CardMedia
                  component="img"
                  image={video.thumbnail}
                  alt="Video thumbnail"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: 1
                  }}
                />
                <Box
                  className="playButton"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '48px',
                    height: '48px',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'all 0.2s ease',
                    border: '2px solid white'
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      width: 0,
                      height: 0,
                      borderTop: '10px solid transparent',
                      borderBottom: '10px solid transparent',
                      borderLeft: '15px solid white',
                      marginLeft: '3px'
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
          {visibleVideos.length < videos.length && (
            <Box
              ref={loadMoreRef}
              sx={{
                gridColumn: '1/-1',
                display: 'flex',
                justifyContent: 'center',
                p: 4
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      )}

      {activeTab === 'images' && (
        <Grid container spacing={2}>
          {visibleImages.map((image) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  const actor = actors.find(a => a.id === image.userId);
                  const actorSlug = actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
                  navigate(`/${lng}/onlyfans/${actorSlug}/${image.postId}`);
                }}
              >
                <CardMedia
                  component="img"
                  image={image.src}
                  alt={`Posted by ${image.actor}`}
                  sx={{
                    width: '100%',
                    aspectRatio: '1',
                    objectFit: 'cover',
                    borderRadius: 1,
                    '&:hover': {
                      opacity: 0.8,
                      transition: 'all 0.2s ease'
                    }
                  }}
                />
                {image.remainingCount > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      color: 'white',
                      padding: '8px',
                      textAlign: 'center',
                      borderBottomLeftRadius: 1,
                      borderBottomRightRadius: 1,
                      fontSize: '0.875rem',
                      fontWeight: 'bold'
                    }}
                  >
                    +{image.remainingCount}
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
          {visibleImages.length < images.length && (
            <Box
              ref={loadMoreRef}
              sx={{
                gridColumn: '1/-1',
                display: 'flex',
                justifyContent: 'center',
                p: 4
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      )}
    </Box>
  );
}

export default OnlyFansPage;