import React from 'react';
import { Box, Container, Typography, Link, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WEBSITE_NAME } from '../App';
import { DRAWER_WIDTH } from '../App'; // Import the drawer width constant

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        py: 2, // Reduced padding
        mt: 'auto',
        backgroundColor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container 
        maxWidth={false} // Remove max-width constraint
        sx={{ 
          pl: { xl: `${DRAWER_WIDTH}px` }, // Add left padding on desktop to account for drawer
          width: { xl: `calc(100% - ${DRAWER_WIDTH}px)` }, // Adjust width on desktop
          mx: 'auto',
          px: { xs: 2, sm: 3, xl: 0 } // Remove padding on xl breakpoint
        }}
      >
        <Box sx={{ 
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1, // Reduced gap
          width: '100%', // Ensure box takes full width
          pl: { xl: `${DRAWER_WIDTH}px` } // Add left padding to match drawer width
        }}>
          <Typography variant="h6" color="primary">
            {WEBSITE_NAME}
          </Typography>
          
          <Typography 
            variant="body2" 
            color="text.secondary"
          >
            © {currentYear} {WEBSITE_NAME}. {t('footer.rights')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;