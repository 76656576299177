// src/components/Breadcrumbs.js
import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

function Breadcrumbs() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [pageTitle, setPageTitle] = useState('');
  
  // Split pathname into segments
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Remove language segment from display but keep for links
  const displayPathnames = [...pathnames];
  displayPathnames.shift(); // Remove language code

  // Reset pageTitle when location changes
  useEffect(() => {
    setPageTitle('');
  }, [location.pathname]);

  // Load additional data for dynamic titles
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the last part of the path
        const lastPath = displayPathnames[displayPathnames.length - 1];
        
        // Reset pageTitle if we're not on a detail page
        if (!lastPath || displayPathnames.length <= 1) {
          setPageTitle('');
          return;
        }
        
        // If we're on a video page
        if (displayPathnames[0] === 'video' && lastPath) {
          const response = await fetch('/json/list.json');
          const videos = await response.json();
          const video = videos.find(v => 
            v.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '') === lastPath
          );
          if (video) {
            setPageTitle(video.title);
          }
        }
        
        // If we're on an actor page
        if (displayPathnames[0] === 'actor' && lastPath) {
          const response = await fetch('/json/actors.json');
          const actors = await response.json();
          // Find actor by matching the URL slug format
          const actor = actors.find(a => 
            a.name.toLowerCase().replace(/[^a-z0-9]+/g, '-') === lastPath
          );
          if (actor) {
            setPageTitle(actor.name); // Use the properly formatted actor name
          }
        }
        
        // If we're on a category page
        if (displayPathnames[0] === 'category' && lastPath) {
          const categoriesResponse = await fetch('/json/categories.json');
          const categories = await categoriesResponse.json();
          const matchingCategory = categories.find(category => {
            const categorySlug = category
              .toLowerCase()
              .replace(/[^a-z0-9]+/g, '-')
              .replace(/^-+|-+$/g, '');
            return categorySlug === lastPath;
          });
          
          if (matchingCategory) {
            setPageTitle(matchingCategory); // Use original category name
          }
        }
        
        // If we're on a gallery page
        if (displayPathnames[0] === 'gallery' && lastPath) {
          const response = await fetch('/json/galleries.json');
          const galleries = await response.json();
          const gallery = galleries.find(g => {
            const gallerySlug = g.title
              .toLowerCase()
              .replace(/[^a-z0-9]+/g, '-')
              .replace(/^-+|-+$/g, '');
            return gallerySlug === lastPath;
          });
          
          if (gallery) {
            setPageTitle(gallery.title); // Use original gallery title
          }
        }

        if (displayPathnames[0] === 'onlyfans' && displayPathnames.length === 3) {
          const actorSlug = displayPathnames[1];
          const actorsResponse = await fetch('/json/actors.json');
          const actors = await actorsResponse.json();
          const actor = actors.find(a => 
            a.name.toLowerCase().replace(/[^a-z0-9]+/g, '-') === actorSlug
          );
          
          if (actor) {
            // Setze den Titel direkt mit dem Übersetzungsschlüssel
            setPageTitle(t('onlyFans.postBy', { name: actor.name }));
          }
        }
      } catch (error) {
        console.error('Error fetching data for breadcrumbs:', error);
      }
    };

    fetchData();
  }, [displayPathnames]);

  // Create breadcrumb data with proper routing
  const breadcrumbNameMap = {
    videos: { title: t('nav.videos'), link: true },
    actors: { title: t('nav.actors'), link: true },
    categories: { title: t('nav.categories'), link: true },
    galleries: { title: t('nav.galleries'), link: true },
    'watch-later': { title: t('nav.watchLater'), link: true },
    video: { title: t('nav.videos'), link: true, redirectTo: 'videos' },
    actor: { title: t('nav.actors'), link: true, redirectTo: 'actors' },
    category: { title: t('nav.categories'), link: true, redirectTo: 'categories' },
    gallery: { title: t('nav.galleries'), link: true, redirectTo: 'galleries' },
    history: { title: t('nav.history'), link: true, redirectTo: 'history' },
    favorites: { title: t('favorites.title'), link: true, redirectTo: 'favorites' },
    onlyfans: { title: 'OnlyFans', link: true },
    'actor-slug': { 
      dynamic: true,
      hide: (pathname) => pathname.startsWith('onlyfans/'), // Hide actor-slug level for OnlyFans posts
      getDynamicTitle: (pathname, t, pageTitle) => {
        // When it's an OnlyFans post (URL format: /onlyfans/actor-slug/post-id)
        if (pathname.startsWith('onlyfans/') && pathname.split('/').length === 3) {
          return t('onlyFans.postBy', { name: pageTitle }); // "Post von {name}"
        }
        return pageTitle;
      }
    },
    ':postId': {
    dynamic: true,
    getDynamicTitle: (pathname, t, pageTitle) => {
      if (pathname.startsWith('onlyfans/')) {
        return t('onlyFans.postBy', { name: pageTitle }); // "Post von {name}"
      }
      return pageTitle;
    }
  }
  };

  const renderBreadcrumbLink = (index, pathname) => {
    const mappedPath = breadcrumbNameMap[pathname];
    
    if (
      displayPathnames[0] === 'onlyfans' &&
      displayPathnames.length === 3 &&
      index === 1
    ) {
      return null;
    }

    // Special case for last item (current page)
    if (index === displayPathnames.length - 1) {
      return (
        <Typography 
          key={index}
          color={displayPathnames[0] === 'onlyfans' ? '#4dadea' : 'primary.main'} // Use OnlyFans blue color
        >
          {pageTitle || mappedPath?.title || pathname}
        </Typography>
      );
    }

    // If it's a mapped path with redirect
    if (mappedPath?.redirectTo) {
      return (
        <Link
          key={index}
          to={`/${i18n.language}/${mappedPath.redirectTo}`}
          style={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              color: 'primary.main'
            }
          }}
        >
          {mappedPath.title}
        </Link>
      );
    }

    // Regular linked path
    if (mappedPath?.link) {
      return (
        <Link
          key={index}
          to={`/${i18n.language}/${pathname}`}
          style={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              color: 'primary.main'
            }
          }}
        >
          {mappedPath.title}
        </Link>
      );
    }

    // Fallback for unmapped paths
    return (
      <Typography 
        key={index}
        color="text.primary"
      >
        {pathname}
      </Typography>
    );
  };

  return (
    <MuiBreadcrumbs 
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ 
        mb: 3,
        mt: 2,
        '& .MuiBreadcrumbs-separator': {
          color: 'text.secondary'
        }
      }}
    >
      <Link
        to={`/${i18n.language}`}
        style={{
          color: 'inherit',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline', 
            color: 'primary.main'
          }
        }}
      >
        {t('nav.home')}
      </Link>
      
      {displayPathnames.map((pathname, index) => 
        renderBreadcrumbLink(index, pathname)
      )}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;