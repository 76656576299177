import { useState } from 'react';
import { 
  Box,
  TextField,
  Autocomplete,
  Avatar,
  Typography,
  CircularProgress,
  IconButton,
  Modal
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useTranslation } from 'react-i18next'; // Add this line
import CloseIcon from '@mui/icons-material/Close';
import { getVideos, getActors, getCategories, getGalleries } from '../utils/api';
import { getThumbnailPath } from "../App";

const SearchBar = ({ lng, isMobile, onMobileOpen, onClose, autoFocus }) => { // Add autoFocus
  const { t } = useTranslation(); // Add this line
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  const generateGallerySlug = (gallery) => {
    return gallery?.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  const handleSearch = async (searchTerm) => {
    if (!searchTerm) {
      setOptions([]);
      return;
    }
  
    setLoading(true);
    
    try {
      // Fetch all required data using API functions
      const [videos, actors, categories, galleries] = await Promise.all([
        getVideos(),
        getActors(), 
        getCategories(),
        getGalleries()
      ]);
  
      const searchResults = [];
      const term = searchTerm.toLowerCase();
  
      // Search videos
      videos.forEach(video => {
        if (video.title.toLowerCase().includes(term)) {
          searchResults.push({
            type: 'video',
            id: video.id,
            title: video.title, 
            thumbnail: getThumbnailPath(video.thumbnail),
            url: `/${lng}/video/${video.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '')}`
          });
        }
      });
  
      // Search actors
      actors.forEach(actor => {
        if (actor.name.toLowerCase().includes(term)) {
          const actorSlug = generateActorSlug(actor);
          searchResults.push({
            type: 'actor',
            id: actor.id,
            title: actor.name,
            thumbnail: getThumbnailPath(actor.photo),
            url: `/${lng}/actor/${actorSlug}`
          });
        }
      });
  
      // Find categories that have at least one video
      const categoriesWithVideos = new Set();
      videos.forEach(video => {
        if (video.categories) {
          video.categories.forEach(category => {
            categoriesWithVideos.add(category);
          });
        }
      });
      
      // Search categories (only those that have videos)
      categories.forEach(category => {
        if (category.toLowerCase().includes(term) && categoriesWithVideos.has(category)) {
          const categorySlug = category.toLowerCase().replace(/[^a-z0-9]+/g, '-');
          searchResults.push({
            type: 'category',
            id: category,
            title: category,
            thumbnail: null, // Categories typically don't have thumbnails
            url: `/${lng}/category/${categorySlug}`
          });
        }
      });
  
      // Search galleries
      galleries.forEach(gallery => {
        if (gallery.title.toLowerCase().includes(term)) {
          const gallerySlug = generateGallerySlug(gallery);
          searchResults.push({
            type: 'gallery',
            id: gallery.id,
            title: gallery.title,
            thumbnail: getThumbnailPath(gallery.thumbnail),
            url: `/${lng}/gallery/${gallerySlug}`
          });
        }
      });
      
      // Limit results to top 10 of each type for better performance
      const limitedResults = [];
      const videoResults = searchResults.filter(r => r.type === 'video').slice(0, 10);
      const actorResults = searchResults.filter(r => r.type === 'actor').slice(0, 10);
      const categoryResults = searchResults.filter(r => r.type === 'category').slice(0, 10);
      const galleryResults = searchResults.filter(r => r.type === 'gallery').slice(0, 10);
      
      limitedResults.push(...videoResults, ...actorResults, ...categoryResults, ...galleryResults);
      
      setOptions(limitedResults);
    } catch (error) {
      console.error('Search error:', error);
      setOptions([]);
    } finally {
      setLoading(false); 
    }
  };

  const getOptionIcon = (type) => {
    switch (type) {
      case 'video':
        return <VideoLibraryIcon />;
      case 'actor':
        return <PersonIcon />;
      case 'category':
        return <CategoryIcon />;
      case 'gallery':
        return <CollectionsIcon />;
      default:
        return null;
    }
  };

  // If it's mobile and not in drawer, just show the icon
  if (isMobile && !onClose) {
    return (
      <IconButton
        color="inherit"
        onClick={onMobileOpen}
        size="large"
        edge="end"
      >
        <SearchIcon />
      </IconButton>
    );
  }

  // Mobile search drawer content
  if (isMobile && onClose) {
    return (
      <Box 
        sx={{ 
          width: '100%',
          position: 'relative' // Add this to make absolute positioning work
        }}
      >
        <TextField
          fullWidth
          autoFocus={autoFocus} // Add this
          placeholder={t('search.placeholder')} // Update placeholder text
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
            endAdornment: loading && <CircularProgress color="inherit" size={20} />,
            sx: {
              pr: 5 // Add padding for the close button
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 48, // Taller input for better touch targets
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
              '&.Mui-focused': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }
            }
          }}
        />

        {/* Fixed positioned close button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 6, // Center vertically in the TextField
            color: 'text.secondary',
            zIndex: 2,
            '&:hover': {
              color: 'text.primary'
            }
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Search Results */}
        <Box sx={{ 
          mt: 2,
          maxHeight: 'calc(100vh - 180px)', // Adjust height to avoid overlap
          overflowY: 'auto'
        }}>
          {options.map((option, index) => (
            <Box
              key={index}
              onClick={() => {
                navigate(option.url);
                onClose();
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1.5,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                },
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}
            >
              {option.thumbnail ? (
                <Avatar
                  src={option.thumbnail}
                  alt={option.title}
                  variant={option.type === 'video' ? 'rounded' : 'circular'}
                  sx={{ width: 50, height: 50, mr: 2 }}
                />
              ) : (
                <Box sx={{ mr: 2 }}>
                  {getOptionIcon(option.type)}
                </Box>
              )}
              <Box>
                <Typography variant="body1">
                  {option.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t(`search.types.${option.type}`)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Box sx={{ width: '100%' }}>
        <TextField
          fullWidth
          placeholder={t('search.placeholder')} // Update placeholder text
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
            endAdornment: loading && <CircularProgress color="inherit" size={20} />
          }}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              }
            }
          }}
        />

        {/* Mobile Search Results */}
        <Box sx={{ 
          maxHeight: 'calc(100vh - 200px)', 
          overflowY: 'auto',
          pb: 2 
        }}>
          {options.map((option, index) => (
            <Box
              key={index}
              onClick={() => {
                navigate(option.url);
                onClose?.();
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1.5,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                },
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}
            >
              {option.thumbnail ? (
                <Avatar
                  src={option.thumbnail}
                  alt={option.title}
                  variant={option.type === 'video' ? 'rounded' : 'circular'}
                  sx={{ width: 50, height: 50, mr: 2 }}
                />
              ) : (
                <Box sx={{ mr: 2 }}>
                  {getOptionIcon(option.type)}
                </Box>
              )}
              <Box>
                <Typography variant="body1" noWrap>
                  {option.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t(`search.types.${option.type}`)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  // Desktop view
  return (
    <Autocomplete
      id="global-search"
      sx={{ 
        width: '100%',
        '& .MuiOutlinedInput-root': {
          height: 40,
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          }
        }
      }}
      open={open && options.length > 0}
      onOpen={() => {
        if (document.querySelector('#global-search').value.trim()) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.url === value.url}
      onChange={(event, value) => {
        if (value) {
          navigate(value.url);
        }
      }}
      onInputChange={(event, value) => handleSearch(value)}
      noOptionsText={t('search.noResults')}
      popupIcon={options.length > 0 ? undefined : null}
      groupBy={(option) => t(`search.types.${option.type}`)} // Add translation here
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('search.placeholder')}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 1
            }}
          >
            {option.thumbnail ? (
              <Avatar
                src={option.thumbnail}
                alt={option.title}
                variant={option.type === 'video' ? 'rounded' : 'circular'}
                sx={{ width: 50, height: 50, mr: 2 }}
              />
            ) : (
              <Box sx={{ mr: 2 }}>
                {getOptionIcon(option.type)}
              </Box>
            )}
            <Box>
              <Typography variant="body1">
                {option.title}
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ textTransform: 'capitalize' }}
              >
                {t(`search.types.${option.type}`)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    />
  );
};

export default SearchBar;