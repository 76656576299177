// src/common/ContentRow.js
import React, { useState, useRef, useEffect } from 'react';
import { 
  Box,
  Typography,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  useTheme,
  alpha
} from '@mui/material';
import { keyframes } from '@mui/system';
import { VIDEO_GRID_CONFIG } from '../App';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const ContentRow = ({ 
  title,
  videos,
  actors,
  lng,
  itemWidth = 300, // Default item width
  itemsToShow = 4  // Default number of items to show
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const containerRef = useRef(null);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const [preHoverVideo, setPreHoverVideo] = useState(null);
  const timeoutRef = useRef(null);

  const handleVideoClick = (video) => {
    const slug = video.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    navigate(`/${lng}/video/${slug}`);
  };

  const handleActorClick = (actorId, event) => {
    event.stopPropagation();
    navigate(`/${lng}/actor/${actorId}`);
  };

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = itemWidth * (direction === 'left' ? -1 : 1);
      const newPosition = container.scrollLeft + scrollAmount;
      
      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
    }
  };

  const checkScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      const hasHorizontalScroll = container.scrollWidth > container.clientWidth;
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(hasHorizontalScroll && container.scrollLeft < container.scrollWidth - container.clientWidth - 1);
    }
  };

  // Add this effect to check scroll buttons on mount and when videos change
  useEffect(() => {
    checkScrollButtons();
  }, [videos]); // Add videos as dependency

  // Add useEffect for cleanup
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Add mouse handlers
  const handleVideoHover = (video) => {
    if (!video.previewVideo) return;

    if (hoveredVideo === video.id || preHoverVideo === video.id) {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setPreHoverVideo(video.id);

    timeoutRef.current = setTimeout(() => {
      setHoveredVideo(video.id);
      setPreHoverVideo(null);
      timeoutRef.current = null;
    }, VIDEO_GRID_CONFIG.PREVIEW_DELAY);
  };

  const handleVideoLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setPreHoverVideo(null);
    setHoveredVideo(null);
  };

  // Add progress bar animation
  const fillAnimation = keyframes`
    0% { width: 0%; }
    100% { width: 100%; }
  `;

  // First, create a reusable style for the buttons
  const buttonBaseStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    bgcolor: 'rgba(0, 0, 0, 0.7)', // Dunklerer Hintergrund
    color: 'white',
    '&:hover': {
      bgcolor: 'rgba(0, 0, 0, 0.9)', // Noch dunkler beim Hover
    },
    // Größere Buttons
    width: 48,
    height: 48,
    padding: 1,
    // Hervorhebungseffekte
    boxShadow: '0 0 15px rgba(0,0,0,0.4)',
    border: '2px solid rgba(255,255,255,0.2)',
    backdropFilter: 'blur(4px)',
    // Bessere Sichtbarkeit des Icons
    '& .MuiSvgIcon-root': {
      fontSize: 32, // Größeres Icon
      color: '#fff',
      filter: 'drop-shadow(0 0 2px rgba(0,0,0,0.5))'
    },
    // Smooth transition
    transition: 'all 0.2s ease',
    '&:hover .MuiSvgIcon-root': {
      transform: 'scale(1.1)'
    }
  };

  // Helper function to create the video URL
  const getVideoUrl = (video) => {
    const slug = video.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    return `/${lng}/video/${slug}`;
  };

  // Helper function to get actor URL
  const getActorUrl = (actorId) => {
    const actor = actors.find(a => a.id === actorId);
    const slug = generateActorSlug(actor);
    return `/${lng}/actor/${slug}`;
  };

  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  return (
    <Box sx={{ position: 'relative', mb: 4 }}>
      {title && (
        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PlayArrowIcon sx={{ color: 'primary.main' }} />
          {title}
        </Typography>
      )}

      <Box sx={{ position: 'relative' }}>
        {/* Left shadow overlay */}
        {showLeftButton && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: 50,
              zIndex: 2,
              pointerEvents: 'none',
              background: 'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0))',
            }}
          />
        )}

        {/* Right shadow overlay */}
        {showRightButton && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              width: 50,
              zIndex: 2,
              pointerEvents: 'none',
              background: 'linear-gradient(to left, rgba(0,0,0,0.3), rgba(0,0,0,0))',
            }}
          />
        )}

        {/* Video container - remove padding since buttons are overlaid */}
        <Box
          ref={containerRef}
          id={`content-row-${title}`}
          onScroll={checkScrollButtons}
          sx={{
            display: 'flex',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',
            gap: 2,
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {videos.map((video) => (
            <Card
              component="a"
              href={getVideoUrl(video)}
              key={video.id}
              onMouseEnter={() => handleVideoHover(video)}
              onMouseLeave={handleVideoLeave}
              onClick={(e) => {
                e.preventDefault();
                handleVideoClick(video);
              }}
              sx={{
                flex: `0 0 ${itemWidth}px`,
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden', // Verhindert Überlauf
                transition: 'all 0.2s ease',
                textDecoration: 'none', // Remove underline from link
                color: 'inherit', // Inherit text color
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  boxShadow: theme => theme.shadows[8]
                }
              }}
            >
              <Box sx={{ 
                position: 'relative',
                paddingTop: '56.25%', // 16:9 Aspect Ratio
                height: 0,
                overflow: 'hidden'
              }}>
                {/* Progress bar */}
                {video.previewVideo && preHoverVideo === video.id && hoveredVideo !== video.id && (
                  <Box 
                    sx={{ 
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: 3,
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      overflow: 'hidden',
                      zIndex: 2 
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        backgroundColor: 'primary.main',
                        animation: `${fillAnimation} ${VIDEO_GRID_CONFIG.PREVIEW_DELAY/1000}s linear forwards`
                      }}
                    />
                  </Box>
                )}

                {/* Preview video or thumbnail */}
                {hoveredVideo === video.id && video.previewVideo ? (
                  <video
                    autoPlay
                    muted
                    loop
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  >
                    <source src={video.previewVideo} type="video/mp4" />
                  </video>
                ) : (
                  <CardMedia
                    component="img"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    image={video.thumbnail}
                    alt={video.title}
                  />
                )}

                {/* Duration overlay */}
                {!(hoveredVideo === video.id && video.previewVideo) && video.duration && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 8,
                      right: 8,
                      zIndex: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        padding: '2px 4px',
                        borderRadius: 1,
                        fontSize: '0.875rem'
                      }}
                    >
                      {video.duration}
                    </Typography>
                  </Box>
                )}
              </Box>

              <CardContent>
                <Typography variant="subtitle1" noWrap>
                  {video.title}
                </Typography>
                
                <Typography variant="body2" sx={{ color: 'primary.main' }} noWrap>
                  {video.actors?.map((actorId, index) => (
                    <React.Fragment key={actorId}>
                      <Box
                        component="a"
                        href={getActorUrl(actorId)}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(getActorUrl(actorId));
                        }}
                        sx={{
                          color: 'primary.main',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {actors.find(a => a.id === actorId)?.name}
                      </Box>
                      {index < video.actors.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </Typography>

              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Left scroll button - overlaid */}
        {showLeftButton && (
          <IconButton
            onClick={() => handleScroll('left')}
            sx={{
              ...buttonBaseStyle,
              left: 8,
              opacity: showLeftButton ? 1 : 0,
              visibility: showLeftButton ? 'visible' : 'hidden',
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        {/* Right scroll button - overlaid */}
        {showRightButton && (
          <IconButton
            onClick={() => handleScroll('right')}
            sx={{
              ...buttonBaseStyle,
              right: 8,
              opacity: showRightButton ? 1 : 0,
              visibility: showRightButton ? 'visible' : 'hidden',
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ContentRow;