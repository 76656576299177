import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Pagination, 
  FormControl, 
  Select, 
  MenuItem,
  IconButton,
  Popover,
  TextField,
  Stack,
  Chip,
  InputAdornment
} from '@mui/material';
import { useParams } from 'react-router-dom';
import GalleryGrid from '../common/GalleryGrid';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { WEBSITE_NAME } from '../App';
import { getLocale } from '../i18n/locales';
import { getGalleries, getActors } from '../utils/api'; // Import API functions
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function GalleriesPage() {
  const [galleries, setGalleries] = useState([]);
  const [actors, setActors] = useState([]);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('oldest'); // Default to oldest first
  const [visibleGalleries, setVisibleGalleries] = useState([]);
  const { lng } = useParams();
  const { t } = useTranslation();
  const galleriesPerPage = 12;
  
  // New state for actor filtering
  const [selectedActors, setSelectedActors] = useState([]);
  const [availableActors, setAvailableActors] = useState([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [actorSearch, setActorSearch] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [galleryData, actorData] = await Promise.all([
          getGalleries(),
          getActors()
        ]);
        setGalleries(galleryData);
        setActors(actorData);

        // Get unique actors that have galleries
        const galleryActors = new Set();
        galleryData.forEach(gallery => {
          gallery.actors?.forEach(actorId => {
            galleryActors.add(actorId);
          });
        });

        // Filter actors that are associated with galleries
        const filteredActors = actorData.filter(actor => 
          galleryActors.has(actor.id)
        );
        setAvailableActors(filteredActors);
      } catch (error) {
        console.error('Error loading galleries and actors:', error);
        setGalleries([]);
        setActors([]);
        setAvailableActors([]);
      }
    };

    fetchData();
  }, []);

  const getSortedGalleries = () => {
    // First filter by selected actors
    let filteredGalleries = [...galleries];
    
    if (selectedActors.length > 0) {
      filteredGalleries = filteredGalleries.filter(gallery => 
        selectedActors.some(actor => gallery.actors?.includes(actor.id))
      );
    }
    
    // Then apply sorting
    switch (sortBy) {
      case 'newest':
        return filteredGalleries.sort((a, b) => {
          if (!a.date) return -1;
          if (!b.date) return -1;
          return new Date(b.date) - new Date(a.date);
        });
      case 'oldest':
        return filteredGalleries.sort((a, b) => {
          if (!a.date) return -1;
          if (!b.date) return -1;
          return new Date(a.date) - new Date(b.date);
        });
      case 'relevance':
      default:
        return filteredGalleries.sort(() => Math.random() - 0.5);
    }
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1); // Reset page when sorting changes
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    // Optional callback when more galleries are loaded
    console.log('Loading more galleries...');
  };

  // Handle filter functionality
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleActorToggle = (actor) => {
    setSelectedActors(prev => {
      if (prev.some(a => a.id === actor.id)) {
        return prev.filter(a => a.id !== actor.id);
      } else {
        return [...prev, actor];
      }
    });
    setPage(1); // Reset page when filter changes
  };

  const handleActorSearch = (event) => {
    setActorSearch(event.target.value);
  };

  // Filter actors based on search
  const filteredActors = availableActors.filter(actor =>
    actor.name.toLowerCase().includes(actorSearch.toLowerCase())
  );

  const pageCount = Math.ceil(getSortedGalleries().length / galleriesPerPage);
  const startIndex = (page - 1) * galleriesPerPage;
  const sortedGalleries = getSortedGalleries();
  const displayedGalleries = sortedGalleries.slice(startIndex, startIndex + galleriesPerPage);

  return (
    <Box sx={{ pt: 2 }}>
      <Helmet>
        <title>{`${t('meta.galleries.title')}`}</title>
        <meta name="description" content={t('meta.galleries.description')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.galleries.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.galleries.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.galleries.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.galleries.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.galleries.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.galleries.description'),
            "numberOfItems": galleries.length,
            "hasPart": galleries.slice(0, 10).map(gallery => ({
              "@type": "ImageGallery",
              "name": gallery.title,
              "image": `${window.location.origin}${gallery.thumbnail}`,
              "datePublished": gallery.date
            }))
          })}
        </script>
      </Helmet>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { xs: 2, sm: 0 },
        justifyContent: { sm: 'space-between' },
        alignItems: { xs: 'stretch', sm: 'center' },
        mb: 3 
      }}>
        <Typography 
          variant="h4" 
          sx={{
            fontSize: { xs: '1.5rem', sm: '2.125rem' },
            mb: { xs: 0, sm: 1.5 }
          }}
        >
          {t('galleriesPage.title')}
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          alignItems: 'center',
          width: { xs: '100%', sm: 'auto' }
        }}>
          <FormControl 
            size="small" 
            sx={{ 
              flex: { xs: 1, sm: 'none' },
              width: { sm: 200 }
            }}
          >
            <Select
              value={sortBy}
              onChange={handleSortChange}
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                '& .MuiSelect-icon': { color: 'text.secondary' }
              }}
            >
              <MenuItem value="relevance">{t('common.sortOptions.relevance')}</MenuItem>
              <MenuItem value="newest">{t('common.sortOptions.newest')}</MenuItem>
              <MenuItem value="oldest">{t('common.sortOptions.oldest')}</MenuItem>
            </Select>
          </FormControl>
          <IconButton 
            onClick={handleFilterClick}
            color={selectedActors.length > 0 ? "primary" : "default"}
            sx={{ flexShrink: 0 }}
          >
            <FilterListIcon />
          </IconButton>
        </Box>
      </Box>

      {selectedActors.length > 0 && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
            {selectedActors.map(actor => (
              <Chip
                key={actor.id}
                label={actor.name}
                onDelete={() => handleActorToggle(actor)}
                color="primary"
              />
            ))}
          </Stack>
        </Box>
      )}

      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 400 }}>
          <Typography variant="subtitle2" gutterBottom>
            {t('common.filterByActor')}
          </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              size="small"
              placeholder={t('common.searchActors')}
              value={actorSearch}
              onChange={handleActorSearch}
              sx={{ mb: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                ...(actorSearch && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setActorSearch('')}
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }),
              }}
            />
          </Box>
          <Stack 
            direction="row" 
            spacing={1} 
            sx={{ 
              flexWrap: 'wrap', 
              gap: 1,
              maxHeight: 200,
              overflow: 'auto'
            }}
          >
            {filteredActors.map(actor => (
              <Chip
                key={actor.id}
                label={actor.name}
                onClick={() => handleActorToggle(actor)}
                color={selectedActors.some(a => a.id === actor.id) ? "primary" : "default"}
                variant={selectedActors.some(a => a.id === actor.id) ? "filled" : "outlined"}
              />
            ))}
          </Stack>
          {filteredActors.length === 0 && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                textAlign: 'center',
                mt: 2 
              }}
            >
              {t('common.noMatchingActors')}
            </Typography>
          )}
        </Box>
      </Popover>

      <GalleryGrid 
        galleries={sortedGalleries}
        actors={actors}
        lng={lng}
        infiniteScroll={true} // Enable infinite scroll
        onLoadMore={handleLoadMore}
        galleriesPerPage={galleriesPerPage}
        showPagination={false} // Disable pagination when using infinite scroll
      />
    </Box>
  );
}

export default GalleriesPage;