// src/common/ImageGrid.js
import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Card,
  Box,
  Modal,
  IconButton,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorder';

const ImageGrid = ({
  images,
  setImages, // Add this prop
  isFavoritesView = false,
  gridItemProps = { xs: 12, sm: 6, md: 4, lg: 3 },
  onImageClick,
  useCustomViewer = false // Add this prop
}) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [uiVisible, setUiVisible] = useState(true);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [favorites, setFavorites] = useState({});
  const [imageLoading, setImageLoading] = useState(true);

  // Function to load favorites from localStorage
  const loadFavorites = () => {
    const favoriteImages = JSON.parse(localStorage.getItem('favoriteImages') || '[]');
    const favoriteMap = {};
    favoriteImages.forEach(img => {
      favoriteMap[img] = true;
    });
    setFavorites(favoriteMap);
  };

  // Initial load of favorites
  useEffect(() => {
    loadFavorites();

    // Add storage event listener
    const handleStorageChange = (e) => {
      if (e.key === 'favoriteImages') {
        loadFavorites();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    
    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleImageClick = (index) => {
    if (!useCustomViewer && onImageClick) {
      onImageClick(index);
      return;
    }
    setSelectedImage(index);
  };

  const handleClose = async () => {
    if (document.fullscreenElement) {
      try {
        await document.exitFullscreen();
      } catch (err) {
        console.error('Error exiting fullscreen:', err);
      }
    }
    setSelectedImage(null);
  };

  const handlePrevious = () => {
    setSelectedImage((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNext = () => {
    setSelectedImage((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  const toggleUI = () => {
    setUiVisible(prev => !prev);
  };

  const enterFullscreen = async () => {
    try {
      await document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } catch (err) {
      console.error('Error attempting to enable fullscreen:', err);
    }
  };

  const exitFullscreen = async () => {
    try {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
        setIsFullscreen(false);
      }
    } catch (err) {
      console.error('Error attempting to exit fullscreen:', err);
    }
  };

  const toggleFavorite = (image, event) => {
    event.stopPropagation();
    const favoriteImages = JSON.parse(localStorage.getItem('favoriteImages') || '[]');
    const isCurrentlyFavorite = favoriteImages.includes(image);
    
    let newFavorites;
    if (isCurrentlyFavorite) {
      // Remove from favorites
      newFavorites = favoriteImages.filter(img => img !== image);
    } else {
      // Add to favorites
      newFavorites = [...favoriteImages, image];
    }
    
    localStorage.setItem('favoriteImages', JSON.stringify(newFavorites));
    
    // Update local favorites state
    setFavorites(prev => ({
      ...prev,
      [image]: !isCurrentlyFavorite
    }));

    // If we're in favorites view (isFavoritesView prop), filter out the removed image
    if (isFavoritesView && isCurrentlyFavorite) {
      const updatedImages = images.filter(img => (img.fullSrc || img) !== image);
      setImages(updatedImages);
    }

    // Dispatch storage event for other tabs/windows
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'favoriteImages',
      newValue: JSON.stringify(newFavorites)
    }));
  };

  const modalContent = (
    <Box sx={{ 
      position: 'relative', 
      width: '100vw',
      height: '100vh',
      backgroundColor: 'black',
      overflow: 'hidden'
    }}>
      {/* Navigation controls */}
      <Box sx={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        padding: 2,
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1000,
        opacity: uiVisible ? 1 : 0,
        transition: 'opacity 0.3s',
        visibility: uiVisible ? 'visible' : 'hidden',
      }}>
        <Typography color="white">
          {selectedImage !== null ? `${selectedImage + 1} / ${images.length}` : ''}
        </Typography>
        <Box>
          <IconButton 
            onClick={isFullscreen ? exitFullscreen : enterFullscreen}
            sx={{ color: 'white' }}
          >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
          <IconButton
            onClick={handleClose}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Left/Right Navigation Buttons */}
      {selectedImage > 0 && (
        <IconButton
          onClick={handlePrevious}
          sx={{
            position: 'fixed',
            left: 16,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.5)'
            },
            opacity: uiVisible ? 1 : 0,
            transition: 'opacity 0.3s',
            visibility: uiVisible ? 'visible' : 'hidden',
            zIndex: 1001
          }}
        >
          <ChevronLeftIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}

      {selectedImage < images.length - 1 && (
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'fixed',
            right: 16,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.5)'
            },
            opacity: uiVisible ? 1 : 0,
            transition: 'opacity 0.3s',
            visibility: uiVisible ? 'visible' : 'hidden',
            zIndex: 1001
          }}
        >
          <ChevronRightIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}

      {/* Image display */}
      <Box
        onClick={toggleUI}
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer'
        }}
      >
        <TransformWrapper
          initialScale={1}
          minScale={0.5}
          maxScale={4}
          centerOnInit={true}
        >
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
            }}
            contentStyle={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {selectedImage !== null && (
              <img
                src={images[selectedImage]?.fullSrc || images[selectedImage]} // Nutze fullSrc wenn verfügbar
                alt={`Gallery image ${selectedImage + 1}`}
                style={{
                  maxHeight: '90vh',
                  maxWidth: '100%',
                  objectFit: 'contain',
                  userSelect: 'none'
                }}
                onLoad={() => setImageLoading(false)}
                onError={(e) => {
                  console.error('Error loading image:', e);
                  setImageLoading(false);
                }}
              />
            )}
          </TransformComponent>
        </TransformWrapper>
      </Box>
    </Box>
  );

  return (
    <>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item {...gridItemProps} key={index}>
            <Card
              onClick={() => handleImageClick(index)}
              onMouseEnter={() => setHoveredImage(index)}
              onMouseLeave={() => setHoveredImage(null)}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                paddingTop: '100%', // 1:1 aspect ratio
                '&:hover': {
                  '& img': {
                    transform: 'scale(1.05)',
                    transition: 'transform 0.3s ease-in-out'
                  }
                }
              }}
            >
              <Box
                component="img"
                // Use thumbnail from /thumb/ subdirectory
                src={image.src || image.replace(/\/([^/]+)\.jpg$/, '/thumb/$1_thumb.jpg')}
                alt={`Image ${index + 1}`}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out'
                }}
              />
              
              {/* Add favorite button overlay */}
              {hoveredImage === index && (
                <IconButton
                  onClick={(e) => toggleFavorite(image.fullSrc || image, e)}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: favorites[image.fullSrc || image] ? 'primary.main' : 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                  }}
                >
                  {favorites[image.fullSrc || image] ? <FavoriteIcon /> : <FavoriteOutlinedIcon />}
                </IconButton>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Only render built-in viewer if useCustomViewer is true */}
      {useCustomViewer && (
        <Modal
          open={selectedImage !== null}
          onClose={handleClose}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'black'
            }
          }}
        >
          {modalContent}
        </Modal>
      )}
    </>
  );
};

export default ImageGrid;