import React from 'react';
import { 
  Stack,
  Card, 
  Box, 
  Typography, 
  CardMedia,
  IconButton 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { VIEWS_CONFIG } from '../App'; // Add this import at the top

const VideoList = ({ 
  videos, 
  actors,
  lng,
  onRemove, // Optional remove callback
  getTimeAgo // Function to format time ago
}) => {
  const navigate = useNavigate();

  const handleVideoClick = (video) => {
    const slug = video.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    navigate(`/${lng}/video/${slug}`);
  };

  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  const handleActorClick = (actorId, event) => {
    event.preventDefault();
    event.stopPropagation();
    const actor = actors.find(a => a.id === actorId);
    if (actor) {
      const slug = generateActorSlug(actor);
      navigate(`/${lng}/actor/${slug}`);
    }
  };

  return (
    <Stack spacing={2}>
      {videos.map((video) => (
        <Card
          component="a" 
          href={`/${lng}/video/${video.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '')}`}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            cursor: 'pointer',
            textDecoration: 'none', // Remove default link underline
            color: 'inherit', // Inherit text color
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.05)'
            }
          }}
          onClick={(e) => {
            e.preventDefault(); // Prevent default link behavior
            handleVideoClick(video); // Use router navigation instead
          }}
        >
          {/* Thumbnail container */}
          <Box sx={{ 
            position: 'relative',
            width: { xs: '100%', sm: 240 },
            height: { xs: 200, sm: 135 },
            flexShrink: 0 
          }}>
            <CardMedia
              component="img"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              image={video.thumbnail}
              alt={video.title}
            />
            {video.duration && (
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  color: 'white',
                  bgcolor: 'rgba(0, 0, 0, 0.8)',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1
                }}
              >
                {video.duration}
              </Typography>
            )}
          </Box>

          {/* Content container */}
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            p: 2,
            position: 'relative'
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 1,
                fontSize: { xs: '1rem', sm: '1.25rem' },
                pr: { xs: 4, sm: onRemove ? 4 : 0 }
              }}
            >
              {video.title}
            </Typography>
            
            <Typography 
              variant="body2" 
              sx={{ 
                mb: 1,
                fontSize: { xs: '0.875rem', sm: '1rem' }
              }}
            >
              {video.actors?.map((actorId, index) => (
                <React.Fragment key={actorId}>
                  <Box
                    component="a"
                    href={`/${lng}/actor/${generateActorSlug(actors.find(a => a.id === actorId))}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleActorClick(actorId, e);
                    }}
                    sx={{
                      color: 'primary.main',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    {actors.find(a => a.id === actorId)?.name || 'Unknown Actor'}
                  </Box>
                  {index < video.actors.length - 1 && ', '}
                </React.Fragment>
              ))}
            </Typography>

            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: 'text.secondary',
              mt: 'auto',
              fontSize: { xs: '0.75rem', sm: '0.875rem' }
            }}>
              <Typography variant="caption">
                {video.uploadDate && getTimeAgo(video.uploadDate)}
              </Typography>
              {VIEWS_CONFIG.enabled && (
                <>
                  <Typography variant="caption">•</Typography>
                  <Typography variant="caption">
                    {(() => {
                      const views = JSON.parse(localStorage.getItem('videoViews') || '{}');
                      return `${(views[video.id] || 0).toLocaleString()} views`;
                    })()}
                  </Typography>
                </>
              )}
            </Box>

            {/* Optional Remove Button */}
            {onRemove && (
              <IconButton
                onClick={(e) => {
                  e.preventDefault(); // Verhindert die Standard-Navigation
                  e.stopPropagation(); // Verhindert Event-Bubbling zum Video-Container
                  onRemove(video.id);
                }}
                sx={{ 
                  position: 'absolute',
                  top: { xs: 8, sm: 'auto' },
                  right: 8,
                  '&:hover': { 
                    color: 'error.main',
                    bgcolor: 'rgba(255, 0, 0, 0.1)'
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Card>
      ))}
    </Stack>
  );
};

export default VideoList;