// Locale mapping for Open Graph and other international contexts
export const LOCALE_MAPPING = {
    en: 'en_US',
    de: 'de_DE',
    hi: 'hi_IN',
    es: 'es_ES',
    fr: 'fr_FR',
    cn: 'zh_CN',
    tw: 'zh_TW',
    ru: 'ru_RU',
    pt: 'pt_BR',
    it: 'it_IT',
    ar: 'ar_SA',
    ja: 'ja_JP',
    ko: 'ko_KR',
    tr: 'tr_TR',
    nl: 'nl_NL'
};

export const getLocale = (language) => {
    return LOCALE_MAPPING[language] || 'en_US';
};