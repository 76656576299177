// src/common/ImageViewer.js
import React from 'react';
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/Fullscreen';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { motion, AnimatePresence } from 'framer-motion';

const TOPBAR_HEIGHT = 64;

function ImageViewer({
  open,
  onClose,
  images,
  selectedIndex,
  onIndexChange,
  t, // translations
  enableDownload = true,
  enableFullscreen = true,
  enableZoom = true,
}) {
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [uiVisible, setUiVisible] = React.useState(true);
  const [slideDirection, setSlideDirection] = React.useState(0);
  const transformComponentRef = React.useRef();
  const uiTimerRef = React.useRef(null);
  const imageContainerRef = React.useRef(null);
  const imageRef = React.useRef(null);

  // Handle UI visibility based on mouse movement
  React.useEffect(() => {
    if (!open) return;

    // Function to show UI
    const showUI = () => {
      setUiVisible(true);
      
      // Clear any existing timer
      if (uiTimerRef.current) {
        clearTimeout(uiTimerRef.current);
      }
      
      // Set new timer to hide UI after 3 seconds
      uiTimerRef.current = setTimeout(() => {
        setUiVisible(false);
      }, 3000);
    };

    // Show UI initially and set up timer
    showUI();

    // Set up mouse movement listener
    const handleMouseMove = () => {
      showUI();
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (uiTimerRef.current) {
        clearTimeout(uiTimerRef.current);
      }
    };
  }, [open]);

  // Enhanced image centering and reset when selectedIndex changes
  React.useEffect(() => {
    if (!open) return;
    
    // Force a proper reset and centering when changing images
    const centerImage = () => {
      if (transformComponentRef.current) {
        // First fully reset the transform to clear any previous state
        transformComponentRef.current.resetTransform();
        
        // Short delay to ensure DOM updates and image is loaded
        setTimeout(() => {
          // Force centering again after image has potentially loaded
          transformComponentRef.current.centerView();
        }, 100);
      }
    };
    
    // Run centering immediately
    centerImage();
    
    // Also set up a listener for image load to ensure centering after load
    const currentImage = imageRef.current;
    if (currentImage) {
      const handleImageLoad = () => {
        centerImage();
      };
      
      currentImage.addEventListener('load', handleImageLoad);
      return () => {
        currentImage.removeEventListener('load', handleImageLoad);
      };
    }
  }, [selectedIndex, open]);

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  React.useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Add keyboard zoom handler
  React.useEffect(() => {
    const handleKeyPress = (e) => {
      if (!open || !enableZoom) return;
      
      // Plus key (+) or NumpadAdd
      if (e.key === '+' || e.key === 'Add') {
        e.preventDefault();
        transformComponentRef.current?.zoomIn();
      }
      // Minus key (-) or NumpadSubtract
      else if (e.key === '-' || e.key === 'Subtract') {
        e.preventDefault();
        transformComponentRef.current?.zoomOut();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [open, enableZoom]);

  const enterFullscreen = async () => {
    try {
      await document.documentElement.requestFullscreen();
    } catch (err) {
      console.error('Error attempting to enable fullscreen:', err);
    }
  };

  const exitFullscreen = async () => {
    try {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      }
    } catch (err) {
      console.error('Error attempting to exit fullscreen:', err);
    }
  };

  const handleClose = async () => {
    if (document.fullscreenElement) {
      try {
        await document.exitFullscreen();
      } catch (err) {
        console.error('Error exiting fullscreen:', err);
      }
    }
    onClose();
  };

  const handlePrevious = () => {
    setSlideDirection(-1);
    onIndexChange(selectedIndex > 0 ? selectedIndex - 1 : images.length - 1);
  };

  const handleNext = () => {
    setSlideDirection(1);
    onIndexChange(selectedIndex < images.length - 1 ? selectedIndex + 1 : 0);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = images[selectedIndex]?.fullSrc || images[selectedIndex];
    link.download = `image-${selectedIndex + 1}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="image-viewer"
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: 'rgba(0, 0, 0, 0.9)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={handleClose}
        onWheel={(e) => {
          e.preventDefault();
          if (e.deltaY > 0) {
            setSlideDirection(1);
            handleNext();
          } else {
            setSlideDirection(-1);
            handlePrevious();
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: `calc(100% - ${TOPBAR_HEIGHT}px)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: `${TOPBAR_HEIGHT}px`
          }}
          onClick={(e) => e.stopPropagation()}
          ref={imageContainerRef}
        >
          {/* Controls Bar */}
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              padding: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: 'rgba(0, 0, 0, 0.3)', // Noch transparenter
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
              zIndex: 1500,
              opacity: uiVisible ? 1 : 0,
              transition: 'opacity 0.3s ease, visibility 0.3s ease',
              visibility: uiVisible ? 'visible' : 'hidden',
              // mixBlendMode entfernt, da es Probleme mit der Transparenz verursachen kann
            }}
          >
            {/* Image counter */}
            <Typography variant="body1" sx={{ color: 'white', userSelect: 'none' }}>
              {`${selectedIndex + 1} / ${images.length}`}
            </Typography>

            {/* Controls */}
            <Box sx={{ display: 'flex', gap: 1 }}>
              {enableZoom && (
                <>
                  <Tooltip title={t('gallery.zoomOut')} arrow>
                    <IconButton
                      onClick={() => transformComponentRef.current?.zoomOut()}
                      sx={{
                        color: 'white',
                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('gallery.zoomIn')} arrow>
                    <IconButton
                      onClick={() => transformComponentRef.current?.zoomIn()}
                      sx={{
                        color: 'white',
                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              {enableDownload && (
                <Tooltip title={t('gallery.download')} arrow>
                  <IconButton
                    onClick={handleDownload}
                    sx={{
                      color: 'white',
                      '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                    }}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
              )}

              {enableFullscreen && (
                <Tooltip title={isFullscreen ? t('gallery.exitFullscreen') : t('gallery.enterFullscreen')} arrow>
                  <IconButton
                    onClick={isFullscreen ? exitFullscreen : enterFullscreen}
                    sx={{
                      color: 'white',
                      '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                    }}
                  >
                    {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={t('gallery.close')} arrow>
                <IconButton
                  onClick={handleClose}
                  sx={{
                    color: 'white',
                    '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* Navigation Buttons */}
          {selectedIndex > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handlePrevious();
              }}
              sx={{
                position: 'fixed',
                left: 16,
                top: '50%',
                transform: 'translateY(-50%)',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                zIndex: 1500,
                opacity: uiVisible ? 1 : 0,
                transition: 'opacity 0.3s ease, visibility 0.3s ease, background-color 0.2s ease',
                visibility: uiVisible ? 'visible' : 'hidden',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' }
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: 40 }} />
            </IconButton>
          )}

          {selectedIndex < images.length - 1 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleNext();
              }}
              sx={{
                position: 'fixed',
                right: 16,
                top: '50%',
                transform: 'translateY(-50%)',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                zIndex: 1500,
                opacity: uiVisible ? 1 : 0,
                transition: 'opacity 0.3s ease, visibility 0.3s ease, background-color 0.2s ease',
                visibility: uiVisible ? 'visible' : 'hidden',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' }
              }}
            >
              <ChevronRightIcon sx={{ fontSize: 40 }} />
            </IconButton>
          )}

          {/* Image with zoom/pan */}
          <TransformWrapper
            ref={transformComponentRef}
            initialScale={1}
            minScale={0.5}
            maxScale={4}
            centerOnInit={true}
            wheel={{ disabled: true }}
            doubleClick={{ mode: 'reset' }}
            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
            limitToBounds={true}
          >
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}
              contentStyle={{
                cursor: 'move',
                '& img': {
                  cursor: 'move',
                },
                '&:active': {
                  cursor: 'grabbing',
                  '& img': {
                    cursor: 'grabbing',
                  }
                }
              }}
            >
              <AnimatePresence initial={false} mode="wait">
                <motion.div
                  key={selectedIndex}
                  initial={{ 
                    x: slideDirection * 50,
                    opacity: 0 
                  }}
                  animate={{ 
                    x: 0,
                    opacity: 1 
                  }}
                  exit={{ 
                    x: slideDirection * -50,
                    opacity: 0 
                  }}
                  transition={{
                    type: "tween",
                    ease: "easeInOut",
                    duration: 0.3
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    ref={imageRef}
                    src={images[selectedIndex]?.fullSrc || images[selectedIndex]}
                    alt={`Item ${selectedIndex + 1}`}
                    style={{
                      maxHeight: `calc(100vh - ${TOPBAR_HEIGHT * 2}px)`,
                      maxWidth: '90vw',
                      objectFit: 'contain',
                      userSelect: 'none',
                      cursor: 'move'
                    }}
                    onLoad={() => {
                      // Additional centering on image load
                      if (transformComponentRef.current) {
                        transformComponentRef.current.centerView();
                      }
                    }}
                  />
                </motion.div>
              </AnimatePresence>
            </TransformComponent>
          </TransformWrapper>
        </Box>
      </Box>
    </Modal>
  );
}

export default ImageViewer;