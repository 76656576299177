import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate, Link } from 'react-router-dom';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PeopleIcon from '@mui/icons-material/People';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CollectionsIcon from '@mui/icons-material/Collections';
import CasinoIcon from '@mui/icons-material/Casino'; // Import CasinoIcon instead of WatchLaterIcon
import VideoGrid from '../common/VideoGrid';
import VideoCarousel from '../components/VideoCarousel';
import { WEBSITE_NAME, getThumbnailPath } from '../App';
import ContentRow from '../common/ContentRow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GalleryRow from '../common/GalleryRow';
import OnlyFansRow from '../common/OnlyFansRow'; // Add this import
import { getLocale } from '../i18n/locales';
import { getVideos, getActors, getGalleries, getOnlyFans } from '../utils/api';

function Home() {
  const [videos, setVideos] = useState([]);
  const [actors, setActors] = useState([]);
  const [page, setPage] = useState(1);
  const [randomActorVideos, setRandomActorVideos] = useState([]);
  const [randomActor, setRandomActor] = useState(null);
  const [recentlyWatched, setRecentlyWatched] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [galleries, setGalleries] = useState([]);
  const [onlyFansPosts, setOnlyFansPosts] = useState([]); // Add this state
  const [contentRowsLoading, setContentRowsLoading] = useState({
    randomActor: true,
    recentlyWatched: true,
    popularVideos: true,
    onlyFans: true // Add this loading state
  });
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    // Helper function definition moved outside
    const getRandomActorWithVideos = (actorsData, videosData, excludeActorId = null) => {
      const actorsWithVideos = actorsData.filter(actor => 
        videosData.some(video => video.actors.includes(actor.id)) &&
        actor.id !== excludeActorId
      );
      if (actorsWithVideos.length === 0) return null;
      return actorsWithVideos[Math.floor(Math.random() * actorsWithVideos.length)];
    };

    const fetchData = async () => {
      try {
        const [videoData, actorData, galleryData, onlyFansData] = await Promise.all([
          getVideos(),
          getActors(),
          getGalleries(),
          getOnlyFans() // Add OnlyFans data fetch
        ]);

        setActors(actorData);
        setVideos(videoData);
        setGalleries(galleryData);

        // Get random OnlyFans posts (up to 10)
        const randomPosts = [...onlyFansData.posts]
          .sort(() => 0.5 - Math.random()) // Randomize order
          .slice(0, 10); // Take only up to 10 posts
        
        setOnlyFansPosts(randomPosts);

        // Check if history tracking is enabled before loading recently watched
        const historyEnabled = JSON.parse(localStorage.getItem('historyEnabled') ?? 'true');
        
        if (historyEnabled) {
          const viewHistory = JSON.parse(localStorage.getItem('viewHistory') || '[]');
          const recentVideos = viewHistory
            .map(entry => videoData.find(v => v.id === entry.video.id))
            .filter(Boolean)
            .slice(0, 7);
          setRecentlyWatched(recentVideos);
        } else {
          setRecentlyWatched([]); // Clear recently watched if history is disabled
        }

        // Load first random actor
        const firstRandomActor = getRandomActorWithVideos(actorData, videoData);
        if (firstRandomActor) {
          setRandomActor(firstRandomActor);
          const actorVideos = videoData.filter(video => 
            video.actors.includes(firstRandomActor.id)
          );
          setRandomActorVideos(actorVideos);
        }

        // Update all loading states
        setContentRowsLoading({
          randomActor: false,
          recentlyWatched: false,
          popularVideos: false,
          onlyFans: false // Update OnlyFans loading state
        });
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading content:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Add separate effect for localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      // Also check historyEnabled changes
      if (e.key === 'historyEnabled') {
        if (!JSON.parse(e.newValue)) {
          setRecentlyWatched([]); 
        }
      }
      else if (e.key === 'viewHistory') {
        const historyEnabled = JSON.parse(localStorage.getItem('historyEnabled') ?? 'true');
        if (historyEnabled) {
          const viewHistory = JSON.parse(e.newValue || '[]');
          getVideos().then(videoData => {
            const recentVideos = viewHistory
              .map(entry => videoData.find(v => v.id === entry.video.id))
              .filter(Boolean)
              .slice(0, 7);
            setRecentlyWatched(recentVideos);
          });
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    // Check if history is enabled
    const historyEnabled = JSON.parse(localStorage.getItem('historyEnabled') ?? 'true');
    
    // Only show recently watched if history is enabled
    if (historyEnabled) {
      const views = JSON.parse(localStorage.getItem('videoViews') || '{}');
      const uniqueVideoIds = new Set(); // Track unique video IDs
      const recentVideos = videos
        .filter(v => views[v.id])
        .sort((a, b) => views[b.id] - views[a.id])
        .filter(video => {
          // Only keep videos we haven't seen yet in this list
          if (!uniqueVideoIds.has(video.id)) {
            uniqueVideoIds.add(video.id);
            return true;
          }
          return false;
        })
        .slice(0, 6); // Get first 6 unique videos
      setRecentlyWatched(recentVideos);
    } else {
      setRecentlyWatched([]);
    }
  }, [videos]);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInDays = Math.floor(diffInSeconds / 86400);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${t(`time.${diffInYears === 1 ? 'year' : 'years'}`)} ${t('time.ago')}`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${t(`time.${diffInMonths === 1 ? 'month' : 'months'}`)} ${t('time.ago')}`;
    } else {
      return `${diffInDays} ${t(`time.${diffInDays === 1 ? 'day' : 'days'}`)} ${t('time.ago')}`;
    }
  };

  const getPopularVideos = (videoList, existingVideos = []) => {
    // Get all views
    const views = JSON.parse(localStorage.getItem('videoViews') || '{}');
    
    // Sort videos by views and filter out ones already shown
    const sortedVideos = videoList
      .filter(v => !existingVideos.includes(v))
      .sort((a, b) => (views[b.id] || 0) - (views[a.id] || 0));
  
    const selectedVideos = [];
    const selectedActors = new Set();
  
    // Try to get videos with different actors
    for (const video of sortedVideos) {
      if (selectedVideos.length >= 10) break;
  
      const hasNewActor = video.actors.some(actorId => !selectedActors.has(actorId));
      if (hasNewActor || selectedActors.size === 0) {
        selectedVideos.push(video);
        video.actors.forEach(actorId => selectedActors.add(actorId));
      }
    }
  
    // Fill remaining slots if needed
    while (selectedVideos.length < 10 && sortedVideos.length > selectedVideos.length) {
      const remaining = sortedVideos.find(v => !selectedVideos.includes(v));
      if (remaining) selectedVideos.push(remaining);
    }
  
    return selectedVideos;
  };

  // Define the same menu items as in Navbar
  const quickLinks = [
    { 
      text: t('nav.videos'), 
      icon: <VideoLibraryIcon />, 
      path: `/${lng}/videos`,
      showOnMobile: true
    },
    { 
      text: t('nav.actors'), 
      icon: <PeopleIcon />, 
      path: `/${lng}/actors`,
      showOnMobile: false
    },
    { 
      text: t('nav.categories'), 
      icon: <LoyaltyIcon />, 
      path: `/${lng}/categories`,
      showOnMobile: false
    },
    { 
      text: t('nav.galleries'), 
      icon: <CollectionsIcon />, 
      path: `/${lng}/galleries`,
      showOnMobile: true
    },
    { 
      text: t('nav.onlyfans'), 
      icon: <FavoriteIcon sx={{ fontSize: { xs: 28, sm: 40 } }} />, 
      path: `/${lng}/onlyfans`,
      showOnMobile: true
    },
    { 
      text: "Feeling Lucky?", 
      icon: <CasinoIcon />, // Changed from WatchLaterIcon to CasinoIcon
      path: '#',
      showOnMobile: false,
      action: (e) => {
        e.preventDefault();
        if (videos.length > 0) {
          const randomVideo = videos[Math.floor(Math.random() * videos.length)];
          const slug = randomVideo.title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/^-+|-+$/g, '');
          navigate(`/${lng}/video/${slug}`);
        }
      }
    }
  ];

  return (
    <Box sx={{ pt: 2 }}>
      <Helmet>
        <title>{`${t('meta.home.title')}`}</title>
        <meta name="description" content={t('meta.home.description')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.home.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.home.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.home.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.home.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org WebPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": `${t('meta.home.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.home.description'),
            "url": window.location.href,
            "hasPart": [
              ...videos.slice(0, 10).map(video => ({
                "@type": "VideoObject",
                "name": video.title,
                "thumbnailUrl": `${window.location.origin}${video.thumbnail}`,
                "uploadDate": video.uploadDate,
                "duration": video.duration
              })),
              ...galleries.slice(0, 5).map(gallery => ({
                "@type": "ImageGallery",
                "name": gallery.title,
                "image": `${window.location.origin}${gallery.thumbnail}`,
                "datePublished": gallery.date
              }))
            ]
          })}
        </script>
      </Helmet>
      
      <VideoCarousel 
        videos={videos.map(video => ({
          ...video,
          thumbnail: getThumbnailPath(video.thumbnail)
        }))} 
        actors={actors} 
        lng={lng} 
      />

      {/* Quick Access Links */}
      <Box sx={{ my: 4 }}>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {quickLinks.map((link) => (
            // Only render if showOnMobile is true on xs screens, show all on sm and up
            <Grid 
              item 
              xs={4} 
              sm={4} 
              key={link.path}
              sx={{ 
                display: { 
                  xs: link.showOnMobile ? 'block' : 'none', 
                  sm: 'block' 
                } 
              }}
            >
              <Card
                component={link.action ? 'div' : Link}
                {...(link.action ? { onClick: link.action } : { to: link.path })}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: { xs: 1, sm: 2 },
                  transition: 'all 0.2s ease',
                  cursor: 'pointer', // Add cursor pointer for clickable effect
                  background: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02))',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(8px)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                    background: 'linear-gradient(rgba(216, 158, 0, 0.1), rgba(216, 158, 0, 0.05))',
                    '& .MuiSvgIcon-root': {
                      color: 'primary.main',
                      transform: 'scale(1.1)'
                    }
                  }
                }}
              >
                <Box
                  sx={{
                    p: { xs: 1, sm: 1.5 },
                    borderRadius: '50%',
                    bgcolor: 'rgba(255, 255, 255, 0.03)',
                    mb: 1,
                    width: { xs: 48, sm: 64 }, // Fixed width
                    height: { xs: 48, sm: 64 }, // Same as width for perfect circle
                    display: 'flex',  // Enable flexbox
                    alignItems: 'center', // Center vertically 
                    justifyContent: 'center', // Center horizontally
                  }}
                >
                  {React.cloneElement(link.icon, { 
                    sx: { 
                      fontSize: { xs: 24, sm: 32 }, // Slightly smaller icons
                      transition: 'all 0.2s ease',
                      color: 'text.secondary',
                    }
                  })}
                </Box>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: 'text.primary',
                    fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                    textAlign: 'center',
                    fontWeight: 500
                  }}
                >
                  {link.text}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Create array of content rows and shuffle them */}
      {(() => {
        const popularVideos = getPopularVideos(videos, [...randomActorVideos, ...recentlyWatched]);
        
        const contentRows = [
          recentlyWatched.length > 0 && {
            id: 'recentlyWatched',
            title: t('home.recentlyWatched'),
            videos: recentlyWatched,
            loading: contentRowsLoading.recentlyWatched,
            show: true
          },
          {
            id: 'popularVideos',
            title: t('home.popularVideos'),
            videos: popularVideos,
            loading: contentRowsLoading.popularVideos,
            show: videos.length > 0
          },
          onlyFansPosts.length > 0 && {
            id: 'onlyFansPosts',
            title: t('home.popularOnlyFansPosts'),
            type: 'onlyfans',
            posts: onlyFansPosts,
            loading: contentRowsLoading.onlyFans,
            show: true
          }
        ].filter(Boolean);
  
        // Shuffle array
        for (let i = contentRows.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [contentRows[i], contentRows[j]] = [contentRows[j], contentRows[i]];
        }
  
        return contentRows.map((row) => (
          <Box key={row.id}>
            {row.loading ? (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                alignItems: 'center',
                height: 200,
                bgcolor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: 1,
                mb: 4
              }}>
                <CircularProgress />
              </Box>
            ) : row.type === 'onlyfans' ? (
              <OnlyFansRow
                title={row.title}
                posts={row.posts}
                actors={actors}
                lng={lng}
                sx={{ mb: 4 }}
              />
            ) : (
              <ContentRow
                title={row.title}
                videos={row.videos.map(video => ({
                  ...video,
                  thumbnail: getThumbnailPath(video.thumbnail)
                }))}
                actors={actors}
                lng={lng}
                sx={{ mb: 4 }}
              />
            )}
          </Box>
        ));
      })()}

      {/* Add popular galleries row */}
      <GalleryRow
        title={t('home.popularGalleries')}
        galleries={galleries
          .sort(() => 0.5 - Math.random()) // Randomize galleries
          .slice(0, 10) // Take first 10
          .map(gallery => ({
            ...gallery,
            thumbnail: getThumbnailPath(gallery.thumbnail)
          }))}
        actors={actors}
        lng={lng}
      />

      <VideoGrid
        videos={videos
          .sort(() => 0.5 - Math.random()) // Keep random sorting but pass all videos
          .map(video => ({
            ...video,
            thumbnail: getThumbnailPath(video.thumbnail)
          }))}
        actors={actors}
        title={t('home.recommendedVideos')}
        videosPerPage={12} // Match VideosPage loading amount
        showPagination={false} // Disable pagination since we're using infinite scroll
        lng={lng}
        getTimeAgo={getTimeAgo}
        infiniteScroll={true} // Enable infinite scrolling
        onLoadMore={() => {}} // Empty callback since sorting is handled in videos prop
      />
    </Box>
  );
}

export default Home;