import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Add this import
import { Helmet } from 'react-helmet-async'; // Add this import
import { WEBSITE_NAME } from '../App'; // Add this import
import VideoGrid from '../common/VideoGrid';
import ErrorPage from './ErrorPage';
import { getLocale } from '../i18n/locales';
import { getVideos, getActors, getCategories } from '../utils/api'; // Add API imports
import { getThumbnailPath } from '../App'; // Add this import

const VIDEOS_PER_LOAD = 12;

function CategoryVideosPage() {
  const { t } = useTranslation(); // Add translation hook
  const [videos, setVideos] = useState([]);
  const [actors, setActors] = useState([]);
  const [page, setPage] = useState(1);
  const [categoryExists, setCategoryExists] = useState(true);
  const { categorySlug, lng } = useParams();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState(''); // Add state for category name
  const [visibleVideos, setVisibleVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use Promise.all with the API functions 
        const [videoData, actorData, categories] = await Promise.all([
          getVideos(),
          getActors(),
          getCategories()
        ]);

        // Find matching category
        const matchingCategory = categories.find(category => {
          const categorySlugNormalized = category
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/^-+|-+$/g, '');
          return categorySlugNormalized === categorySlug;
        });

        if (!matchingCategory) {
          setCategoryExists(false);
          return;
        }

        setCategoryName(matchingCategory);

        const filteredVideos = videoData.filter(video => 
          video.categories.includes(matchingCategory)
        );
        setVideos(filteredVideos);
        setActors(actorData);

      } catch (error) {
        console.error('Error loading category page data:', error);
        setCategoryExists(false);
      }
    };

    fetchData();
  }, [categorySlug]);

  useEffect(() => {
    setVisibleVideos(videos.slice(0, VIDEOS_PER_LOAD));
  }, [videos]);

  const handleLoadMore = () => {
    if (visibleVideos.length >= videos.length) return;
    setVisibleVideos(videos.slice(0, visibleVideos.length + VIDEOS_PER_LOAD));
  };

  if (!categoryExists) {
    return (
      <ErrorPage 
        code={404}
        title="Category Not Found"
        message="The category you are looking for does not exist."
      />
    );
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInDays = Math.floor(diffInSeconds / 86400);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Helmet>
        <title>{`${categoryName} - ${t('meta.category.title')} - ${WEBSITE_NAME}`}</title>
        <meta name="description" content={t('meta.category.description', { category: categoryName })} />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content={`${categoryName} - ${t('meta.category.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.category.description', { category: categoryName })} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${categoryName} - ${t('meta.category.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.category.description', { category: categoryName })} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${categoryName} - ${t('meta.category.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.category.description', { category: categoryName }),
            "numberOfItems": videos.length,
            "hasPart": videos.slice(0, 10).map(video => ({
              "@type": "VideoObject",
              "name": video.title,
              "description": video.description,
              "thumbnailUrl": `${window.location.origin}${video.thumbnail}`,
              "uploadDate": video.uploadDate,
              "duration": video.duration
            }))
          })}
        </script>
      </Helmet>
      <Typography variant="h4" gutterBottom>
        {categoryName}
      </Typography>

      {videos.length > 0 ? (
        <VideoGrid
          videos={visibleVideos.map(video => ({
            ...video,
            thumbnail: getThumbnailPath(video.thumbnail)
          }))}
          actors={actors} 
          infiniteScroll={true}
          onLoadMore={handleLoadMore}
          lng={lng}
          getTimeAgo={getTimeAgo}
        />
      ) : (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 200,
            bgcolor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: 1,
            mt: 4
          }}
        >
          <Typography variant="h6" color="text.secondary">
            {t('categories.noVideos', { category: categoryName })}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default CategoryVideosPage;