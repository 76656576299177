import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Switch, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import VideoList from '../common/VideoList';
import DeleteIcon from '@mui/icons-material/Delete';
import { WEBSITE_NAME } from '../App';
import { getLocale } from '../i18n/locales';
import { getActors } from '../utils/api'; // Add this import at the top
import { getThumbnailPath } from '../App'; // Add this import at the top

function HistoryPage() {
  const [historyByDate, setHistoryByDate] = useState({});
  const [actors, setActors] = useState([]);
  const [historyEnabled, setHistoryEnabled] = useState(() => {
    return JSON.parse(localStorage.getItem('historyEnabled') ?? 'true');
  });
  const { lng } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const loadHistory = () => {
      // Get view history from localStorage
      const rawHistory = JSON.parse(localStorage.getItem('viewHistory') || '[]');
      
      // Group entries by date and video ID 
      const uniqueDailyHistory = rawHistory.reduce((acc, entry) => {
        const date = new Date(entry.timestamp);
        const dateStr = date.toLocaleDateString();
        const videoId = entry.video.id;

        const key = `${dateStr}_${videoId}`;

        if (!acc[key]) {
          acc[key] = entry;
        }

        return acc;
      }, {});

      // Convert back to array and group by date
      const history = Object.values(uniqueDailyHistory).reduce((acc, item) => {
        const date = new Date(item.timestamp);
        const dateStr = date.toLocaleDateString(lng === 'de' ? 'de-DE' : 'en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });

        if (!acc[dateStr]) {
          acc[dateStr] = [];
        }
        
        // Add thumbnail path transformation here with proper path handling
        acc[dateStr].push({
          ...item.video,
          thumbnail: getThumbnailPath(item.video.thumbnail)
        });
        return acc;
      }, {});

      setHistoryByDate(history);
    };

    const fetchActors = async () => {
      try {
        const actorsData = await getActors();
        setActors(actorsData);
      } catch (error) {
        console.error('Error loading actors:', error);
        setActors([]); // Set empty array on error
      }
    };

    fetchActors();
    loadHistory();
  }, [lng]);

  const handleHistoryToggle = (event) => {
    const enabled = event.target.checked;
    setHistoryEnabled(enabled);
    localStorage.setItem('historyEnabled', JSON.stringify(enabled));
  };

  const handleClearHistory = () => {
    localStorage.setItem('viewHistory', '[]');
    localStorage.setItem('videoViews', '{}');
    setHistoryByDate({});
  };

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInDays = Math.floor(diffInSeconds / 86400);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  // Add a helper function to generate actor slugs
  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  return (
    <Box sx={{ pt: 2, px: { xs: 1, sm: 2 } }}>
      <Helmet>
        <title>{`${t('meta.history.title')}`}</title>
        <meta name="description" content={t('meta.history.description')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.history.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.history.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.history.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.history.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.history.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.history.description'),
            "numberOfItems": Object.values(historyByDate).flat().length,
            "hasPart": Object.values(historyByDate).flat().slice(0, 10).map(video => ({
              "@type": "VideoObject",
              "name": video.title,
              "thumbnailUrl": `${window.location.origin}${video.thumbnail}`,
              "uploadDate": video.uploadDate,
              "duration": video.duration
            }))
          })}
        </script>
      </Helmet>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
        alignItems: { xs: 'stretch', sm: 'center' },
        gap: 2,
        mb: 3 
      }}>
        <Typography variant="h4" sx={{ mb: { xs: 2, sm: 0 } }}>
          {t('nav.history')}
        </Typography>
        
        <Box sx={{ 
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 2,
          ml: { sm: 'auto' }
        }}>
          <FormControlLabel
            control={
              <Switch
                checked={historyEnabled}
                onChange={handleHistoryToggle}
                color="primary"
              />
            }
            label={t('history.tracking')}
          />
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleClearHistory}
            disabled={Object.keys(historyByDate).length === 0}
            sx={{
              borderColor: 'rgba(255, 0, 0, 0.3)',
              '&:hover': {
                borderColor: 'rgba(255, 0, 0, 0.5)',
                backgroundColor: 'rgba(255, 0, 0, 0.1)'
              }
            }}
          >
            {t('history.clear')}
          </Button>
        </Box>
      </Box>

      {Object.entries(historyByDate).length > 0 ? (
        Object.entries(historyByDate)
          .sort((a, b) => new Date(b[0]) - new Date(a[0]))
          .map(([date, videos]) => (
            <Box key={date} sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 2,
                  color: 'text.secondary',
                  fontWeight: 500
                }}
              >
                {date}
              </Typography>
              <VideoList
                videos={videos}
                actors={actors}
                lng={lng}
                getTimeAgo={getTimeAgo}
              />
            </Box>
          ))
      ) : (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 200,
            bgcolor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: 1
          }}
        >
          <Typography variant="h6" color="text.secondary">
            {t('history.noVideos')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default HistoryPage;