import React, { useState, useRef, useEffect } from 'react';
import { 
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  useTheme
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from 'react-router-dom';

const GalleryRow = ({ 
  title,
  galleries,
  actors,
  lng,
  itemWidth = 300,
  itemsToShow = 4
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const containerRef = useRef(null);
  
  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = itemWidth * (direction === 'left' ? -1 : 1);
      const newPosition = container.scrollLeft + scrollAmount;
      
      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
    }
  };

  const checkScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      const hasHorizontalScroll = container.scrollWidth > container.clientWidth;
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(hasHorizontalScroll && container.scrollLeft < container.scrollWidth - container.clientWidth - 1);
    }
  };

  useEffect(() => {
    checkScrollButtons();
  }, [galleries]);

  const getGalleryUrl = (gallery) => {
    const slug = gallery.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    return `/${lng}/gallery/${slug}`;
  };

  const handleGalleryClick = (gallery) => {
    navigate(getGalleryUrl(gallery));
  };

  return (
    <Box sx={{ position: 'relative', mb: 4 }}>
      {title && (
        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PlayArrowIcon sx={{ color: 'primary.main' }} />
          {title}
        </Typography>
      )}

      <Box sx={{ position: 'relative' }}>
        {/* Left shadow overlay */}
        {showLeftButton && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              width: 50,
              zIndex: 2,
              pointerEvents: 'none',
              background: 'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0))',
            }}
          />
        )}

        {/* Right shadow overlay */}
        {showRightButton && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              width: 50,
              zIndex: 2,
              pointerEvents: 'none',
              background: 'linear-gradient(to left, rgba(0,0,0,0.3), rgba(0,0,0,0))',
            }}
          />
        )}

        {/* Gallery container */}
        <Box
          ref={containerRef}
          onScroll={checkScrollButtons}
          sx={{
            display: 'flex',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',
            gap: 2,
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {galleries.map((gallery) => (
            <Card
              component="a"
              href={getGalleryUrl(gallery)}
              key={gallery.id}
              onClick={(e) => {
                e.preventDefault();
                handleGalleryClick(gallery);
              }}
              sx={{
                flex: `0 0 ${itemWidth}px`,
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.2s ease',
                textDecoration: 'none',
                color: 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  boxShadow: theme.shadows[8]
                }
              }}
            >
              <Box sx={{ position: 'relative', width: '100%', aspectRatio: '16/9' }}>
                {/* Gallery preview images */}
                {(() => {
                  const firstImage = "0001";
                  const middleImage = String(Math.ceil(gallery.imageCount / 2)).padStart(4, "0");
                  const lastImage = String(gallery.imageCount).padStart(4, "0");
                  
                  return [firstImage, middleImage, lastImage].map((num, index) => (
                    <Box
                      key={index}
                      component="img"  
                      src={gallery.thumb === false 
                        ? `${gallery.path}/${num}.jpg`
                        : `${gallery.path}/thumb/${num}_thumb.jpg`
                      }
                      alt={`${gallery.title} preview ${index + 1}`}
                      sx={{
                        position: "absolute",
                        height: "100%",
                        objectFit: "cover",
                        ...(index === 0 && {
                          width: "50%",
                          left: 0,
                          top: 0
                        }),
                        ...(index === 1 && {
                          width: "50%",
                          right: 0,
                          top: 0,
                          height: "50%"
                        }),
                        ...(index === 2 && {
                          width: "50%",
                          right: 0,
                          bottom: 0,
                          height: "50%"
                        })
                      }}
                    />
                  ));
                })()}

                {/* Image count indicator */}
                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    padding: '4px 8px',
                    borderRadius: '0 0 4px 0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    zIndex: 2,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      component="svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      sx={{ mr: 0.5 }}
                    >
                      <path
                        fill="currentColor"
                        d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"
                      />
                    </Box>
                    {gallery.imageCount}
                  </Box>
                </Box>
              </Box>

              <CardContent>
                <Typography variant="subtitle1" noWrap>
                  {gallery.title}
                </Typography>
                
                <Typography variant="body2" color="text.secondary" noWrap>
                  {gallery.imageCount} images
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Navigation buttons */}
        {showLeftButton && (
          <IconButton
            onClick={() => handleScroll('left')}
            sx={{
              position: 'absolute',
              left: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.9)',
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        {showRightButton && (
          <IconButton
            onClick={() => handleScroll('right')}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.9)',
              }
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default GalleryRow;