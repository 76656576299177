import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import VideoList from '../common/VideoList';
import { WEBSITE_NAME, getThumbnailPath } from '../App'; // Add getThumbnailPath import
import { getLocale } from '../i18n/locales';
import { getActors } from '../utils/api'; // Add this import

function WatchLater() {
  const [watchLaterList, setWatchLaterList] = useState([]);
  const [actors, setActors] = useState([]);
  const { lng } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [watchLaterData, actorsData] = await Promise.all([
          Promise.resolve(JSON.parse(localStorage.getItem('watchLater') || '[]')),
          getActors()
        ]);
        
        // Map through watchLaterData and update thumbnail paths
        const processedWatchLaterData = watchLaterData.map(video => ({
          ...video,
          thumbnail: getThumbnailPath(video.thumbnail)
        }));
        
        setWatchLaterList(processedWatchLaterData);
        setActors(actorsData);
      } catch (error) {
        console.error('Error loading watch later data:', error);
        setWatchLaterList([]);
        setActors([]);
      }
    };

    fetchData();
  }, []);

  const handleRemove = (videoId) => {
    const updatedList = watchLaterList.filter(video => video.id !== videoId);
    setWatchLaterList(updatedList);
    localStorage.setItem('watchLater', JSON.stringify(updatedList));
  };

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInDays = Math.floor(diffInSeconds / 86400);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  return (
    <Box sx={{ pt: 2, px: { xs: 1, sm: 2 } }}>
      <Helmet>
        <title>{`${t('meta.watchLater.title')}`}</title>
        <meta name="description" content={t('meta.watchLater.description')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.watchLater.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.watchLater.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.watchLater.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.watchLater.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.watchLater.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.watchLater.description'),
            "numberOfItems": watchLaterList.length,
            "hasPart": watchLaterList.slice(0, 10).map(video => ({
              "@type": "VideoObject",
              "name": video.title,
              "thumbnailUrl": `${window.location.origin}${video.thumbnail}`,
              "uploadDate": video.uploadDate,
              "duration": video.duration
            }))
          })}
        </script>
      </Helmet>

      <Typography variant="h4" gutterBottom>
        {t('watchLater.title')}
      </Typography>
      
      {watchLaterList.length > 0 ? (
        <VideoList
          videos={watchLaterList}
          actors={actors}
          lng={lng}
          onRemove={handleRemove}
          getTimeAgo={getTimeAgo}
        />
      ) : (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 200,
            bgcolor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: 1
          }}
        >
          <Typography variant="h6" color="text.secondary">
            {t('watchLater.noVideos')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default WatchLater;