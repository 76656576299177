// src/common/GalleryGrid.js
import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Pagination
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ImageIcon from '@mui/icons-material/Image'; // Changed from VisibilityIcon to ImageIcon
import { useInView } from 'react-intersection-observer';

const GalleryGrid = ({ 
  galleries, 
  actors,
  lng,
  isFavoritesView = false,
  gridItemProps = { xs: 12, sm: 6, md: 4 },
  infiniteScroll = false,
  onLoadMore = () => {},
  galleriesPerPage = 12,
  showPagination = true,
  page = 1,
  onPageChange = () => {}
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hoveredGallery, setHoveredGallery] = useState(null);
  const [visibleGalleries, setVisibleGalleries] = useState(galleriesPerPage);
  
  const pageCount = Math.ceil(galleries.length / galleriesPerPage);

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '100px 0px'
  });

  useEffect(() => {
    if (inView && infiniteScroll && visibleGalleries < galleries.length) {
      setVisibleGalleries(prev => Math.min(prev + galleriesPerPage, galleries.length));
      onLoadMore();
    }
  }, [inView, infiniteScroll, galleries.length, visibleGalleries, onLoadMore, galleriesPerPage]);

  const displayedGalleries = infiniteScroll 
    ? galleries.slice(0, visibleGalleries)
    : galleries.slice((page - 1) * galleriesPerPage, page * galleriesPerPage);

  const getTimeAgo = (dateString) => {
    if (!dateString) return null;
    
    const date = new Date(dateString);
    const now = new Date();
    const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${t(`time.${diffInYears === 1 ? "year" : "years"}`)} ${t("time.ago")}`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${t(`time.${diffInMonths === 1 ? "month" : "months"}`)} ${t("time.ago")}`;
    } else {
      return `${diffInDays} ${t(`time.${diffInDays === 1 ? "day" : "days"}`)} ${t("time.ago")}`;
    }
  };

  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  const handleGalleryClick = (event, gallery) => {
    event.preventDefault();
    const slug = gallery.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
    navigate(`/${lng}/gallery/${slug}`);
  };

  const handleActorClick = (event, actorId) => {
    event.preventDefault();
    event.stopPropagation();
    const actor = actors.find(a => a.id === actorId);
    const slug = generateActorSlug(actor);
    navigate(`/${lng}/actor/${slug}`);
  };

  const getActorNames = (actorIds) => {
    return actorIds.map((actorId, index) => {
      const actor = actors.find(a => a.id === actorId);
      const slug = generateActorSlug(actor);
      
      return (
        <React.Fragment key={actorId}>
          <Box
            component="a" 
            href={`/${lng}/actor/${slug}`}
            onClick={(e) => handleActorClick(e, actorId)}
            sx={{
              color: 'primary.main',
              textDecoration: 'none',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {actor?.name}
          </Box>
          {index < actorIds.length - 1 && ', '}
        </React.Fragment>
      );
    });
  };

  const getGalleryThumbnails = (gallery) => {
    const firstImage = '0001';
    const middleImage = String(Math.ceil(gallery.imageCount / 2)).padStart(4, '0');
    const lastImage = String(gallery.imageCount).padStart(4, '0');
    
    return [firstImage, middleImage, lastImage].map(num => 
      gallery.thumb === false 
        ? `${gallery.path}/${num}.jpg`
        : `${gallery.path}/thumb/${num}_thumb.jpg`
    );
  };

  const handleGalleryFavorite = (galleryId, event) => {
    event.stopPropagation();
    const favoriteGalleries = JSON.parse(localStorage.getItem('favoriteGalleries') || '[]');
    const newFavorites = favoriteGalleries.filter(id => id !== galleryId);
    localStorage.setItem('favoriteGalleries', JSON.stringify(newFavorites));

    window.dispatchEvent(new StorageEvent('storage', {
      key: 'favoriteGalleries',
      newValue: JSON.stringify(newFavorites)
    }));
    
    document.dispatchEvent(new Event('galleryFavoriteChanged'));
  };

  return (
    <>
      <Grid container spacing={3}>
        {displayedGalleries.map((gallery) => (
          <Grid item {...gridItemProps} key={gallery.id}>
            <Card 
              sx={{
                cursor: 'pointer',
                height: '100%',
                display: 'flex', 
                flexDirection: 'column',
                position: 'relative',
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  boxShadow: theme => theme.shadows[8]
                }
              }}
              onClick={(e) => handleGalleryClick(e, gallery)}
              onMouseEnter={() => setHoveredGallery(gallery.id)}
              onMouseLeave={() => setHoveredGallery(null)}
            >
              {isFavoritesView && hoveredGallery === gallery.id && (
                <IconButton
                  onClick={(e) => handleGalleryFavorite(gallery.id, e)}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                  }}
                >
                  <FavoriteIcon />
                </IconButton>
              )}

              <Box sx={{ position: 'relative', width: '100%', aspectRatio: '16/9' }}>
                {getGalleryThumbnails(gallery).map((imgSrc, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={imgSrc}
                    alt={`${gallery.title} preview ${index + 1}`}
                    sx={{
                      position: 'absolute',
                      height: '100%',
                      objectFit: 'cover',
                      ...(index === 0 && {
                        width: '50%',
                        left: 0,
                        top: 0,
                      }),
                      ...(index === 1 && {
                        width: '50%',
                        right: 0,
                        top: 0,
                        height: '50%',
                      }),
                      ...(index === 2 && {
                        width: '50%',
                        right: 0,
                        bottom: 0,
                        height: '50%',
                      }),
                    }}
                  />
                ))}
                
                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    padding: '4px 8px',
                    borderRadius: '0 0 4px 0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    zIndex: 2,
                  }}
                >
                  <ImageIcon fontSize="small" />
                  <Typography variant="caption" fontWeight="bold">
                    {gallery.imageCount} {t('galleryGrid.images')}
                  </Typography>
                </Box>
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2" noWrap>
                  {gallery.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'primary.main' }} noWrap>
                  {t('galleryGrid.featuring')}: {getActorNames(gallery.actors)}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}>
                  {gallery.date && (
                    <Typography variant="caption" color="text.secondary">
                      {getTimeAgo(gallery.date)}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {infiniteScroll && visibleGalleries < galleries.length && (
        <Box 
          ref={loadMoreRef}
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            p: 4,
            minHeight: '100px'
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!infiniteScroll && showPagination && pageCount > 1 && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Pagination 
            count={pageCount} 
            page={page} 
            onChange={onPageChange}
            color="primary"
            size="large"
          />
        </Box>
      )}
    </>
  );
};

export default GalleryGrid;