import React, { useEffect, useRef, useState } from 'react';

const VideoAdsManager = ({ onAdComplete, videoRef }) => {
  const adContainerRef = useRef(null);
  const adsManagerRef = useRef(null);
  const adsLoaderRef = useRef(null);
  const [adError, setAdError] = useState(null);

  const checkSecureOrigin = () => {
    const isSecure = window.location.protocol === 'https:' || 
                     window.location.hostname === 'localhost' ||
                     window.location.hostname === '127.0.0.1';
    if (!isSecure) {
      console.warn('IMA SDK requires a secure origin (HTTPS) or localhost');
      // Fall back to completing the ad if we're not in a secure context
      onAdComplete();
      return false;
    }
    return true;
  };

  useEffect(() => {
    // Load IMA SDK script with error handling
    if (!window.google?.ima) {
      const script = document.createElement('script');
      script.src = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js';
      script.async = true;
      script.onerror = () => {
        console.warn('Failed to load IMA SDK');
        onAdComplete();
      };
      script.onload = initializeAds;
      document.head.appendChild(script);
      
      return () => {
        // Clean up script if component unmounts during loading
        if (document.head.contains(script)) {
          document.head.removeChild(script);
        }
      };
    } else {
      initializeAds();
    }
  }, []);

  const initializeAds = () => {
    if (!window.google?.ima || !videoRef.current || !adContainerRef.current) return;
    
    // Check for secure origin before proceeding
    if (!checkSecureOrigin()) return;

    try {
      // Initialize the ads loader
      const adDisplayContainer = new window.google.ima.AdDisplayContainer(
        adContainerRef.current,
        videoRef.current
      );

      // Initialize the container immediately to handle mobile devices better
      try {
        adDisplayContainer.initialize();
      } catch (initError) {
        console.warn('Ad display container initialization failed:', initError);
        onAdComplete();
        return;
      }

      // Create ads loader with error handling
      try {
        adsLoaderRef.current = new window.google.ima.AdsLoader(adDisplayContainer);
      } catch (loaderError) {
        console.warn('Failed to create ads loader:', loaderError);
        onAdComplete();
        return;
      }

      // Add event listeners
      adsLoaderRef.current.addEventListener(
        window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        onAdsManagerLoaded,
        false
      );

      adsLoaderRef.current.addEventListener(
        window.google.ima.AdErrorEvent.Type.AD_ERROR,
        onAdError,
        false
      );

      // Request video ads with error handling
      try {
        const adsRequest = new window.google.ima.AdsRequest();
        adsRequest.adTagUrl = 'https://s.magsrv.com/v1/vast.php?idzone=5553968&=';
        adsRequest.linearAdSlotWidth = videoRef.current.clientWidth;
        adsRequest.linearAdSlotHeight = videoRef.current.clientHeight;
        adsRequest.nonLinearAdSlotWidth = videoRef.current.clientWidth;
        adsRequest.nonLinearAdSlotHeight = videoRef.current.clientHeight / 3;

        // Set additional request parameters for better compatibility
        adsRequest.setAdWillAutoPlay(true);
        adsRequest.setAdWillPlayMuted(true);

        adsLoaderRef.current.requestAds(adsRequest);
      } catch (requestError) {
        console.warn('Failed to request ads:', requestError);
        onAdComplete();
      }
    } catch (error) {
      console.warn('Failed to initialize ads:', error);
      onAdComplete();
    }
  };

  const onAdsManagerLoaded = (adsManagerLoadedEvent) => {
    try {
      // Get the ads manager
      const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
      adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
      adsRenderingSettings.uiElements = [
        window.google.ima.UiElements.AD_ATTRIBUTION,
        window.google.ima.UiElements.COUNTDOWN
      ];

      // Create ads manager
      adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(
        videoRef.current,
        adsRenderingSettings
      );

      // Add event listeners
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.COMPLETE,
        onAdComplete
      );
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        onAdComplete
      );
      adsManagerRef.current.addEventListener(
        window.google.ima.AdErrorEvent.Type.AD_ERROR,
        onAdError
      );
      
      // Add additional event listeners for better error handling
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.STARTED,
        () => setAdError(null)
      );

      try {
        // Initialize the ads manager
        adsManagerRef.current.init(
          videoRef.current.clientWidth,
          videoRef.current.clientHeight,
          window.google.ima.ViewMode.NORMAL
        );
        
        // Start the ad muted initially
        adsManagerRef.current.setVolume(0);
        adsManagerRef.current.start();
      } catch (adError) {
        console.warn('Ads Manager initialization failed:', adError);
        onAdComplete();
      }
    } catch (error) {
      console.warn('Failed to setup ads manager:', error);
      onAdComplete();
    }
  };

  const onAdError = (adErrorEvent) => {
    console.warn('Ad Error:', adErrorEvent.getError());
    setAdError(adErrorEvent.getError());
    if (adsManagerRef.current) {
      adsManagerRef.current.destroy();
    }
    onAdComplete();
  };

  useEffect(() => {
    // Cleanup function
    return () => {
      if (adsManagerRef.current) {
        adsManagerRef.current.destroy();
      }
      if (adsLoaderRef.current) {
        adsLoaderRef.current.destroy();
      }
    };
  }, []);

  return (
    <>
      <div
        ref={adContainerRef}
        className="ad-container"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1000,
          background: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      {adError && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          background: 'rgba(0, 0, 0, 0.8)',
          padding: '10px',
          borderRadius: '4px',
          zIndex: 1001,
        }}>
          Ad playback error. Starting video...
        </div>
      )}
    </>
  );
};

export default VideoAdsManager;
