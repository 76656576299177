import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { WEBSITE_NAME, getEnabledHosters } from "../App";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Modal,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Dialog
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import XIcon from "@mui/icons-material/X";
import RedditIcon from "@mui/icons-material/Reddit";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import DownloadIcon from '@mui/icons-material/Download';
import LaunchIcon from '@mui/icons-material/Launch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorder';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import VideoPlayer from './VideoPlayer';
import ErrorPage from './ErrorPage'; // Add this import at the top
import { VIEWS_CONFIG, SITE_CONFIG } from '../App';
import adsConfig from '../config/adsConfig';
import { getLocale } from '../i18n/locales';
import { getVideos, getActors, getGalleries } from '../utils/api';
import { getThumbnailPath } from '../App';

function VideoPage() {
  const { t } = useTranslation();
  const [video, setVideo] = useState(null);
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const { slug } = useParams();
  const { lng } = useParams();
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [selectedHoster, setSelectedHoster] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isInWatchLater, setIsInWatchLater] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [relatedGalleries, setRelatedGalleries] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [showTimestamps, setShowTimestamps] = useState(false);
  const [allActors, setAllActors] = useState([]);
  const playerRef = useRef(null);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [hostingHelpOpen, setHostingHelpOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [actors, setActors] = useState([]);
  const [videoNotFound, setVideoNotFound] = useState(false); // Add this state
  const [showChapters, setShowChapters] = useState(false);
  const [adScriptInitialized, setAdScriptInitialized] = useState(false);
  const [playerAdInitialized, setPlayerAdInitialized] = useState(false);
  const [sidebarAdInitialized, setSidebarAdInitialized] = useState(false);
  const viewTracked = useRef(false); // Add this useRef
  const [likeCount, setLikeCount] = useState(0);

  const handleHostingHelpOpen = () => {
    setHostingHelpOpen(true);
  };

  const handleHostingHelpClose = () => {
    setHostingHelpOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [videoData, actorData, galleryData] = await Promise.all([
          getVideos(),
          getActors(),
          getGalleries()
        ]);

        // Find matching video by slug
        const foundVideo = videoData.find(
          (v) =>
            v.title.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-+|-+$/g, "") === slug
        );

        if (!foundVideo) {
          setVideoNotFound(true);
          return;
        }

        // Filter the hosters based on the configuration
        const enabledHosters = getEnabledHosters(foundVideo.hosters);
        
        // Set video with filtered hosters
        setVideo({
          ...foundVideo,
          hosters: enabledHosters
        });
        
        // Set default hoster if available
        if (enabledHosters.length > 0) {
          setSelectedHoster(enabledHosters[0].name);
        }

        if (foundVideo) {
          // Filter out current video first
          const otherVideos = videoData.filter((v) => v.id !== foundVideo.id);
          
          // Find videos with matching actors
          const videosWithSameActors = otherVideos.filter(v => 
            v.actors.some(actorId => foundVideo.actors.includes(actorId))
          );

          // If we have enough videos with same actors, use those
          if (videosWithSameActors.length >= 6) {
            const shuffledMatching = [...videosWithSameActors].sort(() => Math.random() - 0.5);
            setRecommendedVideos(shuffledMatching.slice(0, 6));
          } else {
            // Otherwise fill up remaining slots with random videos
            const shuffledMatching = [...videosWithSameActors];
            const remainingNeeded = 6 - videosWithSameActors.length;
            
            // Get random videos that don't share actors
            const nonMatchingVideos = otherVideos.filter(v => 
              !v.actors.some(actorId => foundVideo.actors.includes(actorId))
            );
            const shuffledNonMatching = [...nonMatchingVideos].sort(() => Math.random() - 0.5);
            
            setRecommendedVideos([
              ...shuffledMatching,
              ...shuffledNonMatching.slice(0, remainingNeeded)
            ]);
          }

          const videoActors = actorData.filter((actor) => foundVideo.actors.includes(actor.id));
          setActors(videoActors);
          
          const filteredGalleries = galleryData.filter((gallery) =>
            gallery.relatedVideos?.includes(foundVideo.id)
          );
          setRelatedGalleries(filteredGalleries);

          if (foundVideo.hosters?.length > 0) {
            setSelectedHoster(foundVideo.hosters[0].name);
          }
        }
        setAllActors(actorData);

      } catch (error) {
        console.error('Error loading video data:', error);
        setVideoNotFound(true);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    if (video) {
      const watchLaterList = JSON.parse(localStorage.getItem("watchLater") || "[]");
      setIsInWatchLater(watchLaterList.some((v) => v.id === video.id));
    }
  }, [video]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      setPlayerAdInitialized(false);
      setSidebarAdInitialized(false);
    };
  }, []);

  // Reset ad initialization states when video changes
  useEffect(() => {
    setPlayerAdInitialized(false);
    setSidebarAdInitialized(false);
  }, [slug]); // Add this effect

  useEffect(() => {
    if (video) {
      const favoriteVideos = JSON.parse(localStorage.getItem('favoriteVideos') || '[]');
      setIsFavorite(favoriteVideos.includes(video.id));
    }
  }, [video]);

  const incrementViewCount = useCallback(async () => {
    if (!video || viewTracked.current) return;
      
    // Set the tracking flag to true to prevent duplicate view counts
    viewTracked.current = true;
    
    if (SITE_CONFIG.useOwnSite) {
      // Use API for view storage when useOwnSite is true
      try {
        // No need to fetch current views first - just send a single view
        await fetch('http://192.168.178.54:8000/views', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            video_id: String(video.id),  // Ensure video_id is sent as a string
            views: 1  // Always send 1 - API will handle incrementing
          })
        });
        
        // Fetch the updated count to display after recording the view
        const response = await fetch('http://192.168.178.54:8000/views');
        
        if (response.ok) {
          const viewsData = await response.json();
          
          // Get the updated view count from the API response
          let updatedViews = 0;
          
          if (Array.isArray(viewsData)) {
            const existingEntry = viewsData.find(entry => entry.video_id === video.id);
            if (existingEntry) {
              updatedViews = existingEntry.views;
            }
          } else {
            updatedViews = viewsData[video.id] || 0;
          }
          
          // Update local state for UI
          setViewCount(updatedViews);
        }
      } catch (error) {
        console.warn('Could not update view count in API:', error);
        
        // Fallback to showing the local view count if we have it
        const views = JSON.parse(localStorage.getItem("videoViews") || "{}");
        setViewCount(views[video.id] || 0);
      }
    } else {
      // Use local storage for view storage (existing implementation)
      const views = JSON.parse(localStorage.getItem("videoViews") || "{}");
      const videoId = video.id;
      views[videoId] = (views[videoId] || 0) + 1;
      localStorage.setItem("videoViews", JSON.stringify(views));
      setViewCount(views[videoId]);
    }
  }, [video]);

  // Update the useEffect that loads the initial view count
  useEffect(() => {
    if (!video) return;
    
    const fetchInitialViewCount = async () => {
      if (SITE_CONFIG.useOwnSite) {
        // Try to load view count from API WITHOUT incrementing
        try {
          const response = await fetch('http://192.168.178.54:8000/views');
          
          if (response.ok) {
            const viewsData = await response.json();
            
            // Handle both array and object response formats
            let viewCount = 0;
            
            if (Array.isArray(viewsData)) {
              // Handle array format
              const existingEntry = viewsData.find(entry => entry.video_id === video.id);
              if (existingEntry) {
                viewCount = existingEntry.views;
              }
            } else {
              // Handle object format
              viewCount = viewsData[video.id] || 0;
            }
            
            setViewCount(viewCount);
          } else {
            // Fallback to local storage if API request fails
            const views = JSON.parse(localStorage.getItem("videoViews") || "{}");
            setViewCount(views[video.id] || 0);
          }
        } catch (error) {
          // Silent fail - just use local storage as fallback
          console.warn('Could not fetch view count from API:', error);
          const views = JSON.parse(localStorage.getItem("videoViews") || "{}");
          setViewCount(views[video.id] || 0);
        }
      } else {
        // Use local storage (existing implementation)
        const views = JSON.parse(localStorage.getItem("videoViews") || "{}");
        setViewCount(views[video.id] || 0);
      }
    };
    
    fetchInitialViewCount();
    
    // Reset the viewTracked flag when video changes
    viewTracked.current = false;
  }, [video]);

  useEffect(() => {
    setIsPlaying(false);
  }, [slug]);

  useEffect(() => {
    if (video) {
      document.title = video.title;
    }
  }, [video]);

  useEffect(() => {
    // Only initialize Disqus if we have video data and SITE_CONFIG.useOwnSite is false
    if (!video || SITE_CONFIG.useOwnSite) return;
  
    try {
      if (window.DISQUS) {
        window.DISQUS.reset({
          reload: true,
          config: function () {
            this.page.url = window.location.href;
            this.page.identifier = `video-${video.id}`;
            this.page.title = video.title;
            this.theme = "dark";
          }
        });
      } else {
        // Safe initialization of Disqus
        window.disqus_config = function () {
          this.page.url = window.location.href;
          this.page.identifier = `video-${video.id}`;
          this.page.title = video.title;
          this.theme = "dark";
        };
  
        const script = document.createElement('script');
        script.src = 'https://miakhalifahub.disqus.com/embed.js';
        script.setAttribute('data-timestamp', +new Date());
        script.async = true;
        document.body.appendChild(script);
      }
    } catch (err) {
      console.warn('Disqus failed to initialize:', err);
    }
  
    // Cleanup function
    return () => {
      try {
        // Only cleanup if DISQUS exists
        if (window.DISQUS) {
          window.DISQUS.reset();
        }
        
        const disqusThread = document.getElementById('disqus_thread');
        if (disqusThread) {
          disqusThread.innerHTML = '';
        }
      } catch (err) {
        console.warn('Disqus cleanup failed:', err);
      }
    };
  }, [video?.id, video?.title]); // Only re-run when video ID or title changes
  

  const handleVideoClick = (vid) => {
    const newSlug = vid.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
    navigate(`/${lng}/video/${newSlug}`);
  };

  const handleHosterChange = (event, newHoster) => {
    if (newHoster !== null) {
      setSelectedHoster(newHoster);
    }
  };

  const handlePlayClick = () => {
    if (!video) return;
    
    // Increment view count
    incrementViewCount();
    
    // Add to watch history
    const history = JSON.parse(localStorage.getItem('viewHistory') || '[]');
    
    // Create history entry
    const historyEntry = {
      video: {
        id: video.id,
        title: video.title,
        thumbnail: getThumbnailPath(video.thumbnail),
        actors: video.actors || [],
        duration: video.duration,
        uploadDate: video.uploadDate
      },
      timestamp: new Date().toISOString()
    };

    // Remove duplicate if exists
    const uniqueHistory = history.filter(item => item.video.id !== video.id);
    
    // Add new entry at start
    uniqueHistory.unshift(historyEntry);
    
    // Save back to localStorage
    localStorage.setItem('viewHistory', JSON.stringify(uniqueHistory));

    // Dispatch storage event for other tabs
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'viewHistory',
      newValue: JSON.stringify(uniqueHistory)
    }));
    
    // Set playing state
    setIsPlaying(true);
  };

  const handleShareClick = () => {
    setShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };

  const handleCopyLink = async () => {
    const url = window.location.href;
    
    try {
      // Try the modern clipboard API first
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(url);
        handleCloseShareModal();
        return;
      }
  
      // Fallback for iOS, Android and other mobile devices
      // Create temporary input element
      const textArea = document.createElement('textarea');
      textArea.value = url;
      
      // Make it invisible but keep it in the viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '0';
      textArea.style.top = '0';
      textArea.style.opacity = '0';
      textArea.style.pointerEvents = 'none';
      
      document.body.appendChild(textArea);
      
      // Handle iOS specifics
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        textArea.contentEditable = true;
        textArea.readOnly = true;
        
        const range = document.createRange();
        range.selectNodeContents(textArea);
        
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
      
      try {
        document.execCommand('copy');
        handleCloseShareModal();
      } catch (err) {
        console.error('Copy to clipboard failed:', err);
      }
      
      document.body.removeChild(textArea);
    } catch (err) {
      console.error('Copy to clipboard failed:', err);
    }
  };

  const handleShareX = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`
    );
    handleCloseShareModal();
  };

  const handleShareReddit = () => {
    window.open(
      `https://reddit.com/submit?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(
        video.title
      )}`
    );
    handleCloseShareModal();
  };

  const handleWatchLater = (vid) => {
    const watchLaterList = JSON.parse(localStorage.getItem("watchLater") || "[]");
    const isAlreadyInList = watchLaterList.some((v) => v.id === vid.id);
    if (!isAlreadyInList) {
      const videoToStore = {
        id: vid.id,
        title: vid.title,
        thumbnail: vid.thumbnail,
        actors: vid.actors || [],
        duration: vid.duration,
        uploadDate: vid.uploadDate
      };
      watchLaterList.push(videoToStore);
      setIsInWatchLater(true);
    } else {
      const index = watchLaterList.findIndex((v) => v.id === vid.id);
      watchLaterList.splice(index, 1);
      setIsInWatchLater(false);
    }
    localStorage.setItem("watchLater", JSON.stringify(watchLaterList));
  };

  const handleFavorite = () => {
    const favoriteVideos = JSON.parse(localStorage.getItem('favoriteVideos') || '[]');
    
    if (isFavorite) {
      // Remove from favorites
      const newFavorites = favoriteVideos.filter(id => id !== video.id);
      localStorage.setItem('favoriteVideos', JSON.stringify(newFavorites));
      setIsFavorite(false);
    } else {
      // Add to favorites
      favoriteVideos.push(video.id);
      localStorage.setItem('favoriteVideos', JSON.stringify(favoriteVideos));
      setIsFavorite(true);
    }
  };

  const formatDate = (dateString, showFull = false) => {
    if (!showFull) {
      const date = new Date(dateString);
      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);
      const diffInDays = Math.floor(diffInSeconds / 86400);
      const diffInMonths = Math.floor(diffInDays / 30);
      const diffInYears = Math.floor(diffInDays / 365);
      if (diffInYears > 0) {
        return diffInYears + " " + t(`time.${diffInYears === 1 ? "year" : "years"}`) + " " + t("time.ago");
      } else if (diffInMonths > 0) {
        return diffInMonths + " " + t(`time.${diffInMonths === 1 ? "month" : "months"}`) + " " + t("time.ago");
      } else {
        return diffInDays + " " + t(`time.${diffInDays === 1 ? "day" : "days"}`) + " " + t("time.ago");
      }
    }
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatter = new Intl.DateTimeFormat(lng === "de" ? "de-DE" : "en-US", options);
    const parts = formatter.formatToParts(date);
    return t("videoPage.uploadDate.fullDate", {
      month: parts.find((p) => p.type === "month").value,
      day: parts.find((p) => p.type === "day").value,
      year: parts.find((p) => p.type === "year").value
    });
  };

  // Add this helper function near the top of VideoPage component
const generateActorSlug = (actor) => {
  return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
};

// Update the handleActorClick function
const handleActorClick = (actorId) => {
  const actor = allActors.find(a => a.id === actorId);
  const slug = generateActorSlug(actor);
  navigate(`/${lng}/actor/${slug}`);
};

  const handleCategoryClick = (category) => {
    const catSlug = category.toLowerCase().replace(/\s+/g, "-");
    navigate(`/${lng}/category/${catSlug}`);
  };


// Update handleTimestampClick function
const handleTimestampClick = (timeString) => {
  // Convert timestamp to seconds
  const parts = timeString.split(':');
  let seconds;
  
  if (parts.length === 3) {
    // Format: h:mm:ss
    seconds = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
  } else {
    // Format: mm:ss
    seconds = parseInt(parts[0]) * 60 + parseInt(parts[1]);
  }

  // Try to seek using playerRef
  try {
    if (playerRef.current) {
      const player = playerRef.current.plyr || playerRef.current;
      if (player) {
        player.currentTime = seconds;
      }
    }
  } catch (err) {
    console.error('Error seeking video:', err);
  }
};

  const handleGalleryClick = (gallery) => { // Change parameter from galleryId to gallery object
    const slug = gallery.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    navigate(`/${lng}/gallery/${slug}`);
  };

  const handleDownloadClick = () => {
    setDownloadModalOpen(true);
  };

  const handleCloseDownloadModal = () => {
    setDownloadModalOpen(false);
  };

  const handleDownloadSelect = (url) => {
    window.open(url, '_blank');
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  // Neuer useEffect für Player Ad Initialisierung
useEffect(() => {
  if (!video || !adsConfig.isAdsEnabled() || playerAdInitialized) return;

  // Cleanup old ad scripts first
  document.querySelectorAll('script[data-ad-script="player-provider"]').forEach(script => script.remove());
  document.querySelectorAll('script[data-ad-script="player-initializer"]').forEach(script => script.remove());

  // Create ad container if it doesn't exist
  let adContainer = document.getElementById('player-ad-container');
  if (!adContainer) {
    adContainer = document.createElement('div');
    adContainer.id = 'player-ad-container';
    document.querySelector('.player-ad-wrapper')?.appendChild(adContainer);
  }

  // Clear container
  adContainer.innerHTML = '';

  // Add provider script
  const providerScript = document.createElement('script');
  providerScript.src = 'https://a.magsrv.com/ad-provider.js';
  providerScript.async = true;
  providerScript.setAttribute('data-ad-script', 'player-provider');
  adContainer.appendChild(providerScript);

  // Add ad tag
  const ins = document.createElement('ins');
  ins.className = window.innerWidth < 1140 ? 'eas6a97888e10' : 'eas6a97888e2';
  ins.dataset.zoneid = window.innerWidth < 1140 ? '5514844' : '5514842';
  adContainer.appendChild(ins);

  // Add initializer script
  const initScript = document.createElement('script');
  initScript.setAttribute('data-ad-script', 'player-initializer');
  initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
  adContainer.appendChild(initScript);

  setPlayerAdInitialized(true);
}, [video, playerAdInitialized]); // Abhängigkeiten angepasst

// Move this useEffect before other ad-related effects
useEffect(() => {
  // Only proceed if we have a video and ads are enabled
  if (!video || !adsConfig.isAdsEnabled()) return;
  
  // Small delay to ensure DOM is ready
  const timer = setTimeout(() => {
    // Cleanup old ad scripts first
    document.querySelectorAll('script[data-ad-script="player-provider"]').forEach(script => script.remove());
    document.querySelectorAll('script[data-ad-script="player-initializer"]').forEach(script => script.remove());

    // Create ad container if it doesn't exist
    let adContainer = document.getElementById('player-ad-container');
    if (!adContainer) {
      adContainer = document.createElement('div');
      adContainer.id = 'player-ad-container';
      const wrapper = document.querySelector('.player-ad-wrapper');
      if (wrapper) {
        wrapper.appendChild(adContainer);
      }
    }

    // Clear container
    adContainer.innerHTML = '';

    // Add provider script
    const providerScript = document.createElement('script');
    providerScript.src = 'https://a.magsrv.com/ad-provider.js';
    providerScript.async = true;
    providerScript.setAttribute('data-ad-script', 'player-provider');
    adContainer.appendChild(providerScript);

    // Add ad tag
    const ins = document.createElement('ins');
    ins.className = window.innerWidth < 1140 ? 'eas6a97888e10' : 'eas6a97888e2';
    ins.dataset.zoneid = window.innerWidth < 1140 ? '5514844' : '5514842';
    adContainer.appendChild(ins);

    // Add initializer script
    const initScript = document.createElement('script');
    initScript.setAttribute('data-ad-script', 'player-initializer');
    initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
    adContainer.appendChild(initScript);

    setPlayerAdInitialized(true);
  }, 100); // Small delay

  return () => clearTimeout(timer);
}, [video]); // Only depend on video changes

  // Add this function with the other API-related functions
  const incrementLikeCount = useCallback(async () => {
    if (!video || !SITE_CONFIG.useOwnSite) return;
    
    try {
      // Send like to API
      await fetch('http://192.168.178.54:8000/likes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          video_id: String(video.id)
        })
      });
      
      // Increment the local like count
      setLikeCount(prevCount => prevCount + 1);
      
    } catch (error) {
      console.warn('Could not update like count in API:', error);
    }
  }, [video]);

  // Add this effect to fetch the initial like count
  useEffect(() => {
    if (!video || !SITE_CONFIG.useOwnSite) return;
    
    const fetchInitialLikeCount = async () => {
      try {
        const response = await fetch('http://192.168.178.54:8000/likes');
        
        if (response.ok) {
          const likesData = await response.json();
          
          // Handle both array and object response formats
          let likes = 0;
          
          if (Array.isArray(likesData)) {
            // Handle array format
            const existingEntry = likesData.find(entry => entry.video_id === video.id);
            if (existingEntry) {
              likes = existingEntry.likes;
            }
          } else {
            // Handle object format
            likes = likesData[video.id] || 0;
          }
          
          setLikeCount(likes);
        }
      } catch (error) {
        console.warn('Could not fetch like count from API:', error);
      }
    };
    
    fetchInitialLikeCount();
  }, [video]);

  // Return 404 page if video not found
  if (videoNotFound) {
    return <ErrorPage code={404} />;
  }

  // Return loading state while fetching
  if (!video) {
    return <Typography>{t("videoPage.loading")}</Typography>;
  }

  const truncateDescription = (desc, maxLength = 150) => {
    if (!desc) return '';
    return desc.length > maxLength ? desc.substring(0, maxLength) + '...' : desc;
  };

  // First, add this helper at the top with the other helpers
const getMetaDescription = (video, actors, t) => {
  const translatedPart = t('meta.video.description', {
    title: video.title,
    actors: actors.map(actor => actor?.name).join(', ')
  });
  const videoDesc = video.description ? ` - ${video.description}` : '';
  // Truncate combined text to 150 characters
  return (translatedPart + videoDesc).slice(0, 150) + ((translatedPart + videoDesc).length > 150 ? '...' : '');
};

// Füge diese Hilfsfunktion hinzu
const convertDurationToISO8601 = (duration) => {
  if (!duration) return '';
  
  const parts = duration.split(':');
  if (parts.length === 3) {
    // Format: HH:MM:SS
    return `PT${parts[0]}H${parts[1]}M${parts[2]}S`;
  } else if (parts.length === 2) {
    // Format: MM:SS 
    return `PT${parts[0]}M${parts[1]}S`;
  }
  return '';
};

  // Check if mktv is available for this video
  const hasMktvHoster = video?.hosters?.some(h => h.name === 'mktv');

  return (
    <>
      <Helmet>
        {hasMktvHoster && (
          <meta 
            http-equiv="Delegate-CH" 
            content="Sec-CH-UA https://s.magsrv.com; Sec-CH-UA-Mobile https://s.magsrv.com; Sec-CH-UA-Arch https://s.magsrv.com; Sec-CH-UA-Model https://s.magsrv.com; Sec-CH-UA-Platform https://s.magsrv.com; Sec-CH-UA-Platform-Version https://s.magsrv.com; Sec-CH-UA-Bitness https://s.magsrv.com; Sec-CH-UA-Full-Version-List https://s.magsrv.com; Sec-CH-UA-Full-Version https://s.magsrv.com;"
          />
        )}
        <title>{`${video.title} - ${t('meta.video.titleSuffix')}`}</title>
        <meta name="description" content={getMetaDescription(video, 
          video.actors.map(actorId => allActors.find(a => a.id === actorId)), 
          t
        )} />
        <meta name="author" content={t('meta.video.author')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${video.title} - ${t('meta.video.titleSuffix')}`} />
        <meta property="og:description" content={getMetaDescription(video,
          video.actors.map(actorId => allActors.find(a => a.id === actorId)),
          t
        )} />
        <meta property="og:type" content="video.other" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}${video.thumbnail.replace('.jpg', '_preview.jpg')}`} />
        <meta property="og:video" content={video.previewVideo} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={`${video.title} - ${t('meta.video.titleSuffix')}`} />
        <meta name="twitter:description" content={getMetaDescription(video,
          video.actors.map(actorId => allActors.find(a => a.id === actorId)),
          t
        )} />
        <meta name="twitter:image" content={`${window.location.origin}${video.thumbnail.replace('.jpg', '_preview.jpg')}`} />

        {/* Schema.org VideoObject markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject", 
            "name": video.title,
            "description": video.description || video.title,
            "thumbnailUrl": `${window.location.origin}${video.thumbnail.replace('.jpg', '_preview.jpg')}`,
            "uploadDate": video.uploadDate ? new Date(video.uploadDate).toISOString() : new Date().toISOString(),
            "duration": convertDurationToISO8601(video.duration),
            "contentUrl": video.previewVideo,
            "author": {
              "@type": "Person",
              "name": t('meta.video.author')
            }
          })}
        </script>
      </Helmet>
      <Container 
        maxWidth={false} 
        sx={{ 
          px: { 
            xs: 0, // Remove horizontal padding on mobile
            sm: 0, // Remove horizontal padding on tablet
            md: 0,  // Remove horizontal padding on desktop
          }
        }}
      >
        <Grid container spacing={0}>
          {/* Left column - Main content */}
          <Grid item xs={12} md={8.5}>  {/* Change from md={9} to md={8} */}
            <Box sx={{ 
              pr: { md: 2 }, // Change lg to md
              pl: { md: 2 }  // Change lg to md
            }}>
              <Box>
                <Box sx={{ 
                  mb: 0, // Change from mb: 2 to mb: 0
                  // Only display the hoster bar if there's more than one hoster
                  display: video.hosters.length > 1 ? 'flex' : 'none',
                  alignItems: 'center',
                  bgcolor: 'rgba(0, 0, 0, 0.2)', 
                  borderRadius: '4px 4px 0 0',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  p: 1.5,
                  backdropFilter: 'blur(8px)',
                  position: 'relative',
                  zIndex: 1,
                  // Für mobile Ansicht
                  overflowX: 'auto',
                  msOverflowStyle: 'none', // Hide scrollbar IE/Edge
                  scrollbarWidth: 'none',  // Hide scrollbar Firefox
                  '&::-webkit-scrollbar': {
                    display: 'none'  // Hide scrollbar Chrome/Safari/Opera
                  }
                }}>
                  <ToggleButtonGroup
                    value={selectedHoster}
                    exclusive
                    onChange={handleHosterChange}
                    aria-label="video hoster selection"
                    size="small"
                    sx={{
                      gap: 1,
                      '& .MuiToggleButton-root': {
                        height: "36px", // Match other buttons height
                        backgroundColor: "transparent",
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderRadius: "8px !important", // Force rounded corners
                        color: "white",
                        textTransform: "none",
                        transition: "all 0.2s ease",
                        px: 2,
                        whiteSpace: 'nowrap',

                        // Selected state styling
                        '&.Mui-selected': {
                          backgroundColor: "primary.main",
                          borderColor: "primary.main",
                          color: "black",
                          boxShadow: "0 0 20px rgba(216, 158, 0, 0.3)",
                          '&:hover': {
                            backgroundColor: "primary.dark",
                            borderColor: "primary.main",
                          }
                        },

                        // Hover state
                        '&:hover': {
                          backgroundColor: "rgba(216, 158, 0, 0.1)",
                          borderColor: "primary.main",
                          transform: "translateY(-1px)",
                          boxShadow: "0 5px 15px rgba(0,0,0,0.3)"
                        },

                        // Icon styling
                        '& .MuiBox-root': {
                          display: 'flex',
                          alignItems: 'center'
                        }
                      }
                    }}
                  >
                    {video.hosters.map((hoster) => (
                      <ToggleButton 
                        key={hoster.name} 
                        value={hoster.name} 
                        aria-label={hoster.name}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        {/* Icon based on hoster name */}
                        {(() => {
                          switch(hoster.name.toLowerCase()) {
                            case 'streamtape':
                              return <Box component="img" src="/images/streamtape_icon.png" alt="" sx={{ width: 16, height: 16 }} />;
                            case 'vidoza':
                              return <Box component="img" src="/images/vidoza_icon.png" alt="" sx={{ width: 16, height: 16 }} />;
                            case 'voe.sx':
                              return <Box component="img" src="/images/voe_icon.png" alt="" sx={{ width: 16, height: 16 }} />;
                            case 'direct':
                            case 'mktv': // Add this line to use the same icon for "mktv"
                              return <Box component="img" src="/images/direct_icon.svg" alt="" sx={{ width: 16, height: 16 }} />;
                            default:
                              return null;
                          }
                        })()}
                        {hoster.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  
                  {/* Help icon button */}
                  <IconButton
                    onClick={handleHostingHelpOpen}
                    sx={{
                      ml: 1,
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'primary.main'
                      }
                    }}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </Box>
                <Box sx={{ 
                  position: "relative", 
                  width: "100%", 
                  paddingTop: "56.25%",
                  overflow: 'hidden', // Ensure video doesn't overflow rounded corners
                  border: '1px solid rgba(255, 255, 255, 0.1)', // Matching border
                  // If there's only one hoster, we need top border radius as well
                  borderTop: video.hosters.length > 1 ? 'none' : undefined,
                  borderRadius: video.hosters.length > 1 ? '0 0 4px 4px' : '4px'
                }}>
                  {!isPlaying && (
                    <Box
                      onClick={handlePlayClick}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        cursor: "pointer", 
                        backgroundImage: `url(${getThumbnailPath(video.thumbnail)})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover .playButton": { // Modified to affect child with playButton class directly
                          transform: "translate(-50%, -50%) scale(1.1)", // Add scale while maintaining centering
                          backgroundColor: "rgba(0, 0, 0, 0.9)"
                        }
                      }}
                    >
                      <IconButton
                        className="playButton" // Add className to target
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)", // Base transform
                          width: { xs: 60, md: 80 },
                          height: { xs: 60, md: 80 },
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          border: "4px solid white",
                          transition: "all 0.2s ease", // Add smooth transition
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.9)"
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: { xs: 40, md: 60 },
                            color: "#d89e00"
                          }
                        }}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                    </Box>
                  )}
                  {isPlaying && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                      }}
                    >
                      <VideoPlayer 
                        video={video}
                        selectedHoster={selectedHoster}
                        playerRef={playerRef}
                      />
                    </Box>
                  )}
                </Box>
                {/* Player ad container */}
                {adsConfig.isAdsEnabled() && (
                  <Box
                    className="player-ad-wrapper"
                    sx={{
                      width: '100%',
                      height: { xs: '60px', md: '110px' },
                      mt: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '0 0 4px 4px',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderTop: 'none'
                    }}
                  >
                    <div id="player-ad-container"/>
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <Typography 
                      variant="h4" 
                      gutterBottom
                      sx={{
                        fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
                        fontWeight: 500,
                        mb: 2 // Add margin bottom for spacing
                      }}
                    >
                      {video.title}
                    </Typography>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
                      {/* Actor names row */}
                      <Box sx={{ 
                        display: "flex", 
                        flexWrap: "wrap", 
                        gap: 0.5,
                      }}>
                      {video.actors.map((actorId, index) => {
                        const actor = allActors.find((a) => a.id === actorId);
                        if (!actor) return null;
                        
                        // Calculate age at upload if birthDate exists
                        let ageAtUpload = null;
                        if (actor.birthDate && video.uploadDate) {
                          const birthDate = new Date(actor.birthDate);
                          const uploadDate = new Date(video.uploadDate);
                          let age = uploadDate.getFullYear() - birthDate.getFullYear();
                          const monthDiff = uploadDate.getMonth() - birthDate.getMonth();
                          if (monthDiff < 0 || (monthDiff === 0 && uploadDate.getDate() < birthDate.getDate())) {
                            age--;
                          }
                          ageAtUpload = age;
                        }
                    
                        return (
                          <React.Fragment key={actorId}>
                            <Box sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}>
                              <Box
                                component="a"
                                href={`/${lng}/actor/${generateActorSlug(actor)}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleActorClick(actorId);
                                }}
                                sx={{
                                  color: 'primary.main',
                                  textDecoration: 'none',
                                  cursor: 'pointer',
                                  '&:hover': {
                                    textDecoration: 'underline',
                                    color: 'primary.light'
                                  }
                                }}
                              >
                                {actor?.name}
                              </Box>
                              {ageAtUpload && (
                                <Tooltip title={t("videoPage.ageAtUpload", { age: ageAtUpload })} arrow placement="top">
                                  <Typography component="span" sx={{ color: "text.secondary", fontSize: "0.75rem", ml: 0.5, cursor: "help" }}>
                                    ({ageAtUpload})
                                  </Typography>
                                </Tooltip>
                              )}
                            </Box>
                            {index < video.actors.length - 1 && (
                              <Typography component="span" sx={{ color: "primary.main", fontSize: { xs: "1rem", md: "1.125rem" } }}>
                                {", "}
                              </Typography>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  
                    {/* Buttons row */}
                    <Box sx={{ 
                      display: "flex", 
                      flexWrap: "wrap",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "flex-end" // Align buttons to the right
                    }}>
                      {/* Watch Later Button */}
                      <Button
                        variant={isInWatchLater ? "contained" : "outlined"}
                        startIcon={<WatchLaterIcon />}
                        onClick={() => handleWatchLater(video)}
                        sx={{
                          height: "36px",
                          backgroundColor: isInWatchLater ? "primary.main" : "transparent",
                          borderColor: isInWatchLater ? "primary.main" : "rgba(255, 255, 255, 0.2)",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: "8px",
                          color: isInWatchLater ? "black" : "white",
                          textTransform: "none",
                          boxShadow: isInWatchLater ? "0 0 20px rgba(216, 158, 0, 0.3)" : "none",
                          transition: "all 0.2s ease",
                          px: 2,
                          "& .MuiButton-startIcon": {
                            margin: { xs: 0, sm: "0 8px 0 -4px" }
                          },
                          "&:hover": {
                            backgroundColor: isInWatchLater ? "primary.dark" : "rgba(216, 158, 0, 0.1)",
                            borderColor: "primary.main",
                            transform: "translateY(-1px)",
                            boxShadow: "0 5px 15px rgba(0,0,0,0.3)"
                          }
                        }}
                      >
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                          {isInWatchLater ? t("videoPage.addedToWatchLater") : t("videoPage.watchLater")}
                        </Box>
                      </Button>
                  
                      {/* Share Button - Apply same styling */}
                      <Button
                        variant="outlined"
                        startIcon={<ShareIcon />}
                        onClick={handleShareClick}
                        sx={{
                          height: "36px",
                          backgroundColor: "transparent",
                          borderColor: "rgba(255, 255, 255, 0.2)",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: "8px",
                          color: "white",
                          textTransform: "none",
                          transition: "all 0.2s ease",
                          px: 2,
                          "& .MuiButton-startIcon": {
                            margin: { xs: 0, sm: "0 8px 0 -4px" }
                          },
                          "&:hover": {
                            backgroundColor: "rgba(216, 158, 0, 0.1)",
                            borderColor: "primary.main",
                            transform: "translateY(-1px)",
                            boxShadow: "0 5px 15px rgba(0,0,0,0.3)"
                          }
                        }}
                      >
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                          {t("videoPage.share.button")}
                        </Box>
                      </Button>
                  
                      {/* Download Button - Apply same styling */}
                      {video.download && (
                        <Button
                          variant="outlined"
                          startIcon={<DownloadIcon />}
                          onClick={handleDownloadClick}
                          sx={{
                            height: "36px",
                            backgroundColor: "transparent",
                            borderColor: "rgba(255, 255, 255, 0.2)",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "8px",
                            color: "white",
                            textTransform: "none",
                            transition: "all 0.2s ease",
                            px: 2,
                            "& .MuiButton-startIcon": {
                              margin: { xs: 0, sm: "0 8px 0 -4px" }
                            },
                            "&:hover": {
                              backgroundColor: "rgba(216, 158, 0, 0.1)", 
                              borderColor: "primary.main",
                              transform: "translateY(-1px)",
                              boxShadow: "0 5px 15px rgba(0,0,0,0.3)"
                            }
                          }}
                        >
                          <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            {t("videoPage.download.button")}
                          </Box>
                        </Button>
                      )}
                  
                      {/* Favorite Button - Apply same styling */}
                      <Button
                        variant="outlined"
                        startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteOutlinedIcon />}
                        onClick={handleFavorite}
                        sx={{
                          height: "36px",
                          backgroundColor: "transparent",
                          borderColor: "rgba(255, 255, 255, 0.2)",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderRadius: "8px",
                          color: isFavorite ? "primary.main" : "white",
                          textTransform: "none",
                          transition: "all 0.2s ease",
                          px: 2,
                          "& .MuiButton-startIcon": {
                            margin: { xs: 0, sm: "0 8px 0 -4px" }
                          },
                          "&:hover": {
                            backgroundColor: "rgba(216, 158, 0, 0.1)",
                            borderColor: "primary.main",
                            transform: "translateY(-1px)",
                            boxShadow: "0 5px 15px rgba(0,0,0,0.3)"
                          }
                        }}
                      >
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                          {isFavorite ? t("favorites.remove") : t("favorites.add")}
                        </Box>
                      </Button>
                      {SITE_CONFIG.useOwnSite && (
                        <Button
                          variant="outlined"
                          startIcon={<ThumbUpIcon />}
                          onClick={incrementLikeCount}
                          sx={{
                            height: "36px",
                            backgroundColor: "transparent",
                            borderColor: "rgba(255, 255, 255, 0.2)",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "8px",
                            color: "white",
                            textTransform: "none",
                            transition: "all 0.2s ease",
                            px: 2,
                            "& .MuiButton-startIcon": {
                              margin: { xs: 0, sm: "0 8px 0 -4px" }
                            },
                            "&:hover": {
                              backgroundColor: "rgba(216, 158, 0, 0.1)",
                              borderColor: "primary.main",
                              transform: "translateY(-1px)",
                              boxShadow: "0 5px 15px rgba(0,0,0,0.3)"
                            }
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Box sx={{ display: { xs: "none", sm: "block" } }}>
                              {t("videoPage.like")}
                            </Box>
                            {likeCount > 0 && (
                              <Typography
                                component="span"
                                sx={{ 
                                  ml: 0.5,
                                  bgcolor: 'rgba(0, 0, 0, 0.2)',
                                  px: 0.8,
                                  py: 0.2,
                                  borderRadius: 1,
                                  fontSize: '0.75rem',
                                  fontWeight: 'bold',
                                  display: { xs: 'none', sm: 'inline' }
                                }}
                              >
                                {likeCount}
                              </Typography>
                            )}
                          </Box>
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ mt: 2 }}>
                    {/* Only show timestamps if video.timestamps exists AND has entries */}
                    {video.timestamps && Object.keys(video.timestamps).length > 0 && (
                      <Box
                        onClick={() => setShowTimestamps(!showTimestamps)}
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                          "&:hover": {
                            color: "primary.main"
                          }
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5
                          }}
                        >
                          {t("videoPage.timestamps")}
                          <ExpandMoreIcon
                            sx={{
                              transform: showTimestamps ? "rotate(180deg)" : "rotate(0)",
                              transition: "transform 0.2s"
                            }}
                          />
                        </Typography>
                      </Box>
                    )}
                    {showTimestamps && video.timestamps && (
                      <Box sx={{ mb: 2 }}>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                          {Object.entries(video.timestamps).map(([category, times]) => (
                            <Box key={category} sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="body2"
                                sx={{ color: "text.secondary", mr: 1, minWidth: "fit-content" }}
                              >
                                {category}:
                              </Typography>
                              <Box sx={{ display: "flex", gap: 0.5 }}>
                                {times.map((time, index) => (
                                  <Button
                                    key={category + "-" + index}
                                    title={category + ": " + time}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleTimestampClick(time)}
                                    sx={{
                                      minWidth: "32px",
                                      width: "32px",
                                      height: "24px",
                                      p: 0,
                                      borderColor: "rgba(255, 255, 255, 0.2)",
                                      color: "text.primary",
                                      "&:hover": {
                                        borderColor: "primary.main",
                                        backgroundColor: "rgba(216, 158, 0, 0.1)"
                                      }
                                    }}
                                  >
                                    {index + 1}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                    {/* Chapters section */}
                    {video.chapters && Object.keys(video.chapters).length > 0 && (
                      <>
                        <Box
                          onClick={() => setShowChapters(!showChapters)}
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center", 
                            mb: 1,
                            "&:hover": {
                              color: "primary.main"
                            }
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5
                            }}
                          >
                            {t("videoPage.chapters")}
                            <ExpandMoreIcon
                              sx={{
                                transform: showChapters ? "rotate(180deg)" : "rotate(0)",
                                transition: "transform 0.2s"
                              }}
                            />
                          </Typography>
                        </Box>

                        {showChapters && (
                          <Box 
                            sx={{ 
                              mb: 3, 
                              mt: 1.5,
                              bgcolor: 'rgba(0, 0, 0, 0.2)',
                              borderRadius: 2,
                              border: '1px solid rgba(255, 255, 255, 0.1)',
                              overflow: 'hidden',
                            }}
                          >
                            {/* Chapter timeline visualization */}
                            <Box 
                              sx={{
                                position: 'relative',
                                height: 60,
                                width: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.2)',
                                borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                                px: 3,
                              }}
                            >
                              {/* Timeline base */}
                              <Box 
                                sx={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: 20,
                                  right: 20,
                                  height: 4,
                                  bgcolor: 'rgba(255,255,255,0.1)',
                                  transform: 'translateY(-50%)',
                                  borderRadius: 2
                                }}
                              />

                              {/* Chapter markers */}
                              {Object.entries(video.chapters).map(([chapter, times], index) => {
                                // Convert time to seconds for position calculation
                                const time = times[0];
                                const parts = time.split(':');
                                let totalSeconds;
                                
                                if (parts.length === 3) {
                                  // Format: h:mm:ss
                                  totalSeconds = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
                                } else {
                                  // Format: mm:ss
                                  totalSeconds = parseInt(parts[0]) * 60 + parseInt(parts[1]);
                                }
                                
                                // Get video duration in seconds for calculating position
                                const durationParts = video.duration.split(':');
                                const totalDuration = durationParts.length === 3 
                                  ? parseInt(durationParts[0]) * 3600 + parseInt(durationParts[1]) * 60 + parseInt(durationParts[2])
                                  : parseInt(durationParts[0]) * 60 + parseInt(durationParts[1]);
                                
                                // Calculate position percentage
                                const position = (totalSeconds / totalDuration) * 100;

                                return (
                                  <Tooltip 
                                    key={chapter}
                                    title={`${t('videoPage.chapter')} ${chapter}: ${times[0]}`}
                                    placement="top"
                                    arrow
                                  >
                                    <Button
                                      onClick={() => handleTimestampClick(times[0])}
                                      sx={{
                                        position: 'absolute',
                                        left: `calc(${position}% * 0.9 + 5%)`, // Scale to leave margins
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        minWidth: 30,
                                        width: 30,
                                        height: 30,
                                        p: 0,
                                        borderRadius: '50%',
                                        bgcolor: 'primary.main',
                                        color: 'black',
                                        fontWeight: 'bold',
                                        boxShadow: '0 0 10px rgba(216, 158, 0, 0.4)',
                                        transition: 'all 0.2s ease',
                                        zIndex: 2,
                                        '&:hover': {
                                          bgcolor: 'primary.light',
                                          transform: 'translate(-50%, -50%) scale(1.15)',
                                          boxShadow: '0 0 15px rgba(216, 158, 0, 0.6)',
                                        }
                                      }}
                                    >
                                      {chapter}
                                    </Button>
                                  </Tooltip>
                                );
                              })}
                            </Box>
                            
                            {/* Chapter list */}
                            <Box sx={{ p: 2 }}>
                              <Grid container spacing={2}>
                                {Object.entries(video.chapters).map(([chapter, times]) => (
                                  <Grid item xs={6} sm={4} md={3} key={chapter}>
                                    <Button
                                      onClick={() => handleTimestampClick(times[0])}
                                      variant="outlined"
                                      startIcon={
                                        <Box 
                                          sx={{ 
                                            width: 24, 
                                            height: 24, 
                                            borderRadius: '50%', 
                                            bgcolor: 'primary.main',
                                            color: 'black',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontWeight: 'bold',
                                            fontSize: '0.8rem'
                                          }}
                                        >
                                          {chapter}
                                        </Box>
                                      }
                                      sx={{
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        textTransform: 'none',
                                        py: 1,
                                        border: '1px solid rgba(255, 255, 255, 0.1)',
                                        borderRadius: 1.5,
                                        backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                          backgroundColor: 'rgba(216, 158, 0, 0.1)',
                                          borderColor: 'primary.main',
                                          transform: 'translateY(-2px)',
                                        }
                                      }}
                                    >
                                      <Box sx={{ 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        alignItems: 'flex-start',
                                        overflow: 'hidden',
                                        ml: 1
                                      }}>
                                        <Typography 
                                          variant="body2" 
                                          noWrap
                                          sx={{ 
                                            fontWeight: 500,
                                            color: 'white'
                                          }}
                                        >
                                          {t('videoPage.chapterName', { number: chapter })}
                                        </Typography>
                                        <Typography 
                                          variant="caption" 
                                          sx={{ 
                                            color: 'text.secondary'
                                          }}
                                        >
                                          {times[0]}
                                        </Typography>
                                      </Box>
                                    </Button>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                    <Box sx={{ display: "flex", alignItems: "center", color: "text.secondary", mb: 1 }}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        {VIEWS_CONFIG.enabled && (
                          <>
                            <VisibilityIcon sx={{ fontSize: "0.9em" }} />
                            <Typography variant="body2">
                              {viewCount.toLocaleString()} {t("videoPage.views")}
                            </Typography>
                            <Typography variant="body2" sx={{ mx: 1 }}>
                              •
                            </Typography>
                          </>
                        )}
                        <Typography variant="body2">{formatDate(video.uploadDate, showFullDescription)}</Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: "pre-line",
                        display: "-webkit-box",
                        WebkitLineClamp: showFullDescription ? "unset" : 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        mb: 1,
                        fontStyle: (!video.description || video.description.trim() === '') ? 'italic' : 'normal',
                        color: (!video.description || video.description.trim() === '') ? 'text.secondary' : 'inherit'
                      }}
                    >
                      {(!video.description || video.description.trim() === '') 
                        ? t('videoPage.noDescription')
                        : video.description
                      }
                    </Typography>
                    <Typography
                      variant="button"
                      component="button"
                      onClick={() => setShowFullDescription(!showFullDescription)}
                      sx={{
                        background: "none",
                        border: "none",
                        color: "primary.main",
                        cursor: "pointer",
                        p: 0,
                        "&:hover": {
                          color: "primary.light"
                        }
                      }}
                    >
                      {showFullDescription ? t("videoPage.showLess") : t("videoPage.showMore")}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2, mb: 2 }}>
                    {(showAllCategories ? video.categories : video.categories.slice(0, 10)).map((category, index) => (
                      <Typography
                        key={index}
                        component="a"
                        href={`/${lng}/category/${category.toLowerCase().replace(/\s+/g, "-")}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleCategoryClick(category);
                        }}
                        sx={{
                          bgcolor: "rgba(255, 255, 255, 0.1)",
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          mr: 1,
                          mb: 1,
                          display: "inline-block",
                          fontSize: "0.875rem",
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "inherit",
                          transition: "all 0.2s",
                          "&:hover": {
                            bgcolor: "rgba(216, 158, 0, 0.2)",
                            color: "primary.main"
                          }
                        }}
                      >
                        {category}
                      </Typography>
                    ))}
                    {video.categories.length > 10 && (
                      <Button
                        onClick={() => setShowAllCategories(!showAllCategories)}
                        sx={{
                          ml: 1,
                          color: "primary.main",
                          "&:hover": {
                            bgcolor: "rgba(216, 158, 0, 0.1)"
                          }
                        }}
                      >
                        {showAllCategories
                          ? t("videoPage.categories.showLess")
                          : t("videoPage.categories.showMore", { count: video.categories.length - 10 })}
                      </Button>
                    )}
                  </Box>
                  {/* Recommended Videos Section - Show first on mobile */}
                  <Box sx={{ 
                    display: { xs: 'block', md: 'none' }, // Only show on mobile
                    mb: 4 
                  }}>
                    {/* Add mobile ad container */}
                    {adsConfig.isAdsEnabled() && (
                      <Box
                        className="mobile-ad-container" 
                        sx={{
                          width: '300px',
                          height: '250px',
                          mx: 'auto', // Center horizontally
                          mb: 3,
                          bgcolor: 'rgba(0, 0, 0, 0.2)',
                          borderRadius: 1,
                          border: '1px solid rgba(255, 255, 255, 0.1)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box ref={(el) => {
                          if (!el || adScriptInitialized) return;
                          
                          // Cleanup old ad scripts first
                          document.querySelectorAll('script[data-ad-script="mobile-sidebar-provider"]').forEach(script => script.remove());
                          document.querySelectorAll('script[data-ad-script="mobile-sidebar-initializer"]').forEach(script => script.remove());
                          document.querySelectorAll('ins.eas6a97888e4').forEach(ins => ins.remove());
                          
                          // Clear container
                          el.innerHTML = '';
                          
                          // Use the same configuration as desktop sidebar ads
                          const providerScript = document.createElement('script');
                          providerScript.src = 'https://a.magsrv.com/ad-provider.js';
                          providerScript.async = true;
                          providerScript.setAttribute('data-ad-script', 'mobile-sidebar-provider');
                          el.appendChild(providerScript);
                        
                          // Use the same class and zone ID as desktop sidebar
                          const ins = document.createElement('ins');
                          ins.className = 'eas6a97888e2'; // Same class as desktop
                          ins.dataset.zoneid = '5514838'; // Same zone ID as desktop
                          el.appendChild(ins);
                        
                          // Create and append the initializer script
                          const initScript = document.createElement('script');
                          initScript.setAttribute('data-ad-script', 'mobile-sidebar-initializer');
                          initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
                          el.appendChild(initScript);
                          
                          setAdScriptInitialized(true);
                        }} />
                      </Box>
                    )}

                    <Typography variant="h6" gutterBottom>
                      {t("videoPage.recommendedVideos")}
                    </Typography>
                    <Box sx={{ 
                      display: "flex", 
                      flexDirection: "column", 
                      gap: 2
                    }}>
                      {recommendedVideos.map((v) => (
                        <Card
                          key={v.id}
                          component="a" // Set Card component to "a"
                          href={`/${lng}/video/${v.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '')}`}
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default navigation
                            handleVideoClick(v); // Keep client-side routing
                          }}
                          sx={{
                            display: "flex",
                            cursor: "pointer", 
                            width: '100%',
                            textDecoration: 'none', // Remove default link underline
                            color: 'inherit', // Keep text color consistent
                            "&:hover": {
                              bgcolor: "rgba(255, 255, 255, 0.05)"
                            }
                          }}
                        >
                          <Box sx={{ 
                            position: "relative", 
                            width: { xs: 160, md: 180 }, // Breite für mobile/desktop
                            minWidth: { xs: 160, md: 180 }, // Minimale Breite
                            aspectRatio: '16/9', // Erzwingt 16:9 Format
                            flexShrink: 0 
                          }}>
                            <CardMedia
                              component="img"
                              sx={{
                                position: 'absolute', // Absolute positioning
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: "cover"
                              }}
                              image={getThumbnailPath(v.thumbnail)}
                              alt={v.title}
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 4,
                                right: 4,
                                color: "white",
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                                padding: "2px 4px",
                                borderRadius: 1,
                                fontSize: "0.75rem"
                              }}
                            >
                              {v.duration}
                            </Box>
                          </Box>
                          <CardContent
                            sx={{
                              flex: "1 1 auto",
                              py: 1,
                              "&:last-child": { pb: 1 },
                              overflow: "hidden"
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                lineHeight: 1.2,
                                mb: 0.5
                              }}
                            >
                              {v.title}
                            </Typography>
                            <Typography
                              variant="caption"
                              display="block"
                              sx={{
                                color: "primary.main",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {v.actors.map((actorId, index) => {
                                const actor = allActors.find((a) => a.id === actorId);
                                return (
                                  <React.Fragment key={actorId}>
                                    <Box
                                      component="a"
                                      href={`/${lng}/actor/${generateActorSlug(actor)}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleActorClick(actorId);
                                      }}
                                      sx={{
                                        color: 'primary.main', // Use brand color
                                        textDecoration: 'none', // Remove default underline
                                        cursor: 'pointer',
                                        '&:hover': {
                                          textDecoration: 'underline', // Add underline only on hover
                                          color: 'primary.light' // Optional: lighter shade on hover
                                        }
                                      }}
                                    >
                                      {actor?.name}
                                    </Box>
                                    {index < v.actors.length - 1 ? ", " : ""}
                                  </React.Fragment>
                                );
                              })}
                            </Typography>
                            <Typography
                              variant="caption"
                              display="block"
                              color="text.secondary"
                              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                            >
                              {(() => {
                                const date = new Date(v.uploadDate);
                                const now = new Date();
                                const diffInSeconds = Math.floor((now - date) / 1000);
                                const diffInDays = Math.floor(diffInSeconds / 86400);
                                const diffInMonths = Math.floor(diffInDays / 30);
                                const diffInYears = Math.floor(diffInDays / 365);
                                if (diffInYears > 0) {
                                  return (
                                    diffInYears +
                                    " " +
                                    t(`time.${diffInYears === 1 ? "year" : "years"}`) +
                                    " " +
                                    t("time.ago")
                                  );
                                } else if (diffInMonths > 0) {
                                  return (
                                    diffInMonths +
                                    " " +
                                    t(`time.${diffInMonths === 1 ? "month" : "months"}`) +
                                    " " +
                                    t("time.ago")
                                  );
                                } else {
                                  return (
                                    diffInDays +
                                    " " +
                                    t(`time.${diffInDays === 1 ? "day" : "days"}`) +
                                    " " +
                                    t("time.ago")
                                  );
                                }
                              })()}

                            </Typography>
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                  </Box>
                  
                  {relatedGalleries.length > 0 && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        {t("videoPage.galleries")}
                      </Typography>
                      <Grid container spacing={2}>
                        {relatedGalleries.map((gallery) => (
                          <Grid item xs={12} sm={6} md={4} key={gallery.id}>
                            <Card
                              component="a"
                              href={`/${lng}/gallery/${gallery.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '')}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleGalleryClick(gallery);
                              }}
                              sx={{
                                cursor: "pointer",
                                textDecoration: 'none', // Remove underline
                                color: 'inherit', // Keep text color
                                display: 'block', // Ensure proper block display
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                                  transition: "transform 0.2s"
                                }
                              }}
                            >
                              <Box sx={{ position: "relative", width: "100%", aspectRatio: "16/9" }}>
                                {(() => {
                                  const firstImage = "0001";
                                  const middleImage = String(Math.ceil(gallery.imageCount / 2)).padStart(4, "0");
                                  const lastImage = String(gallery.imageCount).padStart(4, "0");
                                  return [firstImage, middleImage, lastImage].map((num, index) => (
                                    <Box
                                      key={index}
                                      component="img"
                                      // Check for thumb property and adjust path accordingly
                                      src={gallery.thumb === false 
                                        ? `${gallery.path}/${num}.jpg`  // Use main image if thumb is false
                                        : `${gallery.path}/thumb/${num}_thumb.jpg`  // Use thumbnail by default
                                      }
                                      alt={`${gallery.title} preview ${index + 1}`}
                                      sx={{
                                        position: "absolute",
                                        height: "100%",
                                        objectFit: "cover",
                                        ...(index === 0 && {
                                          width: "50%",
                                          left: 0,
                                          top: 0
                                        }),
                                        ...(index === 1 && {
                                          width: "50%",
                                          right: 0,
                                          top: 0,
                                          height: "50%"
                                        }),
                                        ...(index === 2 && {
                                          width: "50%",
                                          right: 0,
                                          bottom: 0,
                                          height: "50%"
                                        })
                                      }}
                                    />
                                  ));
                                })()}
                              </Box>
                              <CardContent>
                                <Typography variant="subtitle1" noWrap>
                                  {gallery.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {gallery.imageCount} {t("videoPage.images")}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  <Box sx={{ mt: 3, mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                      {t("videoPage.comments")} {/* Add this to your translations */}
                    </Typography>
                    <Box 
                      sx={{
                        bgcolor: '#1e1e1e', // Changed from rgba(0, 0, 0, 0.2) to solid #1e1e1e
                        borderRadius: 1,
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        p: 2
                      }}
                    >
                      <div id="disqus_thread" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* Right column - Recommended videos */}
          <Grid item xs={12} md={3.5} sx={{ 
            display: { 
              xs: 'none',
              md: 'block'
            },
            pr: { md: 2 },
            position: 'relative', 
            height: 'fit-content'
          }}>
            {/* Sidebar ad container */}
            {adsConfig.isAdsEnabled() && (
              <Box
                className="desktop-ad-container"
                sx={{
                  width: '300px',
                  height: '250px',
                  mb: 3,
                  mx: 'auto',
                  bgcolor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: 1,
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box ref={(el) => {
                  if (!el || sidebarAdInitialized) return;
                  
                  // Cleanup old ad scripts first
                  document.querySelectorAll('script[data-ad-script="sidebar-provider"]').forEach(script => script.remove());
                  document.querySelectorAll('script[data-ad-script="sidebar-initializer"]').forEach(script => script.remove());
                  document.querySelectorAll('ins.eas6a97888e2').forEach(ins => ins.remove());
                  
                  // Clear container
                  el.innerHTML = '';
                  
                  const providerScript = document.createElement('script');
                  providerScript.src = 'https://a.magsrv.com/ad-provider.js';
                  providerScript.async = true;
                  providerScript.setAttribute('data-ad-script', 'sidebar-provider');
                  el.appendChild(providerScript);
                
                  const ins = document.createElement('ins');
                  ins.className = 'eas6a97888e2';
                  ins.dataset.zoneid = '5514838';
                  el.appendChild(ins);
                
                  const initScript = document.createElement('script');
                  initScript.setAttribute('data-ad-script', 'sidebar-initializer');
                  initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
                  el.appendChild(initScript);
                  
                  setSidebarAdInitialized(true);
                }} />
              </Box>
            )}
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                fontSize: '1.25rem',
                fontWeight: 500,
                mb: 2
              }}
            >
              {t("videoPage.recommendedVideos")}
            </Typography>
            
            <Box sx={{ 
              display: "flex", 
              flexDirection: "column", 
              gap: 1.5 
            }}>
              {recommendedVideos.map((v) => (
                <Card
                  key={v.id}
                  component="a" // Set Card component to "a"
                  href={`/${lng}/video/${v.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '')}`}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default navigation
                    handleVideoClick(v); // Keep client-side routing
                  }}
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    textDecoration: 'none', // Remove default link underline
                    color: 'inherit', // Keep text color consistent
                    bgcolor: 'background.paper',
                    transition: 'all 0.2s ease',
                    height: '100px',
                    "&:hover": {
                      bgcolor: "rgba(255, 255, 255, 0.05)"
                    }
                  }}
                >
                  {/* Thumbnail container with fixed aspect ratio */}
                  <Box sx={{ 
                    position: "relative", 
                    width: { xs: 160, md: 180 }, // Breite für mobile/desktop
                    minWidth: { xs: 160, md: 180 }, // Minimale Breite
                    aspectRatio: '16/9', // Erzwingt 16:9 Format
                    flexShrink: 0 
                  }}>
                    <CardMedia
                      component="img"
                      sx={{
                        position: 'absolute', // Absolute positioning
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: "cover"
                      }}
                      image={getThumbnailPath(v.thumbnail)}
                      alt={v.title}
                    />
                    {/* Duration badge */}
                    <Typography
                      sx={{
                        position: "absolute",
                        bottom: 4,
                        right: 4,
                        color: "white",
                        bgcolor: "rgba(0, 0, 0, 0.8)",
                        px: 0.8,
                        py: 0.4,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        lineHeight: 1.2,
                        fontWeight: 500
                      }}
                    >
                      {v.duration}
                    </Typography>
                  </Box>

                  {/* Content section */}
                  <CardContent 
                    sx={{
                      flex: 1,
                      p: 1.5,
                      "&:last-child": { pb: 1.5 },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    {/* Title */}
                    <Typography
                      variant="subtitle2"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        lineHeight: 1.2,
                        height: '2.4em', // Fixe Höhe für 2 Zeilen (2 * 1.2em)
                        mb: 0.5
                      }}
                    >
                      {v.title}
                    </Typography>

                    {/* Metadata */}
                    <Box sx={{ mt: 'auto' }}>
                      {/* Actors */}
                      <Typography
                        variant="caption"
                        sx={{
                          color: "primary.main",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          mb: 0.5,
                          lineHeight: 1.2
                        }}
                      >
                        {v.actors?.map((actorId, index) => {
                          const actor = allActors.find(a => a.id === actorId);
                          return (
                            <React.Fragment key={actorId}>
                              <Box
                                component="a"
                                href={`/${lng}/actor/${generateActorSlug(actor)}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleActorClick(actorId);
                                }}
                                sx={{
                                  color: 'primary.main', // Use brand color
                                  textDecoration: 'none', // Remove default underline
                                  cursor: 'pointer',
                                  '&:hover': {
                                    textDecoration: 'underline', // Add underline only on hover
                                    color: 'primary.light' // Optional: lighter shade on hover
                                  }
                                }}
                              >
                                {actor?.name}
                              </Box>
                              {index < v.actors.length - 1 && ", "}
                            </React.Fragment>
                          );
                        })}
                      </Typography>

                      {/* Views & Upload date */}
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1,
                        color: 'text.secondary',
                        fontSize: '0.75rem'
                      }}>
                        
                        <Typography 
                          component="span" 
                          variant="caption" 
                          color="text.secondary"
                        >
                          {formatDate(v.uploadDate)}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMoreClose}
          MenuListProps={{
            'aria-labelledby': 'more-button',
          }}
        >
          <MenuItem onClick={() => {
            handleDownloadClick();
            handleMoreClose();
          }}>
            {t("videoPage.download.button")}
          </MenuItem>
        </Menu>
        <Modal 
          open={shareModalOpen} 
          onClose={handleCloseShareModal}
          aria-labelledby="share-modal-title"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: '90%', sm: 400 },
              bgcolor: 'background.paper',
              backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02))', // Match hoster modal
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: 2,
              p: 3,
              outline: 'none'
            }}
          >
            {/* Header */}
            <Typography 
              id="share-modal-title" 
              variant="h6" 
              component="h2" 
              gutterBottom
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'primary.main',
                fontWeight: 500,
                pb: 2,
                borderBottom: 1,
                borderColor: 'divider'
              }}
            >
              <ShareIcon /> {t("videoPage.share.title")}
            </Typography>

            {/* Video Preview Panel */}
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 2, 
                mt: 2, 
                mb: 3,
                p: 2,
                bgcolor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: 1
              }}
            >
              <Box 
                component="img"
                src={getThumbnailPath(video.thumbnail)}
                alt={video.title}
                sx={{ 
                  width: 120,
                  height: 67.5,
                  objectFit: 'cover',
                  borderRadius: 1
                }}
              />
              <Box sx={{ flex: 1 }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden', // Add this
                    textOverflow: 'ellipsis' // Add this
                  }}
                >
                  {video.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {video.actors?.map((actorId, index) => {
                    const actor = allActors.find(a => a.id === actorId);
                    return (
                      <React.Fragment key={actorId}>
                        {actor?.name}
                        {index < video.actors.length - 1 && ', '}
                      </React.Fragment>
                    );
                  })}
                </Typography>
              </Box>
            </Box>

            {/* Share Options List */}
            <List sx={{ pt: 1 }}>
              {[
                { 
                  icon: <ContentCopyIcon />, 
                  text: t("videoPage.share.copyLink"),
                  onClick: handleCopyLink 
                },
                { 
                  icon: <XIcon />, 
                  text: t("videoPage.share.shareOnX"),
                  onClick: handleShareX 
                },
                { 
                  icon: <RedditIcon />, 
                  text: t("videoPage.share.shareOnReddit"),
                  onClick: handleShareReddit 
                }
              ].map((option, index) => (
                <ListItem 
                  key={index}
                  button 
                  onClick={option.onClick}
                  sx={{ 
                    borderRadius: 1,
                    mb: index < 2 ? 1 : 0,
                    cursor: 'pointer', // Add pointer cursor
                    '&:hover': {
                      bgcolor: 'rgba(216, 158, 0, 0.08)',
                      '& .MuiListItemIcon-root': {
                        color: 'primary.main'
                      }
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={option.text}
                    primaryTypographyProps={{
                      sx: { fontWeight: 500 }
                    }}
                  />
                </ListItem>
              ))}
            </List>

            {/* Close Button */}
            <Button 
              fullWidth 
              variant="contained"
              onClick={handleCloseShareModal}
              sx={{ 
                mt: 3,
                py: 1,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark'
                }
              }}
            >
              {t('common.close')}
            </Button>
          </Box>
        </Modal>
        <Modal 
          open={downloadModalOpen} 
          onClose={handleCloseDownloadModal}
          aria-labelledby="download-modal-title"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: '90%', sm: 360 },
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 3,
              outline: 'none'
            }}
          >
            <Typography 
              id="download-modal-title" 
              variant="h6" 
              component="h2" 
              gutterBottom
              sx={{ 
                textAlign: 'center',
                pb: 2,
                borderBottom: 1,
                borderColor: 'divider'
              }}
            >
              {t("videoPage.download.title")}
            </Typography>

            <List sx={{ pt: 1 }}>
              {video.download?.map((dl) => (
                <ListItem 
                  key={dl.name}
                  button 
                  onClick={() => handleDownloadSelect(dl.embedUrl)}
                  sx={{ 
                    borderRadius: 1,
                    mb: 1,
                    '&:hover': {
                      bgcolor: 'rgba(216, 158, 0, 0.08)',
                      '& .MuiListItemIcon-root, & .MuiSvgIcon-root': {
                        color: 'primary.main'
                      }
                    },
                    cursor: 'pointer'
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                    {dl.name.toLowerCase() === 'rapidgator' ? (
                      <Box
                        component="img"
                        src="/images/download_icon_rapidgator.jpg"
                        alt="Rapidgator"
                        sx={{ width: 24, height: 24 }}
                      />
                    ) : (
                      <DownloadIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText 
                    primary={dl.name}
                    primaryTypographyProps={{
                      sx: { fontWeight: 500 }
                    }}
                  />
                  <LaunchIcon 
                    sx={{ 
                      fontSize: 16, 
                      ml: 1, 
                      color: 'text.secondary',
                      opacity: 0.7
                    }} 
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Modal>
        <Dialog
          open={hostingHelpOpen}
          onClose={handleHostingHelpClose}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              bgcolor: 'background.paper',
              backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02))',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: 2,
              overflow: 'hidden',
              maxHeight: {
                xs: 'calc(100% - 32px)', // Full height minus margin on mobile
                sm: '80vh' // 80% of viewport height on larger screens
              },
              margin: {
                xs: '16px', // Small margin on mobile
                sm: '32px'  // Larger margin on desktop
              }
            }
          }}
        >
          <Box 
            sx={{ 
              p: 3,
              overflowY: 'auto', // Make content scrollable
              '&::-webkit-scrollbar': {
                width: '8px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px'
              }
            }}
          >
            {/* Header */}
            <Typography 
              variant="h5" 
              gutterBottom 
              id="hoster-help-title"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'primary.main',
                fontWeight: 500
              }}
            >
              <HelpOutlineIcon /> {t('videoPage.hosters.helpTitle')}
            </Typography>

            {/* Main Text */}
            <Typography 
              variant="body1" 
              paragraph
              sx={{ 
                color: 'text.primary',
                lineHeight: 1.6 
              }}
            >
              {t('videoPage.hosters.helpText')}
            </Typography>

            {/* Reasons List */}
            <Box 
              component="ul" 
              sx={{ 
                pl: 3,
                listStyle: 'none',
                '& li': {
                  mb: 2,
                  '&:last-child': {
                    mb: 0
                  }
                }
              }}
            >
              {[
                t('videoPage.hosters.reason1'),
                t('videoPage.hosters.reason2'),
                t('videoPage.hosters.reason3')
              ].map((reason, index) => (
                <Typography 
                  key={index}
                  component="li" 
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: -20,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: 6,
                      height: 6,
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      opacity: 0.7
                    }
                  }}
                >
                  {reason}
                </Typography>
              ))}
            </Box>

            {/* Conclusion Text */}
            <Typography 
              variant="body2" 
              sx={{ 
                mt: 3,
                p: 2,
                bgcolor: 'rgba(216, 158, 0, 0.1)',
                border: '1px solid rgba(216, 158, 0, 0.2)',
                borderRadius: 1,
                color: 'primary.main',
                fontStyle: 'italic'
              }}
            >
              {t('videoPage.hosters.conclusion')}
            </Typography>

            {/* Close Button */}
            <Button 
              fullWidth 
              variant="contained"
              onClick={handleHostingHelpClose}
              sx={{ 
                mt: 3,
                py: 1,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark'
                }
              }}
            >
              {t('common.close')}
            </Button>
          </Box>
        </Dialog>
      </Container>
    </>
  );
}

export default VideoPage;
