import { getJsonBasePath } from '../App';

export const fetchFromJson = async (filename) => {
  const basePath = getJsonBasePath();
  const response = await fetch(`${basePath}/${filename}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
};

export const getVideos = () => fetchFromJson('list.json');
export const getActors = () => fetchFromJson('actors.json');
export const getGalleries = () => fetchFromJson('galleries.json');
export const getCategories = () => fetchFromJson('categories.json');
export const getOnlyFans = () => fetchFromJson('onlyfans.json');