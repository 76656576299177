import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const SUPPORTED_LANGUAGES = ['en', 'de', 'hi', 'es', 'fr', 'cn', 'tw', 'ru', 'pt', 'it', 'ar', 'ja', 'ko', 'tr', 'nl'];

// Prüfe ob die Sprache unterstützt wird
const isValidLanguage = (lng) => SUPPORTED_LANGUAGES.includes(lng);

// Special paths that should not be treated as language codes
const SPECIAL_PATHS = ['video', 'videos', 'categories'];

// Hole den Sprachcode aus der URL und Browser
const path = window.location.pathname;
const pathLng = path.split('/')[1];
const browserLng = navigator.language.split('-')[0];

// Wenn ein ungültiger Sprachcode in der URL ist:
// 1. Prüfe Browsersprache
// 2. Wenn nicht unterstützt, nutze Englisch
if (pathLng && !isValidLanguage(pathLng)) {
  const targetLang = isValidLanguage(browserLng) ? browserLng : 'en';
  
  // Check if the invalid path is one of our special paths
  if (SPECIAL_PATHS.includes(pathLng)) {
    // Prepend the language code instead of replacing
    window.location.pathname = `/${targetLang}${window.location.pathname}`;
  } else {
    // Original behavior for other invalid paths
    window.location.pathname = window.location.pathname.replace(`/${pathLng}`, `/${targetLang}`);
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'path', 'navigator'], // localStorage zuerst prüfen
      lookupFromPathIndex: 0,
      // Korrekte Konfiguration der Cache-Optionen
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'], // Optional: Verhindert Caching für bestimmte Sprach-Modi
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

// Speichere Sprachänderungen im localStorage
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nextLng', lng);
});

export default i18n;