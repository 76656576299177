// src/components/ErrorPage.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { WEBSITE_NAME } from '../App';
import { getLocale } from '../i18n/locales';

function ErrorPage({ code }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { lng } = useParams();

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '70vh',
        textAlign: 'center',
        p: 3
      }}
    >
      <Helmet>
        <title>{`${code} - ${t(`error.${code}.title`)}`}</title>
        <meta name="description" content={t(`error.${code}.message`)} />
        <meta name="robots" content="noindex" />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${code} - ${t(`error.${code}.title`)} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t(`error.${code}.message`)} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${code} - ${t(`error.${code}.title`)} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t(`error.${code}.message`)} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org WebPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": `${code} - ${t(`error.${code}.title`)} - ${WEBSITE_NAME}`,
            "description": t(`error.${code}.message`)
          })}
        </script>
      </Helmet>
      
      <Typography variant="h1" sx={{ mb: 2, fontSize: '4rem' }}>
        {code}
      </Typography>
      
      <Typography variant="h4" sx={{ mb: 3 }}>
        {t(`error.${code}.title`)}
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 4 }}>
        {t(`error.${code}.message`)}
      </Typography>

      <Button 
        variant="contained" 
        color="primary"
        onClick={() => navigate(`/${i18n.language}`)}
      >
        {t('error.backToHome')}
      </Button>
    </Box>
  );
}

export default ErrorPage;