import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Tabs,
  Tab,
  Container 
} from '@mui/material';
import VideoGrid from '../common/VideoGrid';
import GalleryGrid from '../common/GalleryGrid';
import ImageGrid from '../common/ImageGrid'; // You'll need to create this component
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import OnlyFansTimeline from '../common/OnlyFansTimeline';
import { WEBSITE_NAME, getThumbnailPath } from '../App'; // Add getThumbnailPath import
import { getLocale } from '../i18n/locales';
import { getVideos, getActors, getGalleries, getOnlyFans } from '../utils/api';
import ImageViewer from '../common/ImageViewer'; // Add this import

function FavoritesPage() {
  const { t } = useTranslation();
  const { lng } = useParams();
  const [tab, setTab] = useState('videos');
  const [videos, setVideos] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [images, setImages] = useState([]);
  const [actors, setActors] = useState([]);
  const [page, setPage] = useState(1);
  const [onlyFansPosts, setOnlyFansPosts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const loadFavoriteVideos = async () => {
    try {
      const favoriteVideos = JSON.parse(localStorage.getItem('favoriteVideos') || '[]');
      
      if (!favoriteVideos.length) {
        setVideos([]);
        return;
      }

      const data = await getVideos();
      const filteredVideos = data.filter(video => favoriteVideos.includes(video.id));
      setVideos(filteredVideos);
    } catch (error) {
      console.error('Error loading favorite videos:', error);
      setVideos([]);
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const favoriteVideos = JSON.parse(localStorage.getItem('favoriteVideos') || '[]');
        const favoriteGalleries = JSON.parse(localStorage.getItem('favoriteGalleries') || '[]');
        const favoritePostIds = JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');

        const [actorsData, videosData, galleriesData, onlyFansData] = await Promise.all([
          getActors(),
          getVideos(),
          getGalleries(),
          getOnlyFans()
        ]);

        setActors(actorsData);

        // Map thumbnails through getThumbnailPath
        const filteredVideos = videosData
          .filter(video => favoriteVideos.includes(video.id))
          .map(video => ({
            ...video,
            thumbnail: getThumbnailPath(video.thumbnail)
          }));
        setVideos(filteredVideos);

        // Map thumbnails through getThumbnailPath for galleries 
        const filteredGalleries = galleriesData
          .filter(gallery => favoriteGalleries.includes(gallery.id))
          .map(gallery => ({
            ...gallery,
            thumbnail: getThumbnailPath(gallery.thumbnail)
          }));
        setGalleries(filteredGalleries);

        // Map thumbnails for OnlyFans posts if they have thumbnails
        const filteredPosts = onlyFansData.posts
          .filter(post => favoritePostIds.includes(post.id))
          .map(post => ({
            ...post,
            thumbnail: post.thumbnail ? getThumbnailPath(post.thumbnail) : post.thumbnail
          }));
        setOnlyFansPosts(filteredPosts);
      } catch (error) {
        console.error('Error loading favorites:', error);
      }
    };

    loadInitialData();

    // Update image paths using getThumbnailPath
    const favoriteImages = JSON.parse(localStorage.getItem('favoriteImages') || '[]');
    
    setImages(favoriteImages.map(url => {
      const isThumb = url.includes('/thumb/') && url.includes('_thumb');
      if (isThumb) {
        return {
          src: getThumbnailPath(url),
          fullSrc: getThumbnailPath(url.replace('/thumb/', '/').replace('_thumb.jpg', '.jpg'))
        };
      } else {
        const adjustedUrl = getThumbnailPath(url);
        return {
          src: adjustedUrl,
          fullSrc: adjustedUrl
        };
      }
    }));
  }, []); // Empty dependency array since we only want this to run once on mount

  // Add this effect to listen for gallery favorite changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'favoriteGalleries') {
        // Get updated favorite gallery IDs
        const favoriteGalleries = JSON.parse(localStorage.getItem('favoriteGalleries') || '[]');
        
        // Update galleries state by filtering the current galleries
        setGalleries(prevGalleries => 
          prevGalleries.filter(gallery => favoriteGalleries.includes(gallery.id))
        );
      }
    };

    // Listen for storage events
    window.addEventListener('storage', handleStorageChange);

    // Also add direct document event listener for same-tab updates
    document.addEventListener('galleryFavoriteChanged', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      document.removeEventListener('galleryFavoriteChanged', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const loadOnlyFansData = async () => {
      if (tab !== 'onlyfans') return;

      try {
        const favoritePostIds = JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');
        
        if (!favoritePostIds.length) {
          setOnlyFansPosts([]);
          return;
        }

        const [postsData, actorsData] = await Promise.all([
          getOnlyFans(),
          getActors()
        ]);

        const filteredPosts = postsData.posts.filter(post => 
          favoritePostIds.includes(post.id)
        );
        setOnlyFansPosts(filteredPosts);
        setActors(actorsData);
      } catch (error) {
        console.error('Error loading OnlyFans data:', error);
        setOnlyFansPosts([]);
      }
    };

    loadOnlyFansData();
  }, [tab]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    if (newValue === 'videos') {
      loadFavoriteVideos();
    }
  };

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const handleImageClick = (index) => {
    setSelectedImage(index);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setSelectedImage(null);
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Helmet>
        <title>{`${t('meta.favorites.title')}`}</title>
        <meta name="description" content={t('meta.favorites.description')} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${t('meta.favorites.title')} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.favorites.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${t('meta.favorites.title')} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.favorites.description')} />
        <meta name="twitter:image" content={`${window.location.origin}/images/og-image.png`} />

        {/* Schema.org CollectionPage markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": `${t('meta.favorites.title')} - ${WEBSITE_NAME}`,
            "description": t('meta.favorites.description'),
            "numberOfItems": videos.length + galleries.length + images.length + onlyFansPosts.length,
            "hasPart": [
              ...videos.slice(0, 5).map(video => ({
                "@type": "VideoObject",
                "name": video.title,
                "thumbnailUrl": `${window.location.origin}${video.thumbnail}`
              })),
              ...galleries.slice(0, 5).map(gallery => ({
                "@type": "ImageGallery",
                "name": gallery.title
              }))
            ]
          })}
        </script>
      </Helmet>
      <Typography variant="h4" gutterBottom>
        {t('favorites.title')}
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={tab} 
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              color: 'text.secondary',
              '&.Mui-selected': {
                color: 'primary.main'
              }
            }
          }}
        >
          <Tab 
            label={t('favorites.videosTab', { count: videos.length })} 
            value="videos" 
          />
          <Tab 
            label={t('favorites.imagesTab', { count: images.length })} 
            value="images" 
          />
          <Tab 
            label={t('favorites.galleriesTab', { count: galleries.length })} 
            value="galleries" 
          />
          <Tab 
            label={t('favorites.onlyFansTab', { count: onlyFansPosts.length })} 
            value="onlyfans" 
          />
        </Tabs>
      </Box>

      {tab === 'videos' && (
        videos.length > 0 ? (
          <VideoGrid
            videos={videos}
            actors={actors}
            lng={lng}
            getTimeAgo={getTimeAgo}
            videosPerPage={12}  // Optional: Füge eine feste Anzahl pro Seite hinzu
            showPagination={true}  // Optional: Aktiviere Pagination
            gridItemProps={{ xs: 12, sm: 6, md: 4 }}  // Optional: Definiere Grid-Layout
            isFavoritesView={true} // Add this prop
            page={page} // Add current page
            onPageChange={handlePageChange} // Add page change handler
          />
        ) : (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200,
              bgcolor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: 1
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {t('favorites.noVideos')}
            </Typography>
          </Box>
        )
      )}

      {tab === 'images' && (
        images.length > 0 ? (
          <>
            <ImageGrid 
              images={images}
              setImages={setImages} // Add this prop
              isFavoritesView={true}
              gridItemProps={{ xs: 12, sm: 6, md: 4, lg: 3 }}
              onImageClick={handleImageClick}
              useCustomViewer={false} // Add this prop to disable built-in viewer
            />
            
            <ImageViewer
              open={isViewerOpen}
              onClose={handleCloseViewer}
              images={images.map(img => ({
                // Wenn img bereits ein Objekt mit src/fullSrc ist, nutze diese
                // Ansonsten erstelle ein neues Objekt mit der URL
                src: img.fullSrc || img,
                fullSrc: img.fullSrc || img.replace('/thumb/', '/').replace('_thumb.jpg', '.jpg')
              }))}
              selectedIndex={selectedImage}
              onIndexChange={setSelectedImage}
              t={t}
              enableDownload={true}
              enableFullscreen={true} 
              enableZoom={true}
            />
          </>
        ) : (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200,
              bgcolor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: 1
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {t('favorites.noImages')}
            </Typography>
          </Box>
        )
      )}

      {tab === 'galleries' && (
        galleries.length > 0 ? (
          <GalleryGrid
            galleries={galleries}
            actors={actors}
            lng={lng}
            isFavoritesView={true} // Add this prop
          />
        ) : (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200,
              bgcolor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: 1
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {t('favorites.noGalleries')}
            </Typography>
          </Box>
        )
      )}

      {tab === 'onlyfans' && (
        onlyFansPosts.length > 0 ? (
          <OnlyFansTimeline 
            posts={onlyFansPosts}
            actors={actors}
            lng={lng}
            isFavoritesView={true}
          />
        ) : (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200,
              bgcolor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: 1
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {t('favorites.noOnlyFansPosts')}
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

export default FavoritesPage;