import React, { useEffect, useRef, useState, useCallback } from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';
import "./plyr-custom.css";
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VideoAdsManager from './VideoAdsManager';

const getBasePlayerConfig = (video) => {
  const baseConfig = {
    controls: [
      'play',
      'progress',
      'current-time',
      'duration',
      'mute',
      'volume',
      'settings',
      'fullscreen'
    ],
    settings: ['quality', 'speed'],
    quality: {
      default: 1080,
      options: [4320, 2880, 2160, 1440, 1080, 720, 480, 360, 240]
    },
    fullscreen: {
      enabled: true,
      fallback: true,
      iosNative: true
    },
    hideControls: true,
    load: true // Aktiviert die native Buffer-Anzeige von Plyr
  };

  // Add thumbnails if available
  if (video.vidthumbnails?.[0]) {
    baseConfig.previewThumbnails = {
      enabled: true,
      src: video.vidthumbnails[0].vtt,
      thumbnailUrl: video.vidthumbnails[0].jpg
    };
  }

  return baseConfig;
};

// For MKTV player (native Plyr)
const getMktvConfig = (video) => {
  return {
    ...getBasePlayerConfig(video),
    clickToPlay: true,
    listeners: {
      click: true
    },
    autoplay: true,  // Enable autoplay for MKTV
    // Quality settings will be added dynamically when HLS manifest is parsed
  };
};

// For Direct player (PlyrReact)
const getDirectConfig = (video) => {
  return {
    ...getBasePlayerConfig(video),
    playsinline: true,
    autoplay: false,
    clickToPlay: true,  // Ensure this is enabled
    listeners: {
      click: true      // Enable click events
    },
    muted: true,
    resetOnEnd: false,
    hideControls: true
  };
};

const VideoPlayer = React.memo(({ video, selectedHoster, playerRef }) => {
  const hoster = video.hosters.find((h) => h.name === selectedHoster);
  const isDirect = selectedHoster === 'direct';
  const isMktv = selectedHoster === 'mktv';
  const videoRef = useRef(null);
  const mktvPlayerRef = useRef(null);
  const hlsRef = useRef(null);
  const [isBuffering, setIsBuffering] = useState(false);
  const [showPlayIcon, setShowPlayIcon] = useState(false);
  const [showPauseIcon, setShowPauseIcon] = useState(false);
  // Add new states for loop and copy animations
  const [showLoopIcon, setShowLoopIcon] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [isFastForwarding, setIsFastForwarding] = useState(false);
  const previousPlaybackRate = useRef(1);
  const mouseDownActive = useRef(false); // Track if mouse was pressed on player
  const fastForwardModeRef = useRef(false);
  
  // Context menu state
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [isRepeatEnabled, setIsRepeatEnabled] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const { t } = useTranslation();
  // Add new state for ad handling
  const [isAdPlaying, setIsAdPlaying] = useState(true);
  const [adComplete, setAdComplete] = useState(false);

  // Full cleanup function
  const fullCleanup = () => {
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
    if (mktvPlayerRef.current) {
      mktvPlayerRef.current.destroy();
      mktvPlayerRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.removeAttribute('src');
      videoRef.current.load();
    }
  };

  // Function to check if current quality is HD (720p or higher)
  const isHDQuality = (height) => {
    return height >= 720;
  };

  // Function to add/remove HD badge to the settings button
  const updateHDBadge = (player, currentQuality) => {
    const settingsButton = player.elements.buttons.settings;
    if (!settingsButton) return;

    // Remove existing badge if any
    const existingBadge = settingsButton.querySelector('.hd-badge');
    if (existingBadge) {
      existingBadge.remove();
    }

    // Add badge if quality is HD
    if (isHDQuality(currentQuality)) {
      const badge = document.createElement('span');
      badge.className = 'hd-badge';
      badge.textContent = 'HD';
      settingsButton.appendChild(badge);
    }
  };

  // Handle context menu - modified to properly handle the right-click event
  const handleContextMenu = useCallback((e) => {
    if (!e.target.closest('.plyr__controls') && (isDirect || isMktv)) {
      e.preventDefault();
      e.stopPropagation();
      
      // Ensure menu is positioned within view
      let x = e.clientX;
      let y = e.clientY;
      
      // Make sure the menu doesn't go off-screen
      const menuWidth = 180; // Approximate width of menu
      const menuHeight = 80; // Approximate height of menu
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      
      if (x + menuWidth > windowWidth) {
        x = windowWidth - menuWidth - 5;
      }
      
      if (y + menuHeight > windowHeight) {
        y = windowHeight - menuHeight - 5;
      }
      
      setContextMenuPosition({ x, y });
      setShowContextMenu(true);
      console.log("Context menu opened"); // Debug log
    }
  }, [isDirect, isMktv]);

  // Copy video URL to clipboard - updated to copy the current page URL
  const handleCopyVideoUrl = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    console.log("Copy URL clicked"); // Debug log
    
    // Use the current page URL instead of the video source URL
    const urlToCopy = window.location.href;
    console.log("URL to copy:", urlToCopy); // Debug log
    
    try {
      // Use modern clipboard API with fallback
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(urlToCopy)
          .then(() => {
            console.log('URL copied to clipboard successfully'); // Debug log
            setShowCopiedMessage(true);
            setTimeout(() => setShowCopiedMessage(false), 2000);
            
            // Show the copy icon animation
            setShowCopyIcon(true);
            setTimeout(() => setShowCopyIcon(false), 700);
          })
          .catch(err => {
            console.error('Failed to copy URL using Clipboard API:', err);
            // Fallback method
            fallbackCopyTextToClipboard(urlToCopy);
          });
      } else {
        // For older browsers
        fallbackCopyTextToClipboard(urlToCopy);
      }
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
    
    // Close menu after action
    setTimeout(() => {
      setShowContextMenu(false);
    }, 100);
  }, []); // Remove hoster?.embedUrl from dependencies since we're not using it
  
  // Fallback copying method using textarea element
  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        console.log('URL copied to clipboard using fallback'); // Debug log
        setShowCopiedMessage(true);
        setTimeout(() => setShowCopiedMessage(false), 2000);
        
        // Show the copy icon animation
        setShowCopyIcon(true);
        setTimeout(() => setShowCopyIcon(false), 700);
      } else {
        console.error('Fallback copy failed');
      }
    } catch (err) {
      console.error('Fallback: Could not copy text: ', err);
    }
    
    document.body.removeChild(textArea);
  };

  // Toggle repeat mode - improved implementation with direct DOM manipulation
  const handleToggleRepeat = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    console.log("Toggle repeat clicked"); // Debug log
    
    setIsRepeatEnabled(prevState => {
      const newState = !prevState;
      console.log("New repeat state:", newState); // Debug log
      
      // Apply the repeat mode directly to the video element
      const currentVideoRef = videoRef.current;
      if (currentVideoRef) {
        currentVideoRef.loop = newState;
        console.log("Video loop set to:", newState); // Debug log
      }
      
      // Show the loop icon animation
      setShowLoopIcon(true);
      setTimeout(() => setShowLoopIcon(false), 700);
      
      return newState;
    });
    
    // Close menu after action
    setTimeout(() => {
      setShowContextMenu(false);
    }, 100);
  }, []);

  useEffect(() => {
    let player;
    let hls;
    const currentVideoRef = videoRef.current;

    const initializePlayer = () => {
      if (isMktv && !adComplete) {
        // Don't initialize the player yet if ad hasn't completed
        return;
      }

      fullCleanup();

      if ((isMktv || isDirect) && currentVideoRef) {
        const playerConfig = isDirect ? getDirectConfig(video) : getMktvConfig(video);

        // For direct player, initialize directly
        if (isDirect) {
          player = new Plyr(currentVideoRef, playerConfig);
          if (playerRef) {
            playerRef.current = player;
          }
          mktvPlayerRef.current = player;
          
          currentVideoRef.src = hoster?.embedUrl;
          currentVideoRef.load();
          player.play().catch(error => {
            currentVideoRef.muted = true;
            player.play();
          });
        } 
        // For MKTV/HLS player, handle HLS initialization
        else if (isMktv && Hls.isSupported() && hoster?.embedUrl) {
          hls = new Hls({
            debug: false,
            enableWorker: true,
            capLevelToPlayerSize: true,
            startLevel: -1,
            maxLoadingDelay: 4,
            maxBufferLength: 30,
            autoStartLoad: true
          });

          hls.loadSource(hoster.embedUrl);
          hls.attachMedia(currentVideoRef);
          hlsRef.current = hls;

          // Handle quality levels
          hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
            // Transform available levels into an array of integers (height values)
            const availableQualities = hls.levels.map((l) => l.height);
            availableQualities.unshift(0); // Prepend 0 for AUTO quality
            
            // Add new qualities to options
            const enhancedConfig = {
              ...playerConfig,
              quality: {
                default: 0, // Default - AUTO
                options: availableQualities,
                forced: true,
                onChange: (quality) => updateQuality(quality, hls, currentVideoRef),
              },
              // Add Auto Label
              i18n: {
                qualityLabel: {
                  0: 'Auto',
                },
              }
            };
            
            // Initialize player with the enhanced config including quality options
            player = new Plyr(currentVideoRef, enhancedConfig);
            
            if (playerRef) {
              playerRef.current = player;
            }
            mktvPlayerRef.current = player;
            
            // Update Auto quality label when level changes
            hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
              const span = document.querySelector(".plyr__menu__container [data-plyr='quality'][value='0'] span");
              if (span) {
                if (hls.autoLevelEnabled) {
                  const currentHeight = hls.levels[data.level].height;
                  span.innerHTML = `Auto (${currentHeight}p)`;
                  // Update HD badge based on current quality
                  updateHDBadge(player, currentHeight);
                } else {
                  span.innerHTML = `Auto`;
                }
              }
            });
            
            // Also listen for manual quality changes
            player.on('qualitychange', (e) => {
              const newQuality = parseInt(e.detail.quality);
              if (newQuality === 0 && hls.autoLevelEnabled) {
                // If Auto selected, use current HLS level's height
                const currentLevel = hls.levels[hls.currentLevel];
                if (currentLevel) {
                  updateHDBadge(player, currentLevel.height);
                }
              } else {
                // For manually selected quality
                updateHDBadge(player, newQuality);
              }
            });
            
            // Set up event listeners for player
            setupPlayerEventListeners(player);
            
            // Start playback immediately for MKTV
            player.muted = true; // Temporarily mute to ensure autoplay works
            player.play().then(() => {
              player.muted = false; // Unmute after successful autoplay
            }).catch(error => {
              // If autoplay fails, keep it muted and try again
              player.play();
            });
            
            // Initialize HD badge with default quality
            if (hls.autoLevelEnabled && hls.levels[hls.currentLevel]) {
              updateHDBadge(player, hls.levels[hls.currentLevel].height);
            } else if (hls.levels && hls.levels.length > 0) {
              // If current level isn't set yet, listen for the first level switch
              // to ensure we show the HD badge if the initial quality is HD
              const onInitialLevelSwitch = () => {
                if (hls.currentLevel >= 0 && hls.levels[hls.currentLevel]) {
                  updateHDBadge(player, hls.levels[hls.currentLevel].height);
                  hls.off(Hls.Events.LEVEL_SWITCHED, onInitialLevelSwitch);
                }
              };
              hls.on(Hls.Events.LEVEL_SWITCHED, onInitialLevelSwitch);
              
              // Also set an initial HD badge if the default quality is HD
              // This covers cases where HLS might not switch levels immediately
              const initialLevel = hls.startLevel >= 0 ? hls.startLevel : 0;
              if (hls.levels[initialLevel] && isHDQuality(hls.levels[initialLevel].height)) {
                updateHDBadge(player, hls.levels[initialLevel].height);
              }
            }

            // Add an additional listener for the first fragment loaded
            // This helps catch the initial quality even before a level switch
            const onFirstFragmentLoaded = (event, data) => {
              if (data.frag && data.frag.level >= 0 && hls.levels[data.frag.level]) {
                updateHDBadge(player, hls.levels[data.frag.level].height);
                hls.off(Hls.Events.FRAG_LOADED, onFirstFragmentLoaded);
              }
            };
            hls.on(Hls.Events.FRAG_LOADED, onFirstFragmentLoaded);
          });

          // Handle errors
          hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch(data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  hls.recoverMediaError();
                  break;
                default:
                  fullCleanup();
                  break;
              }
            }
          });
        }
        
        // If not HLS/MKTV player, set up direct player event listeners
        if (!isMktv && player) {
          setupPlayerEventListeners(player);
        }

        // Add context menu event listener directly to the video element
        // and its container for better coverage
        if ((isMktv || isDirect) && currentVideoRef) {
          // First, remove any existing listeners to avoid duplicates
          currentVideoRef.removeEventListener('contextmenu', handleContextMenu);
          currentVideoRef.addEventListener('contextmenu', handleContextMenu);
          
          // Also add the event listener to the parent container when it's ready
          const setupContextMenuOnWrapper = () => {
            // Try to find the video wrapper after a short delay to ensure it's in the DOM
            setTimeout(() => {
              const videoWrapper = currentVideoRef.closest('.plyr__video-wrapper');
              if (videoWrapper) {
                videoWrapper.removeEventListener('contextmenu', handleContextMenu);
                videoWrapper.addEventListener('contextmenu', handleContextMenu);
              }
            }, 100);
          };
          
          setupContextMenuOnWrapper();
        }
      }
    };
    
    // Quality change handler function
    const updateQuality = (newQuality, hls, videoElement) => {
      // Store current playback state and position
      const currentTime = videoElement.currentTime;
      const wasPlaying = !videoElement.paused;
      
      // Show buffering indicator
      setIsBuffering(true);
      
      if (newQuality === 0) {
        // Enable AUTO quality if option value is 0
        hls.currentLevel = -1;
      } else {
        // Find the matching quality level
        hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            console.log(`Switching to quality: ${newQuality}p (level ${levelIndex})`);
            hls.currentLevel = levelIndex;
          }
        });
      }
      
      // Handle seeking to current position after quality change
      const onLevelLoaded = () => {
        videoElement.currentTime = currentTime;
        if (wasPlaying) {
          videoElement.play().catch(e => console.error("Failed to resume after quality change:", e));
        }
        hls.off(Hls.Events.LEVEL_LOADED, onLevelLoaded);
      };
      
      hls.on(Hls.Events.LEVEL_LOADED, onLevelLoaded);
      
      // Reset buffering indicator when media starts playing again
      const onPlaying = () => {
        setIsBuffering(false);
        videoElement.removeEventListener('playing', onPlaying);
      };
      videoElement.addEventListener('playing', onPlaying);
    };
    
    // Extract common player event setup to a function for reuse
    const setupPlayerEventListeners = (player) => {
      // Use two separate systems for click and fast-forward
      
      // Flag to track if we were in fast-forward mode
      let wasInFastForwardMode = false;
      
      // Click handler for play/pause
      const handleVideoClick = (e) => {
        // Skip if fast-forward mode was just active or recently active
        if (fastForwardModeRef.current || wasInFastForwardMode) {
          return;
        }
        
        // Ignore clicks on controls
        if (e.target.closest('.plyr__controls')) {
          return;
        }
        
        // Prevent event bubbling
        e.stopPropagation();
        
        // Wait a frame to avoid race conditions
        requestAnimationFrame(() => {
          if (player.paused) {
            player.play();
          } else {
            player.pause();
          }
        });
      };

      // Fast-forward with mousedown/mouseup
      const initFastForwardHandlers = () => {
        const videoWrapper = currentVideoRef.closest('.plyr__video-wrapper');
        if (!videoWrapper) return;
        
        // Track if we're handling a fast-forward sequence or just a normal click
        let isFastForwardSequence = false;
        let mouseDownTimer = null;
        
        // Define handlers first before using them in cleanup
        // Handle mouse down - start fast-forward
        function handleMouseDown(e) {
          // Only process left mouse button (button code 0) and when not paused
          if (e.button !== 0 || player.paused || e.target.closest('.plyr__controls')) {
            return;
          }
          
          // Use a short delay before activating fast-forward mode
          // This helps distinguish between clicks and fast-forward
          clearTimeout(mouseDownTimer);
          mouseDownTimer = setTimeout(() => {
            // After delay, activate fast-forward
            isFastForwardSequence = true;
            fastForwardModeRef.current = true;
            wasInFastForwardMode = false;
            
            // Store current rate before changing
            previousPlaybackRate.current = player.speed;
            
            // Double speed
            player.speed = 2;
            setIsFastForwarding(true);
          }, 150); // Short delay to differentiate click from hold
          
          // Prevent default to avoid text selection during dragging
          e.preventDefault();
        }
        
        // Handle mouse up - stop fast-forward
        function handleMouseUp(e) {
          clearTimeout(mouseDownTimer);
          
          // Only handle if we're actually in fast-forward mode
          if (!isFastForwardSequence) {
            return; // Let normal click processing happen
          }
          
          if (fastForwardModeRef.current) {
            // Reset to normal speed
            player.speed = previousPlaybackRate.current;
            setIsFastForwarding(false);
            
            // Set wasInFastForwardMode to true to prevent the click handler
            // from triggering on this same mouseup event
            wasInFastForwardMode = true;
            
            // Clear the fast-forward flag
            fastForwardModeRef.current = false;
            
            // Use setTimeout to reset the wasInFastForwardMode flag after all click events have processed
            setTimeout(() => {
              wasInFastForwardMode = false;
            }, 300); // Give enough time for click events to finish processing
            
            // Prevent the click event from firing only if we were in fast-forward mode
            e.stopPropagation();
            e.preventDefault();
          }
          
          // Reset the fast-forward sequence tracker
          isFastForwardSequence = false;
        }
        
        // Handle mouse leave - also stop fast-forward
        function handleMouseLeave(e) {
          clearTimeout(mouseDownTimer);
          
          // Handle the case when mouse leaves the player while button is pressed
          if (fastForwardModeRef.current) {
            player.speed = previousPlaybackRate.current;
            setIsFastForwarding(false);
            
            // Set wasInFastForwardMode to true to prevent click handler from running
            wasInFastForwardMode = true;
            fastForwardModeRef.current = false;
            
            // Reset the flag after enough time has passed
            setTimeout(() => {
              wasInFastForwardMode = false;
            }, 300);
          }
          
          // Reset the fast-forward sequence tracker
          isFastForwardSequence = false;
        }
        
        // Handle click events specifically to prevent them when fast-forwarding
        function handleClick(e) {
          // Only prevent clicks if we were just in fast-forward mode
          if (wasInFastForwardMode || fastForwardModeRef.current) {
            e.stopPropagation();
            e.preventDefault();
          }
        }
        
        // Remove any existing handlers
        function cleanupHandlers() {
          videoWrapper.removeEventListener('mousedown', handleMouseDown, true);
          videoWrapper.removeEventListener('mouseup', handleMouseUp, true);
          videoWrapper.removeEventListener('mouseleave', handleMouseLeave, true);
          videoWrapper.removeEventListener('click', handleClick, true);
          document.removeEventListener('mouseup', handleMouseUp, true);
          clearTimeout(mouseDownTimer);
        }
        
        // Clean up any existing handlers first
        cleanupHandlers();
        
        // Add handlers with capture phase to ensure they run first
        videoWrapper.addEventListener('mousedown', handleMouseDown, true);
        videoWrapper.addEventListener('mouseup', handleMouseUp, true);
        videoWrapper.addEventListener('mouseleave', handleMouseLeave, true);
        videoWrapper.addEventListener('click', handleClick, true);
        
        // Also listen for mouseup on document in case user releases outside player
        document.addEventListener('mouseup', handleMouseUp, true);
        
        // Return cleanup function
        return cleanupHandlers;
      };

      // Initialize handlers for fast-forward
      const cleanupFastForward = initFastForwardHandlers();

      // Handle play/pause click separately
      const videoWrapper = currentVideoRef.closest('.plyr__video-wrapper');
      if (videoWrapper) {
        videoWrapper.removeEventListener('click', handleVideoClick);
        videoWrapper.addEventListener('click', handleVideoClick);
      }

      // Add listener for speed changes from other sources
      player.on('ratechange', () => {
        // Only update our state if we're not currently in fast-forward mode
        if (!fastForwardModeRef.current) {
          previousPlaybackRate.current = player.speed;
        }
      });

      // Add keyboard event handling
      const handleKeyDown = (e) => {
        // Only handle if this player has focus (check if it's in the DOM and visible)
        const playerElement = currentVideoRef.closest('.plyr');
        if (!playerElement || !document.body.contains(playerElement) || 
            window.getComputedStyle(playerElement).display === 'none') {
          return;
        }

        // Don't handle keyboard events when user is typing in an input, textarea, etc.
        if (['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.tagName)) {
          return;
        }

        switch (e.key) {
          case ' ': // Space key
            e.preventDefault();
            if (player.paused) {
              player.play();
            } else {
              player.pause();
            }
            break;
          case 'ArrowRight':
            e.preventDefault();
            player.forward(10); // Forward 10 seconds
            break;
          case 'ArrowLeft':
            e.preventDefault();
            player.rewind(10); // Rewind 10 seconds
            break;
          case 'f':
          case 'F':
            e.preventDefault();
            player.fullscreen.toggle(); // Toggle fullscreen
            break;
          default:
            break;
        }
      };

      // Add keyboard listener to document
      document.addEventListener('keydown', handleKeyDown);

      player.on('playing', () => {
        setIsBuffering(false);
        setShowPlayIcon(true);
        setTimeout(() => setShowPlayIcon(false), 700);
      });

      player.on('pause', () => {
        setShowPauseIcon(true);
        setTimeout(() => setShowPauseIcon(false), 700);
      });

      // Clean up keyboard listener when player is destroyed
      player.on('destroy', () => {
        document.removeEventListener('keydown', handleKeyDown);
        if (cleanupFastForward) cleanupFastForward();
        if (videoWrapper) {
          videoWrapper.removeEventListener('click', handleVideoClick);
        }
        
        const videoWrapper = currentVideoRef.closest('.plyr__video-wrapper');
        if (videoWrapper) {
          videoWrapper.removeEventListener('contextmenu', handleContextMenu);
        }
      });

      // Add hover preview bar functionality
      const setupHoverPreviewBar = () => {
        // Wait for the player UI to be fully initialized
        setTimeout(() => {
          const progressContainer = player.elements.progress?.parentNode;
          if (!progressContainer) return;
          
          // First check if a hover preview already exists and remove it
          const existingPreview = progressContainer.querySelector('.plyr__progress__hover-preview');
          if (existingPreview) {
            existingPreview.remove();
          }
          
          // Create the hover preview element
          const hoverPreview = document.createElement('div');
          hoverPreview.className = 'plyr__progress__hover-preview';
          
          // The key change: add the hover preview to the same container as the buffer bar for proper positioning
          // Find the progress element which contains the input range and buffer
          const progressElement = progressContainer.querySelector('.plyr__progress');
          
          if (progressElement) {
            // Insert the hover preview as the first child of the progress element
            // This will place it behind both the buffer and the track
            progressElement.insertBefore(hoverPreview, progressElement.firstChild);
            
            // Handle mousemove to update hover preview position
            const updateHoverPreview = (e) => {
              // Get the bounding rectangle of the progress container
              const rect = progressContainer.getBoundingClientRect();
              
              // Calculate the hover position as a percentage
              const posX = e.clientX - rect.left;
              const percentage = Math.min(Math.max(posX / rect.width, 0), 1);
              
              // Update the width of the hover preview
              hoverPreview.style.width = `${percentage * 100}%`;
            };
            
            // Handle mouseleave to reset hover preview
            const resetHoverPreview = () => {
              hoverPreview.style.width = '0%';
            };
            
            // Add mouse move listener to the progress container
            progressContainer.addEventListener('mousemove', updateHoverPreview);
            progressContainer.addEventListener('mouseleave', resetHoverPreview);
            
            // Cleanup function to remove listeners
            return () => {
              progressContainer.removeEventListener('mousemove', updateHoverPreview);
              progressContainer.removeEventListener('mouseleave', resetHoverPreview);
            };
          }
        }, 200); // Short delay to ensure UI is ready
      };
      
      // Call the setup function and store the cleanup function
      const cleanupHoverPreview = setupHoverPreviewBar();
      
      // Make sure to call cleanupHoverPreview in the player.on('destroy') event
      player.on('destroy', () => {
        // Clean up hover preview
        if (cleanupHoverPreview) cleanupHoverPreview();
      });
    };

    // Only initialize player if we're not MKTV or if ad is complete
    if (!isMktv || adComplete) {
      const timer = setTimeout(() => {
        initializePlayer();
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [isMktv, isDirect, hoster?.embedUrl, playerRef, video, handleContextMenu, adComplete]);

  const handleAdComplete = useCallback(() => {
    setIsAdPlaying(false);
    setAdComplete(true);
  }, []);

  useEffect(() => {
    const handleWaiting = () => setIsBuffering(true);
    const handlePlaying = () => setIsBuffering(false);
    const currentVideoRef = videoRef.current;

    if (isMktv && currentVideoRef) {
      currentVideoRef.addEventListener('waiting', handleWaiting);
      currentVideoRef.addEventListener('playing', handlePlaying);
    }

    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener('waiting', handleWaiting);
        currentVideoRef.removeEventListener('playing', handlePlaying);
      }
    };
  }, [isMktv]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || (!isDirect && !isMktv)) return;

    const updateBufferProgress = () => {
      const duration = video.duration;
      if (duration > 0) {
        const buffered = video.buffered;
        if (buffered.length > 0) {
          const bufferEnd = buffered.end(buffered.length - 1);
          const bufferPercentage = (bufferEnd / duration) * 100;
          
          const bufferElements = document.querySelectorAll('.plyr__progress__buffer');
          bufferElements.forEach(element => {
            element.style.width = `${bufferPercentage}%`;
          });
        }
      }
    };

    video.addEventListener('progress', updateBufferProgress);
    return () => video.removeEventListener('progress', updateBufferProgress);
  }, [isDirect, isMktv]);

  // Add effect to reset speed when player state changes
  useEffect(() => {
    const handlePause = () => {
      // If paused while fast-forwarding, reset speed
      if (fastForwardModeRef.current && mktvPlayerRef.current) {
        mktvPlayerRef.current.speed = previousPlaybackRate.current;
        setIsFastForwarding(false);
        fastForwardModeRef.current = false;
      }
    };

    const handlePlay = () => {
      // When playing resumes, ensure we're at the correct speed
      // if not actively fast-forwarding
      if (!fastForwardModeRef.current && mktvPlayerRef.current && 
          mktvPlayerRef.current.speed === 2 && previousPlaybackRate.current !== 2) {
        mktvPlayerRef.current.speed = previousPlaybackRate.current;
      }
    };

    const currentVideoRef = videoRef.current;
    if (currentVideoRef) {
      currentVideoRef.addEventListener('pause', handlePause);
      currentVideoRef.addEventListener('play', handlePlay);
    }

    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener('pause', handlePause);
        currentVideoRef.removeEventListener('play', handlePlay);
      }
    };
  }, []);

  // Handle click outside to close context menu - modified to use document event
  useEffect(() => {
    if (!showContextMenu) return;
    
    const handleClickOutside = (e) => {
      // Don't close if clicking inside the menu
      if (e.target.closest('.custom-context-menu')) {
        return;
      }
      setShowContextMenu(false);
    };
    
    // Use mousedown for better cross-browser compatibility
    document.addEventListener('mousedown', handleClickOutside, true);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [showContextMenu]);

  // Handle repeat mode
  useEffect(() => {
    const currentVideoRef = videoRef.current;
    const player = mktvPlayerRef.current;
    
    console.log("Repeat mode effect running, isRepeatEnabled:", isRepeatEnabled); // Debug log
    
    // Apply repeat mode setting to both video element and player instance
    if (currentVideoRef) {
      currentVideoRef.loop = isRepeatEnabled;
      console.log("Set video.loop =", isRepeatEnabled); // Debug log
    }
    
    if (player) {
      try {
        player.loop = isRepeatEnabled;
        console.log("Set player.loop =", isRepeatEnabled); // Debug log
      } catch (e) {
        console.error("Failed to set player loop:", e);
      }
    }
    
    // Additionally handle the 'ended' event as backup for some browsers
    const handleVideoEnded = () => {
      console.log("Video ended, isRepeatEnabled:", isRepeatEnabled); // Debug log
      if (isRepeatEnabled && currentVideoRef) {
        console.log("Restarting video due to repeat mode"); // Debug log
        currentVideoRef.currentTime = 0;
        
        // Use a small timeout to ensure proper restart
        setTimeout(() => {
          const playPromise = currentVideoRef.play();
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.error("Failed to auto-restart video:", error);
            });
          }
        }, 50);
      }
    };
    
    if (currentVideoRef) {
      currentVideoRef.addEventListener('ended', handleVideoEnded);
    }
    
    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [isRepeatEnabled]);

  return (
    <div key={`player-container-${selectedHoster}-${hoster?.embedUrl}`} className="custom-player-container">
      <Box 
        className={`custom-player${(!isDirect && !isMktv) ? ' custom-player-iframe' : ''}`}
        // Add an additional event listener directly to the Box component
        onContextMenu={isDirect || isMktv ? handleContextMenu : undefined}
      >
        {!isDirect && !isMktv ? (
          <iframe
            key={`iframe-${selectedHoster}-${hoster?.embedUrl}`}
            title="Video Player"
            src={hoster?.embedUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            playsInline
            webkit-playsinline="true"
          />
        ) : (
          <>
            <video
              key={`video-${hoster?.embedUrl}`}
              ref={videoRef}
              style={{ width: '100%', height: '100%' }}
              playsInline
            />
            {isMktv && !adComplete && (
              <VideoAdsManager
                onAdComplete={handleAdComplete}
                videoRef={videoRef}
              />
            )}
            {showPlayIcon && (
              <Box className="player-state-overlay show-animation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M8 5v14l11-7z"/>
                </svg>
              </Box>
            )}
            {showPauseIcon && (
              <Box className="player-state-overlay show-animation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                </svg>
              </Box>
            )}
            {/* Add loop icon animation */}
            {showLoopIcon && (
              <Box className="player-state-overlay show-animation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/>
                </svg>
              </Box>
            )}
            {/* Add copy icon animation */}
            {showCopyIcon && (
              <Box className="player-state-overlay show-animation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                </svg>
              </Box>
            )}
            {/* Fast-forward indicator */}
            <Box 
              className={`fast-forward-indicator ${isFastForwarding ? 'visible' : ''}`}
            >
              2x Speed
            </Box>
            {isBuffering && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10,
                  borderRadius: '50%',
                  padding: 2
                }}
              >
                <CircularProgress 
                  sx={{ 
                    color: 'white',
                    '& .MuiCircularProgress-svg': {
                      transform: 'scale(1.5)'
                    }
                  }} 
                />
              </Box>
            )}
            {/* Custom Context Menu - improved event handling */}
            {showContextMenu && (
              <div 
                className="custom-context-menu"
                style={{
                  top: `${contextMenuPosition.y}px`,
                  left: `${contextMenuPosition.x}px`,
                }}
                onClick={(e) => e.stopPropagation()} // Prevent menu from closing when clicked
              >
                <div 
                  className="context-menu-item"
                  onClick={handleToggleRepeat}
                  onMouseDown={(e) => e.stopPropagation()} // Important: prevent mousedown propagation
                >
                  {isRepeatEnabled ? '✓ ' : ''}{t('Loop Video')}
                </div>
                <div 
                  className="context-menu-item"
                  onClick={handleCopyVideoUrl}
                  onMouseDown={(e) => e.stopPropagation()} // Important: prevent mousedown propagation
                >
                  {t('Copy Video URL')}
                </div>
              </div>
            )}
            
            {/* Copied message notification */}
            {showCopiedMessage && (
              <div className="copied-notification">
                {t('URL copied to clipboard')}
              </div>
            )}
          </>
        )}
      </Box>
      <Box
        component="ul"
        sx={{
          pl: 3,
          listStyle: 'none',
          '& li': {
            mb: 2,
            '&:last-child': {
              mb: 0
            }
          }
        }}
      />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.video.id === nextProps.video.id &&
         prevProps.selectedHoster === nextProps.selectedHoster &&
         prevProps.hoster?.embedUrl === nextProps.hoster?.embedUrl;
});

export default VideoPlayer;