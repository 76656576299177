import React from 'react';
import { useState, useEffect } from 'react';
import { 
  Box,
  Grid, 
  Typography,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Tabs, 
  Tab, // Add this import
  FormControl, // Add this import
  InputLabel, // Add this import
  Select, // Add this import
  MenuItem, // Add this import
  Popover, // Add this import
  Chip, // Add this import
  IconButton, // Add this import
  Stack, // Add this import
  TextField, // Add this import
  InputAdornment // Add this import
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList'; // Add this import
import SearchIcon from '@mui/icons-material/Search'; // Add this import
import ClearIcon from '@mui/icons-material/Clear'; // Add this import
import { useParams, useNavigate } from 'react-router-dom'; // Add useNavigate
import { useTranslation } from 'react-i18next'; // Add this import
import VideoGrid from '../common/VideoGrid';
import GalleryGrid from '../common/GalleryGrid';
import { Helmet } from 'react-helmet-async';
import OnlyFansTimeline from '../common/OnlyFansTimeline';
import { WEBSITE_NAME, getThumbnailPath } from "../App";
import { getLocale } from '../i18n/locales';
import { getVideos, getActors, getGalleries, getOnlyFans, getCategories } from '../utils/api'; // Add getCategories

function ActorDetailPage() {
  const { t } = useTranslation(); // Add translation hook
  const navigate = useNavigate(); // Add this line
  const [actor, setActor] = useState(null);
  const [videos, setVideos] = useState([]);
  const [showFullBio, setShowFullBio] = useState(false);
  const [allActors, setAllActors] = useState([]);
  const [page, setPage] = useState(1);
  const { actorSlug, lng, tab, pageParam } = useParams(); // Add pageParam
  const [onlyFansPosts, setOnlyFansPosts] = useState([]);

  // Set the selected tab based on URL param or default to "videos"
  const [selectedTab, setSelectedTab] = useState(tab || 'videos');
  const [galleries, setGalleries] = useState([]);
  const [videoSort, setVideoSort] = useState('newest'); // Default sort by newest first
  const [selectedCategories, setSelectedCategories] = useState([]); // Add this state
  const [availableCategories, setAvailableCategories] = useState([]); // Add this state
  const [filterAnchorEl, setFilterAnchorEl] = useState(null); // Add this state
  const [categorySearch, setCategorySearch] = useState(''); // Add this state

  // Update the selected tab when the URL parameter changes
  useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use Promise.all with the API functions
        const [actorsData, videosData, galleriesData, onlyFansData] = await Promise.all([
          getActors(),
          getVideos(),
          getGalleries(),
          getOnlyFans()
        ]);

        // Find the actor by slug
        const foundActor = actorsData.find(a => 
          a.name.toLowerCase().replace(/[^a-z0-9]+/g, '-') === actorSlug
        );
        // Add the getThumbnailPath for actor's photo
        setActor({
          ...foundActor,
          photo: getThumbnailPath(foundActor.photo)
        });
        setAllActors(actorsData);

        // Filter actor's videos and update thumbnails
        const actorVideos = videosData
          .filter(video => video.actors.includes(foundActor.id))
          .map(video => ({
            ...video,
            thumbnail: getThumbnailPath(video.thumbnail)
          }));
        setVideos(actorVideos);

        // Filter actor's galleries and update thumbnails
        const actorGalleries = galleriesData
          .filter(gallery => gallery.actors.includes(foundActor.id))
          .map(gallery => ({
            ...gallery,
            thumbnail: getThumbnailPath(gallery.thumbnail)
          }));
        setGalleries(actorGalleries);

        // Filter actor's OnlyFans posts
        const actorPosts = onlyFansData.posts.filter(post =>
          post.user_id === foundActor.id
        );
        setOnlyFansPosts(actorPosts);

        // Fetch categories and set available categories
        const categories = await getCategories();
        const actorCategories = new Set();
        actorVideos.forEach(video => {
          video.categories?.forEach(category => {
            actorCategories.add(category);
          });
        });
        setAvailableCategories([...actorCategories].sort());

      } catch (error) {
        console.error('Error loading actor data:', error);
        // Set empty states on error
        setActor(null);
        setAllActors([]);
        setVideos([]);
        setGalleries([]);
        setOnlyFansPosts([]);
      }
    };

    fetchData();
  }, [actorSlug]);

  // When component mounts, set the page from URL if available
  useEffect(() => {
    if (pageParam && !isNaN(parseInt(pageParam))) {
      setPage(parseInt(pageParam));
    } else {
      setPage(1); // Reset to page 1 if no valid page in URL
    }
  }, [pageParam, tab]); // Re-run when tab or page in URL changes

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
    
    // Update URL to include current tab and page
    navigate(`/${lng}/actor/${actorSlug}/${selectedTab}/${value}`);
  };

  // Update tab change handler to navigate to the appropriate URL
  const handleTabChange = (event, newValue) => {
    setPage(1); // Reset page when switching tabs
    setSelectedTab(newValue);
    
    // Update the URL to include the tab but reset the page
    navigate(`/${lng}/actor/${actorSlug}/${newValue}/1`);
  };

  // If we're on the base actor URL without a tab, redirect to /videos tab
  useEffect(() => {
    if (actor && !tab) {
      navigate(`/${lng}/actor/${actorSlug}/videos/1`, { replace: true });
    }
  }, [actor, tab, navigate, lng, actorSlug]);

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInDays = Math.floor(diffInSeconds / 86400);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  // First add the age calculation function if not already present
  const calculateAge = (birthDate) => {
    if (!birthDate) return null;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  // Add sorting function
  const getSortedVideos = () => {
    let sortedVideos = [...videos];
    
    // Apply category filter
    if (selectedCategories.length > 0) {
      sortedVideos = sortedVideos.filter(video => 
        selectedCategories.every(category => video.categories?.includes(category))
      );
    }

    // Apply sort
    switch (videoSort) {
      case 'views':
        return sortedVideos.sort((a, b) => {
          const viewsA = JSON.parse(localStorage.getItem('videoViews') || '{}')[a.id] || 0;
          const viewsB = JSON.parse(localStorage.getItem('videoViews') || '{}')[b.id] || 0;
          return viewsB - viewsA;
        });
      case 'newest':
        return sortedVideos.sort((a, b) => 
          new Date(b.uploadDate) - new Date(a.uploadDate)
        );
      case 'oldest':
        return sortedVideos.sort((a, b) => 
          new Date(a.uploadDate) - new Date(b.uploadDate)
        );
      case 'relevance':
      default:
        return sortedVideos.sort((a, b) => a.id - b.id);
    }
  };

  // Add filter handlers
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev => {
      if (prev.includes(category)) {
        return prev.filter(c => c !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  // Add search handler
  const handleCategorySearch = (event) => {
    setCategorySearch(event.target.value);
  };

  // Filter categories based on search
  const filteredCategories = availableCategories.filter(category =>
    category.toLowerCase().includes(categorySearch.toLowerCase())
  );

  if (!actor) return <Typography>{t('common.loading')}</Typography>;

  return (
    <Box sx={{ pt: 2 }}>
      <Helmet>
        <title>{t('meta.actor.title', { name: actor.name })}</title>
        <meta name="description" content={
          (actor.bio ? actor.bio : t('meta.actor.description', { name: actor.name }))
            .substring(0, 150) + '...'
        } />
        
        {/* Open Graph tags */}
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:title" content={t('meta.actor.title', { name: actor.name })} />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={`${window.location.origin}${actor.photo}`} />
        <meta property="og:description" content={
          (actor.bio ? actor.bio : t('meta.actor.description', { name: actor.name }))
            .substring(0, 150) + '...'
        } />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('meta.actor.title', { name: actor.name })} />
        <meta name="twitter:description" content={
          (actor.bio ? actor.bio : t('meta.actor.description', { name: actor.name }))
            .substring(0, 150) + '...'
        } />
        <meta name="twitter:image" content={`${window.location.origin}${actor.photo}`} />

        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            "name": actor.name,
            "description": (actor.bio ? actor.bio : t('meta.actor.description', { name: actor.name }))
              .substring(0, 150) + '...',
            "image": `${window.location.origin}${actor.photo}`,
            "birthDate": actor.birthDate || undefined,
            "birthPlace": actor.birthPlace || undefined,
            "height": actor.height || undefined,
            "gender": actor.gender === 1 ? "Female" : "Male"
          })}
        </script>
      </Helmet>
      <Grid container spacing={4}>
        {/* Actor Info Panel - Make it sticky */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              position: { md: 'sticky' }, // Nur auf Desktop sticky
              top: { md: 24 }, // Abstand von oben auf Desktop
            }}
          >
            <Card>
              <CardMedia
                component="img"
                image={actor.photo}
                alt={actor.name}
                sx={{
                  width: '100%',
                  aspectRatio: '3/4',
                  objectFit: 'cover'
                }}
              />
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  {actor.name}
                </Typography>
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="body1" gutterBottom>
                  <strong>{t('actors.birthDate')}:</strong>{' '}
                  {actor.birthDate ? 
                    `${new Date(actor.birthDate).toLocaleDateString(lng === 'de' ? 'de-DE' : 'en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })} (${calculateAge(actor.birthDate)} ${t('actors.yearsOld')})` : 
                    t('actors.noInformation')}
                </Typography>
                
                {actor.birthPlace && (
                  <Typography variant="body1" gutterBottom>
                    <strong>{t('actors.birthPlace')}:</strong> {actor.birthPlace}
                  </Typography>
                )}
                
                {actor.height && (
                  <Typography variant="body1" gutterBottom>
                    <strong>{t('actors.height')}:</strong> {actor.height}
                  </Typography>
                )}
                
                {actor.measurements && (
                  <Typography variant="body1" gutterBottom>
                    <strong>{t('actors.measurements')}:</strong> {actor.measurements}
                  </Typography>
                )}
                
                {actor.yearsActive && (
                  <Typography variant="body1" gutterBottom>
                    <strong>{t('actors.yearsActive')}:</strong> {actor.yearsActive}
                  </Typography>
                )}
                
                {actor.bio && (
                  <>
                    <Divider sx={{ my: 2 }} />
                    <Typography 
                      variant="body1"
                      sx={{ 
                        display: '-webkit-box',
                        WebkitLineClamp: showFullBio ? 'unset' : 4,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        mb: 1
                      }}
                    >
                      {actor.bio}
                    </Typography>
                    
                    <Typography
                      variant="button"
                      component="button"
                      onClick={() => setShowFullBio(!showFullBio)}
                      sx={{
                        background: 'none',
                        border: 'none',
                        color: 'primary.main',
                        cursor: 'pointer',
                        p: 0,
                        '&:hover': {
                          color: 'primary.light',
                        }
                      }}
                    >
                      {showFullBio ? t('common.showLess') : t('common.showMore')}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>

        {/* Content Grid - Right Side */}
        <Grid item xs={12} md={8}>
          <Tabs 
            value={selectedTab} 
            onChange={handleTabChange}
            sx={{
              mb: 3,
              borderBottom: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                color: 'text.secondary',
                '&.Mui-selected': {
                  color: 'primary.main'
                }
              }
            }}
          >
            <Tab 
              value="videos" 
              label={t('actors.videosTab', { count: videos.length })}
              id="videos-tab"
              aria-controls="videos-panel"
            />
            <Tab 
              value="galleries" 
              label={t('actors.galleriesTab', { count: galleries.length })}
              id="galleries-tab"
              aria-controls="galleries-panel"
            />
            <Tab 
              value="onlyfans" 
              label={t('actors.onlyFansTab', { count: onlyFansPosts.length })}
              id="onlyfans-tab"
              aria-controls="onlyfans-panel"
            />
          </Tabs>


          {selectedTab === 'videos' && (
            videos.length > 0 ? (
              <>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2
                }}>
                  <Typography 
                    variant="h6" 
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                      mr: 2
                    }}
                  >
                    {t('actors.videosFeaturing', { name: actor.name })}
                  </Typography>
                  <FormControl size="small" sx={{ minWidth: 200, flexShrink: 0 }}>
                    <InputLabel id="video-sort-label">{t('common.sortBy')}</InputLabel>
                    <Select
                      labelId="video-sort-label"
                      value={videoSort}
                      label={t('common.sortBy')}
                      onChange={(e) => setVideoSort(e.target.value)}
                    >
                      <MenuItem value="relevance">{t('common.sortOptions.relevance')}</MenuItem>
                      <MenuItem value="views">{t('common.sortOptions.mostViewed')}</MenuItem>
                      <MenuItem value="newest">{t('common.sortOptions.newest')}</MenuItem>
                      <MenuItem value="oldest">{t('common.sortOptions.oldest')}</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton 
                    onClick={handleFilterClick}
                    color={selectedCategories.length > 0 ? "primary" : "default"}
                    sx={{ ml: 1 }}
                  >
                    <FilterListIcon />
                  </IconButton>
                  <Popover
                    open={Boolean(filterAnchorEl)}
                    anchorEl={filterAnchorEl}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Box sx={{ p: 2, maxWidth: 400 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        {t('common.filterByCategory')}
                      </Typography>
                      {/* Add search input */}
                      <Box sx={{ mb: 2 }}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder={t('common.searchCategories')}
                          value={categorySearch}
                          onChange={handleCategorySearch}
                          sx={{ mb: 1 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                              </InputAdornment>
                            ),
                            ...(categorySearch && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    onClick={() => setCategorySearch('')}
                                    edge="end"
                                  >
                                    <ClearIcon fontSize="small" />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }),
                          }}
                        />
                      </Box>
                      <Stack 
                        direction="row" 
                        spacing={1} 
                        sx={{ 
                          flexWrap: 'wrap', 
                          gap: 1,
                          maxHeight: 200,
                          overflow: 'auto'
                        }}
                      >
                        {filteredCategories.map(category => (
                          <Chip
                            key={category}
                            label={category}
                            onClick={() => handleCategoryToggle(category)}
                            color={selectedCategories.includes(category) ? "primary" : "default"}
                            variant={selectedCategories.includes(category) ? "filled" : "outlined"}
                          />
                        ))}
                      </Stack>
                      {filteredCategories.length === 0 && (
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ 
                            textAlign: 'center',
                            mt: 2 
                          }}
                        >
                          {t('common.noMatchingCategories')}
                        </Typography>
                      )}
                    </Box>
                  </Popover>
                </Box>
                {selectedCategories.length > 0 && (
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                      {selectedCategories.map(category => (
                        <Chip
                          key={category}
                          label={category}
                          onDelete={() => handleCategoryToggle(category)}
                          color="primary"
                        />
                      ))}
                    </Stack>
                  </Box>
                )}
                <VideoGrid
                  videos={getSortedVideos()}
                  actors={allActors}
                  page={page}
                  onPageChange={handlePageChange}
                  videosPerPage={4}
                  gridItemProps={{
                    xs: 12,
                    sm: 6,
                    md: 6
                  }}
                  lng={lng}
                  getTimeAgo={getTimeAgo}
                />
              </>
            ) : (
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 200,
                  bgcolor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: 1
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  {t('actors.noVideos', { name: actor.name })}
                </Typography>
              </Box>
            )
          )}

          {/* Galleries Panel */}
          {selectedTab === 'galleries' && (
            galleries.length > 0 ? (
              <GalleryGrid 
                galleries={galleries}
                actors={allActors}
                lng={lng}
                gridItemProps={{ xs: 12, sm: 6 }}
                page={page} // Add current page
                onPageChange={handlePageChange} // Add page change handler
                galleriesPerPage={12} // Add items per page
                showPagination={true} // Enable pagination
                infiniteScroll={false} // Disable infinite scroll
              />
            ) : (
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 200,
                  bgcolor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: 1
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  {t('actors.noGalleries', { name: actor.name })}
                </Typography>
              </Box>
            )
          )}

          {/* OnlyFans Panel */}
          {selectedTab === 'onlyfans' && (
            onlyFansPosts.length > 0 ? (
              <OnlyFansTimeline 
                posts={[...onlyFansPosts]
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map(post => ({
                    ...post,
                    thumbnail: post.thumbnail ? getThumbnailPath(post.thumbnail) : undefined,
                    images: post.images?.map(img => getThumbnailPath(img))
                  }))}
                actors={allActors}
                lng={lng}
              />
            ) : (
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 200,
                  bgcolor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: 1
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  {t('actors.noOnlyFansPosts', { name: actor.name })}
                </Typography>
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ActorDetailPage;