// src/components/Navbar.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Box, 
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Divider,
  Tooltip
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PeopleIcon from '@mui/icons-material/People';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CategoryIcon from '@mui/icons-material/Category';
import CollectionsIcon from '@mui/icons-material/Collections';
import SearchIcon from '@mui/icons-material/Search';
import LoyaltyIcon from '@mui/icons-material/Loyalty'; // This will be used for categories
import SearchBar from './SearchBar';
import { WEBSITE_NAME } from '../App';
import { DRAWER_WIDTH } from '../App';
import HistoryIcon from '@mui/icons-material/History';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import adsConfig from '../config/adsConfig';
import LaunchIcon from '@mui/icons-material/Launch';

function Navbar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useParams();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [languageMenu, setLanguageMenu] = useState(null);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const [sidebarAdInitialized, setSidebarAdInitialized] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl')); // For sidebar only
  const isHoverEnabled = useMediaQuery(theme.breakpoints.up('md')); // Enable hover only on medium and larger screens

  const handleLanguageClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageMenu(null);
  };

  // Add with other useEffect hooks
  useEffect(() => {
    return () => {
      setSidebarAdInitialized(false);
    };
  }, []);

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    navigate(`/${newLang}${window.location.pathname.substring(3)}`);
    handleLanguageClose();
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMobileOpen(open);
  };

  const isActiveRoute = (path) => {
    // Get the current path without the language prefix
    const currentPathWithoutLang = location.pathname.substring(4); // Skip /{lng}/
    const pathWithoutLang = path.substring(4); // Skip /{lng}/
    
    // For home page, only match exact root path
    if (pathWithoutLang === '') {
      return currentPathWithoutLang === ''; // Only true if exactly at root
    }
    
    // For other pages, check if current path starts with the menu item path
    return currentPathWithoutLang.startsWith(pathWithoutLang) && pathWithoutLang !== '';
  };

  const handleTitleClick = (e) => {
    e.preventDefault();
    navigate(`/${lng}`);
  };

  const menuItems = [
    { text: t('nav.home'), icon: <HomeIcon />, path: `/${lng}` },
    { text: t('nav.videos'), icon: <VideoLibraryIcon />, path: `/${lng}/videos` },
    { text: t('nav.actors'), icon: <PeopleIcon />, path: `/${lng}/actors` },
    { text: t('nav.categories'), icon: <LoyaltyIcon />, path: `/${lng}/categories` }, // Using OnlyFans' old icon
    { text: t('nav.galleries'), icon: <CollectionsIcon />, path: `/${lng}/galleries` },
    { 
      text: t('nav.onlyfans'), 
      icon: (
        <Box
          component="img"
          src="/images/onlyfans_icon.svg"
          alt="OnlyFans"
          sx={{ 
            width: 24,
            height: 24
          }}
        />
      ), 
      path: `/${lng}/onlyfans` 
    },
    { text: t('nav.watchLater'), icon: <WatchLaterIcon />, path: `/${lng}/watch-later` },
    { text: t('nav.history'), icon: <HistoryIcon />, path: `/${lng}/history` }, // Add this line
    { 
      text: t('nav.favorites'),
      icon: <FavoriteIcon />,
      path: `/${lng}/favorites`
    },
    {
      text: 'ThePornDude.com',
      icon: (
        <Box
          component="img"
          src="/images/tpd.svg"
          alt="ThePornDude"
          sx={{ width: 24, height: 24 }}
        />
      ),
      path: 'https://theporndude.com/',
      isExternal: true
    },
    {
      text: 'ThePornDude.vip',
      icon: (
        <Box
          component="img"
          src="/images/tpdvip.png"
          alt="ThePornDude"
          sx={{ width: 24, height: 24 }}
        />
      ),
      path: 'https://theporndude.vip/',
      isExternal: true
    }
  ];

  function AdBox() {
    if (!adsConfig.isAdsEnabled()) {
      return null;
    }
  
    return (
      <Box
        sx={{
          width: '300px',
          height: { xs: '100px', md: '250px' },
          mx: 'auto',
          mt: 2,
          mb: 2,
          bgcolor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: 1,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative', // Add this
          overflow: 'hidden'    // Add this
        }}
      >
        <Box 
          ref={(el) => {
            if (!el || sidebarAdInitialized) return;
            
            // Cleanup old ad scripts first
            document.querySelectorAll('script[data-ad-script="sidebar-provider"]').forEach(script => script.remove());
            document.querySelectorAll('script[data-ad-script="sidebar-initializer"]').forEach(script => script.remove());
            document.querySelectorAll('ins.eas6a97888e2, ins.eas6a97888e10').forEach(ins => ins.remove());
            
            el.innerHTML = '';
            
            // Add provider script
            const providerScript = document.createElement('script');
            providerScript.src = 'https://a.magsrv.com/ad-provider.js';
            providerScript.async = true;
            providerScript.setAttribute('data-ad-script', 'sidebar-provider');
            el.appendChild(providerScript);
            
            // Add ad tag with specific styling
            const ins = document.createElement('ins');
            ins.className = isMobile ? 'eas6a97888e10' : 'eas6a97888e2';
            ins.dataset.zoneid = isMobile ? '5533844' : '5514824';
            ins.style.display = 'block';               // Add this
            ins.style.position = 'absolute';           // Add this
            ins.style.top = '50%';                    // Add this
            ins.style.left = '50%';                   // Add this
            ins.style.transform = 'translate(-50%, -50%)'; // Add this
            el.appendChild(ins);
            
            // Add initializer script
            const initScript = document.createElement('script');
            initScript.setAttribute('data-ad-script', 'sidebar-initializer');
            initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
            el.appendChild(initScript);
            
            setSidebarAdInitialized(true);
          }}
          sx={{
            position: 'absolute', // Add this
            width: '100%',       // Add this
            height: '100%'       // Add this
          }}
        />
      </Box>
    );
  }

  const drawer = (
    <Box
      sx={{ 
        width: DRAWER_WIDTH,
        paddingTop: (theme) => `${theme.mixins.toolbar.minHeight}px`,
        backgroundColor: 'background.paper',
        height: '100%',
        overflowX: 'hidden',
        display: 'flex', // Add flex display
        flexDirection: 'column', // Stack children vertically
        '& .MuiListItem-root': {
          borderLeft: '3px solid transparent',
          overflow: 'hidden'
        },
        '& .MuiListItemIcon-root': {
          minWidth: 40,
          color: 'text.secondary'
        }
      }}
    >
      {/* Make List scrollable */}
      <Box sx={{ 
        flex: 1, 
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '4px',
        },
      }}>
        <List sx={{ p: 2 }}>
          {/* Home-Button separat */}
          <ListItem 
            disablePadding 
            sx={{ 
              mt: { xs: 0.5, sm: 1 }, // 8px auf Mobil, 16px auf Desktop
              mb: 2  // Existierender Abstand nach unten bleibt gleich
            }}
          >
            <ListItemButton
              component={Link}
              to={`/${lng}`}
              selected={isActiveRoute(`/${lng}`)}
              onClick={() => setMobileOpen(false)} // Add this line
              sx={{
                borderRadius: 2, // Rundliche Ecken
                textDecoration: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(216, 158, 0, 0.08)',
                },
                ...(isActiveRoute(`/${lng}`) && {
                  backgroundColor: 'rgba(216, 158, 0, 0.12)',
                }),
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={t('nav.home')} />
            </ListItemButton>
          </ListItem>

          <Divider sx={{ mb: 2 }} /> {/* Trennlinie nach Home-Button */}

          {/* Restliche Menüpunkte */}
          {menuItems.slice(1).map((item, index) => (
            <Box key={item.text}> {/* Add container with key */}
              <ListItem 
                disablePadding 
                sx={{ mb: 1 }}
              >
                <ListItemButton
                  component={item.isExternal ? 'a' : Link}
                  to={!item.isExternal ? item.path : undefined}
                  href={item.isExternal ? item.path : undefined}
                  target={item.isExternal ? "_blank" : undefined}
                  rel={item.isExternal ? "nofollow" : undefined}
                  selected={!item.isExternal && isActiveRoute(item.path)}
                  onClick={() => setMobileOpen(false)}
                  sx={{
                    borderRadius: 2,
                    textDecoration: 'none',
                    '&:hover': {
                      backgroundColor: isHoverEnabled ? 
                        (item.text === t('nav.onlyfans') ? 'rgba(68, 144, 193, 0.08)' : 'rgba(216, 158, 0, 0.08)') 
                        : 'inherit',
                    },
                    ...(isActiveRoute(item.path) && {
                      backgroundColor: item.text === t('nav.onlyfans') ? 
                        'rgba(68, 144, 193, 0.12)' : // OnlyFans Blue (#4490c1) with transparency
                        'rgba(216, 158, 0, 0.12)', // Brand color for other menu items
                    }),
                    '&.Mui-selected': {
                      backgroundColor: item.text === t('nav.onlyfans') ? 
                        'rgba(68, 144, 193, 0.12)' : // Match hover color for OnlyFans
                        'rgba(216, 158, 0, 0.12)',
                      '&:hover': {
                        backgroundColor: item.text === t('nav.onlyfans') ? 
                          'rgba(68, 144, 193, 0.16)' : // Slightly darker on hover when selected
                          'rgba(216, 158, 0, 0.16)',
                      }
                    }
                  }}
                >
                  <ListItemIcon>
                    {item.text === t('nav.onlyfans') ? 
                      <Box 
                        component="img" 
                        src="/images/onlyfans_icon.svg" 
                        alt="OnlyFans"
                        sx={{ 
                          width: 24,
                          height: 24,
                          filter: isActiveRoute(item.path) ? 
                            'invert(52%) sepia(65%) saturate(434%) hue-rotate(167deg) brightness(87%) contrast(90%)' 
                            : 'none'
                        }}
                      />
                      : item.icon
                    }
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.text} 
                  />
                  {item.isExternal && (
                    <Box component={LaunchIcon} sx={{ 
                      ml: 1,
                      fontSize: 16,
                      color: 'text.secondary',
                      opacity: 0.7
                    }} />
                  )}
                </ListItemButton>
              </ListItem>
              {(item.text === t('nav.onlyfans') || item.text === t('nav.favorites')) && (
                <Divider sx={{ mb: 2 }} />
              )}
            </Box>
          ))}
        </List>
      </Box>

      {/* Language selector button */}
      <Box 
        sx={{
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.03)', // Slightly lighter background
          borderTop: '1px solid rgba(255, 255, 255, 0.05)', // Subtle separator
          mt: 'auto', // Push to bottom
          display: 'flex',
          alignItems: 'center',
          height: 56
        }}
      >
        <ListItemButton
          onClick={handleLanguageClick}
          sx={{
            mx: 2,
            borderRadius: 2,
            '&:hover': {
              backgroundColor: 'rgba(216, 158, 0, 0.08)',
            }
          }}
        >
          <ListItemIcon>
              <Box 
                component="img"
                src={`/locales/flags/${
                  i18n.language === 'en' ? 'us' : 
                  i18n.language === 'hi' ? 'in' :
                  i18n.language === 'cn' ? 'cn' :
                  i18n.language === 'tw' ? 'tw' :
                  i18n.language === 'ja' ? 'jp' :
                  i18n.language === 'ko' ? 'kr' :
                  i18n.language
                }.svg`}
                alt={i18n.language}
                sx={{ width: 24, height: 24 }}
              />
            </ListItemIcon>
            <Tooltip 
              title={
                i18n.language === 'de' ? 'Deutsch/German' : 
                i18n.language === 'hi' ? 'हिन्दी/Hindi' : 
                i18n.language === 'es' ? 'Español/Spanish' :
                i18n.language === 'fr' ? 'Français/French' :
                i18n.language === 'cn' ? '简体中文/Chinese (Simplified)' :
                i18n.language === 'tw' ? '繁體中文/Chinese (Traditional)' :
                i18n.language === 'ru' ? 'Русский/Russian' :
                i18n.language === 'pt' ? 'Português/Portuguese' :
                i18n.language === 'it' ? 'Italiano/Italian' :
                i18n.language === 'ar' ? 'العربية/Arabic' :
                i18n.language === 'ja' ? '日本語/Japanese' :
                i18n.language === 'ko' ? '한국어/Korean' :
                i18n.language === 'tr' ? 'Türkçe/Turkish' :
                i18n.language === 'nl' ? 'Nederlands/Dutch' :
                'English/English'
              }
              enterDelay={700}
            >
              <ListItemText 
                primary={
                  i18n.language === 'de' ? 'Deutsch/German' : 
                  i18n.language === 'hi' ? 'हिन्दी/Hindi' : 
                  i18n.language === 'es' ? 'Español/Spanish' :
                  i18n.language === 'fr' ? 'Français/French' :
                  i18n.language === 'cn' ? '简体中文/Chinese (Simplified)' :
                  i18n.language === 'tw' ? '繁體中文/Chinese (Traditional)' :
                  i18n.language === 'ru' ? 'Русский/Russian' :
                  i18n.language === 'pt' ? 'Português/Portuguese' :
                  i18n.language === 'it' ? 'Italiano/Italian' :
                  i18n.language === 'ar' ? 'العربية/Arabic' :
                  i18n.language === 'ja' ? '日本語/Japanese' :
                  i18n.language === 'ko' ? '한국어/Korean' :
                  i18n.language === 'tr' ? 'Türkçe/Turkish' :
                  i18n.language === 'nl' ? 'Nederlands/Dutch' :
                  'English/English'
                }
                primaryTypographyProps={{
                  sx: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }
                }}
              />
            </Tooltip>
        </ListItemButton>
      </Box>

      {/* Add after language menu items */}
{adsConfig.isAdsEnabled() && (
  <Box
    className="sidebar-ad-container"
    sx={{
      width: '300px',
      height: { xs: '100px', md: '250px' },
      mx: 'auto',
      bgcolor: 'rgba(0, 0, 0, 0.2)',
      borderRadius: 1,
      border: '1px solid rgba(255, 255, 255, 0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    }}
  >
    <Box ref={(el) => {
      if (!el || sidebarAdInitialized) return;
      
      // Cleanup old ad scripts first
      document.querySelectorAll('script[data-ad-script="sidebar-provider"]').forEach(script => script.remove());
      document.querySelectorAll('script[data-ad-script="sidebar-initializer"]').forEach(script => script.remove());
      document.querySelectorAll('ins.eas6a97888e2, ins.eas6a97888e10').forEach(ins => ins.remove());
      
      // Clear container
      el.innerHTML = '';
      
      // Add provider script
      const providerScript = document.createElement('script');
      providerScript.src = 'https://a.magsrv.com/ad-provider.js';
      providerScript.async = true;
      providerScript.setAttribute('data-ad-script', 'sidebar-provider');
      el.appendChild(providerScript);
      
      // Add ad tag
      const ins = document.createElement('ins');
      ins.className = window.innerWidth < 1140 ? 'eas6a97888e10' : 'eas6a97888e2';
      ins.dataset.zoneid = window.innerWidth < 1140 ? '5533844' : '5514824';
      el.appendChild(ins);
      
      // Add initializer script
      const initScript = document.createElement('script');
      initScript.setAttribute('data-ad-script', 'sidebar-initializer');
      initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
      el.appendChild(initScript);
      
      setSidebarAdInitialized(true);
    }} />
  </Box>
)}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar 
        position="fixed"
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: 'rgba(25, 25, 28, 0.95)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileOpen(!mobileOpen)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          
          {/* Add Box to contain both icon and title */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            position: { xs: 'absolute', md: 'static' }, // Add positioning
            left: { xs: '50%', md: 'auto' }, // Center on mobile
            transform: { xs: 'translateX(-50%)', md: 'none' }, // Center on mobile
          }}>
            <Box
              component={Link}
              to={`/${lng}`}
              onClick={() => setMobileOpen(false)} // Add this line to close mobile sidebar
              sx={{
                height: { xs: 38, md: 42 },
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none'
              }}
            >
              <Box
                component="img"
                src="/navbar-logo.png" 
                sx={{
                  height: '100%',
                  ml: { xs: 1, lg: 0, xl: 5, md: 0 },
                  '&:hover': {
                    filter: 'brightness(1)',
                    transition: 'filter 0.3s ease'
                  }
                }}
                alt={WEBSITE_NAME}
              />
            </Box>
          </Box>

          {/* Search bar container */}
          <Box sx={{ 
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            display: { xs: 'none', md: 'flex' }, // Change xl to md
            width: '100%',
            maxWidth: 500,
            zIndex: 1
          }}>
            <SearchBar lng={lng} isMobile={false} />
          </Box>

          {/* Right-side container */}
          <Box sx={{ 
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center'
          }}>
            {/* Mobile search icon */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 0 }}> {/* Changed mr from 2 to 0 */}
              <IconButton
                color="inherit"
                onClick={() => setMobileSearchOpen(true)}
                size="large"
                sx={{ 
                  padding: 1,  // Increased padding for larger click area
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                <SearchIcon />
              </IconButton>
            </Box>


            {/* Language Menu */}
            <Menu
              anchorEl={languageMenu}
              open={Boolean(languageMenu)}
              onClose={handleLanguageClose}
              sx={{
                mt: 1
              }}
            >
              {[
                { code: 'en', label: 'English/English', flag: 'us' },
                { code: 'de', label: 'Deutsch/German', flag: 'de' },
                { code: 'hi', label: 'हिन्दी/Hindi', flag: 'in' },
                { code: 'es', label: 'Español/Spanish', flag: 'es' },
                { code: 'fr', label: 'Français/French', flag: 'fr' },
                { code: 'cn', label: '简体中文/Chinese (Simplified)', flag: 'cn' },
                { code: 'tw', label: '繁體中文/Chinese (Traditional)', flag: 'tw' },
                { code: 'ru', label: 'Русский/Russian', flag: 'ru' },
                { code: 'pt', label: 'Português/Portuguese', flag: 'pt' },
                // Fügen Sie die fehlenden Sprachen hinzu
                { code: 'it', label: 'Italiano/Italian', flag: 'it' },
                { code: 'ar', label: 'العربية/Arabic', flag: 'ar' },
                { code: 'ja', label: '日本語/Japanese', flag: 'jp' },
                { code: 'ko', label: '한국어/Korean', flag: 'kr' },
                { code: 'tr', label: 'Türkçe/Turkish', flag: 'tr' },
                { code: 'nl', label: 'Nederlands/Dutch', flag: 'nl' }
              ].map(({ code, label, flag }) => (
                <MenuItem 
                  key={code}
                  onClick={() => changeLanguage(code)} 
                  sx={{ 
                    py: 1.5,
                    bgcolor: i18n.language === code ? 'rgba(216, 158, 0, 0.5)' : 'transparent',
                    '&:hover': {
                      bgcolor: i18n.language === code ? 'rgba(216, 158, 0, 0.3)' : 'rgba(255, 255, 255, 0.08)'
                    }
                  }}
                >
                  <Box 
                    component="img" 
                    src={`/locales/flags/${flag}.svg`} 
                    alt={label} 
                    sx={{ width: 24, height: 24, mr: 2 }} 
                  />
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Permanent drawer for desktop */}
      {!isMobile && (
        <Drawer
          variant="permanent"
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
              overflowX: 'hidden' // Add this line
            },
          }}
        >
          {drawer}
        </Drawer>
      )}

      {/* Temporary drawer for mobile */}
      {isMobile && (
        <Drawer
          variant="temporary" 
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
              overflowX: 'hidden' // Add this line
            },
          }}
        >
          {drawer}
        </Drawer>
      )}

      {/* Mobile search drawer */}
      <Drawer
        anchor="top"
        open={mobileSearchOpen}
        onClose={() => setMobileSearchOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            mt: '55px', // Align exactly with AppBar height
            height: 'auto',
            maxHeight: '70vh',
            p: 2,
            bgcolor: 'background.paper',
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02))',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderTop: 'none', // Remove top border to prevent double line
            '& .MuiAutocomplete-listbox': {
              maxHeight: '50vh'
            }
          }
        }}
      >
        <Box sx={{ position: 'relative' }}>


          <SearchBar 
            lng={lng} 
            isMobile={true}
            onClose={() => setMobileSearchOpen(false)}
            autoFocus // Add autoFocus prop
          />
        </Box>
      </Drawer>
    </Box>
  );
}

export default Navbar;