import React from 'react';
import { useState, useEffect } from 'react';
import { 
  Box, 
  ImageList, 
  ImageListItem, 
  IconButton,
  CircularProgress,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Tooltip
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTranslation } from 'react-i18next';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorder';
import ErrorPage from './ErrorPage';
import { useInView } from 'react-intersection-observer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Helmet } from 'react-helmet-async';
import adsConfig from '../config/adsConfig';
import { WEBSITE_NAME } from "../App";
import { getLocale } from '../i18n/locales';
import { getVideos, getActors, getGalleries } from '../utils/api';
import ImageViewer from '../common/ImageViewer';

// Add these helper functions before the GalleryViewPage component
const GRID_SIZE_KEY = 'galleryGridSize';

const getStoredGridSize = () => {
  const stored = localStorage.getItem(GRID_SIZE_KEY);
  return stored ? parseInt(stored) : 4; // Default to 4 if not set
};

const setStoredGridSize = (size) => {
  localStorage.setItem(GRID_SIZE_KEY, size.toString());
};

// Add this helper function
const generateActorSlug = (actor) => {
  return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
};

// First modify the generateGallerySlug helper function to ensure consistent slug generation:
const generateGallerySlug = (title) => {
  if (!title) return '';
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-') 
    .replace(/^-+|-+$/g, '');
};

function GalleryViewPage() {
  const { t } = useTranslation();
  const [gallery, setGallery] = useState(null);
  const [galleryNotFound, setGalleryNotFound] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const { gallerySlug, lng } = useParams();
  const [actors, setActors] = useState([]);
  const navigate = useNavigate();
  // Remove unused state variables or mark them for future use with a comment
  // const [isFullscreen, setIsFullscreen] = useState(false);
  // const [uiVisible, setUiVisible] = useState(true);
  const [gridCols, setGridCols] = useState(getStoredGridSize());
  const [hoveredImage, setHoveredImage] = useState(null);
  // const [isFavorite, setIsFavorite] = useState({});
  const [isGalleryFavorite, setIsGalleryFavorite] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  // const [slideDirection, setSlideDirection] = useState(0);
  
  // Gruppiere Bilder in Chunks für effizienteres Laden
  const IMAGES_PER_CHUNK = 12; // Anzahl Bilder pro Ladevorgang
  const [visibleImages, setVisibleImages] = useState([]);

  // Intersection Observer Hook
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '100px 0px' // Lade etwas früher bevor User scrollt
  });

  // Lade mehr Bilder wenn User scrollt
  useEffect(() => {
    if (inView && visibleImages.length < images.length) {
      const nextChunk = images.slice(
        visibleImages.length,
        visibleImages.length + IMAGES_PER_CHUNK
      );
      setVisibleImages(prev => [...prev, ...nextChunk]);
    }
  }, [inView, images, visibleImages.length]);

  // Initial ersten Chunk laden
  useEffect(() => {
    if (images.length > 0 && visibleImages.length === 0) {
      setVisibleImages(images.slice(0, IMAGES_PER_CHUNK));
    }
  }, [images, visibleImages.length]); // Added visibleImages.length to dependencies

  useEffect(() => {
    if (!gallerySlug) {
      setGalleryNotFound(true);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const [videoData, actorData, galleryData] = await Promise.all([
          getVideos(),
          getActors(),
          getGalleries()
        ]);

        const normalizedUrlSlug = gallerySlug?.toLowerCase()?.trim() || '';
        
        // Find gallery
        const foundGallery = galleryData.find(g => {
          if (!g?.title) return false;
          const gallerySlug = generateGallerySlug(g.title);
          return gallerySlug === normalizedUrlSlug;
        });

        if (!foundGallery) {
          console.error('Gallery not found:', {
            providedSlug: normalizedUrlSlug,
            availableGalleries: galleryData.map(g => ({
              title: g.title,
              slug: generateGallerySlug(g.title)
            }))
          });
          setGalleryNotFound(true);
          setLoading(false);
          return;
        }

        setGallery(foundGallery);
        setActors(actorData);
        
        if (foundGallery) {
          // Find related videos
          const related = videoData.filter(video => 
            foundGallery.relatedVideos?.includes(video.id)
          );
          setRelatedVideos(related);
          
          // Generate image paths with thumb awareness
          const imageList = Array.from({ length: foundGallery.imageCount }, (_, i) => {
            const paddedNumber = String(i + 1).padStart(4, '0');
            const fullPath = `${foundGallery.path}/${paddedNumber}.jpg`;
            
            return {
              src: foundGallery.thumb === false ? 
                fullPath : 
                `${foundGallery.path}/thumb/${paddedNumber}_thumb.jpg`,
              fullSrc: fullPath,
              loading: 'lazy'
            };
          });
          setImages(imageList);
        }
        setLoading(false);

      } catch (error) {
        console.error('Error fetching gallery data:', error);
        setGalleryNotFound(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [gallerySlug]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Add this useEffect inside GalleryViewPage
  useEffect(() => {
    if (gallery) {
      const favoriteGalleries = JSON.parse(localStorage.getItem('favoriteGalleries') || '[]');
      setIsGalleryFavorite(favoriteGalleries.includes(gallery.id));
    }
  }, [gallery]);

  useEffect(() => {
    const handleScroll = () => {
      // Show button when user scrolls down 200px
      setShowScrollTop(window.pageYOffset > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Add this after setting the gallery state
  useEffect(() => {
    if (gallery?.title) {
      // Dispatch custom event to update breadcrumb with proper title
      const breadcrumbEvent = new CustomEvent('updateBreadcrumb', {
        detail: {
          path: 'gallery',
          title: gallery.title // Use actual gallery title instead of slug
        }
      });
      document.dispatchEvent(breadcrumbEvent);
    }
  }, [gallery]);

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handleClose = async () => {
    // First exit fullscreen if active
    if (document.fullscreenElement) {
      try {
        await document.exitFullscreen();
      } catch (err) {
        console.error('Error exiting fullscreen:', err);
      }
    }
    // Then close the modal
    setSelectedImage(null);
  };
  
  const handleVideoClick = (video) => {
    const slug = video.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    navigate(`/${lng}/video/${slug}`);
  };

  const handleFullscreenChange = () => {
    // We've removed the isFullscreen state since it's not used
    // But we keep the function for the event listener
  };

  // Add this function inside GalleryViewPage
  const handleGalleryFavorite = () => {
    const favoriteGalleries = JSON.parse(localStorage.getItem('favoriteGalleries') || '[]');
    
    if (isGalleryFavorite) {
      // Remove from favorites
      const newFavorites = favoriteGalleries.filter(id => id !== gallery.id);
      localStorage.setItem('favoriteGalleries', JSON.stringify(newFavorites));
      setIsGalleryFavorite(false);
    } else {
      // Add to favorites
      favoriteGalleries.push(gallery.id);
      localStorage.setItem('favoriteGalleries', JSON.stringify(favoriteGalleries));
      setIsGalleryFavorite(true);
    }
  
    // Dispatch storage event for other tabs/windows
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'favoriteGalleries',
      newValue: JSON.stringify(favoriteGalleries)
    }));
  };

  const toggleFavorite = (image, event) => {
    event.stopPropagation();
    const favoriteImages = JSON.parse(localStorage.getItem('favoriteImages') || '[]');
    const isCurrentlyFavorite = favoriteImages.includes(image.fullSrc);
    
    let newFavorites;
    if (isCurrentlyFavorite) {
      newFavorites = favoriteImages.filter(img => img !== image.fullSrc);
    } else {
      newFavorites = [...favoriteImages, image.fullSrc];
    }
    
    localStorage.setItem('favoriteImages', JSON.stringify(newFavorites));
  };
  
  const checkIsFavorite = (imageSrc) => {
    const favoriteImages = JSON.parse(localStorage.getItem('favoriteImages') || '[]');
    return favoriteImages.includes(imageSrc);
  };

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
  };

  // Add this at the start of the render logic
  if (galleryNotFound) {
    return <ErrorPage code={404} />;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const AD_INTERVAL = gridCols * 2; // Show ad after every 2 rows


  return (
    <Box sx={{ pt: 2 }}>
      <Helmet>
        <title>
          {gallery?.title}
          {gallery?.actors?.length > 0 
            ? ` - ${actors.find(a => a.id === gallery.actors[0])?.name}`
            : ''
          } - IMAGESET
        </title>
        <meta name="description" content={t('meta.gallery.description', { 
          title: gallery?.title,
          actor: gallery?.actors?.length > 0 ? actors.find(a => a.id === gallery.actors[0])?.name : '',
          count: gallery?.imageCount
        })} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${gallery?.title}${gallery?.actors?.length > 0 ? ` - ${actors.find(a => a.id === gallery.actors[0])?.name}` : ''} - IMAGESET`} />
        <meta property="og:description" content={t('meta.gallery.description', {
          title: gallery?.title,
          actor: gallery?.actors?.length > 0 ? actors.find(a => a.id === gallery.actors[0])?.name : '',
          count: gallery?.imageCount
        })} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}${images[0]?.fullSrc}`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${gallery?.title}${gallery?.actors?.length > 0 ? ` - ${actors.find(a => a.id === gallery.actors[0])?.name}` : ''} - IMAGESET`} />
        <meta name="twitter:description" content={t('meta.gallery.description', {
          title: gallery?.title,
          actor: gallery?.actors?.length > 0 ? actors.find(a => a.id === gallery.actors[0])?.name : '',
          count: gallery?.imageCount
        })} />
        <meta name="twitter:image" content={`${window.location.origin}${images[0]?.fullSrc}`} />

        {/* Schema.org ImageGallery markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ImageGallery",
            "name": gallery?.title,
            "description": t('meta.gallery.description', {
              title: gallery?.title,
              actor: gallery?.actors?.length > 0 ? actors.find(a => a.id === gallery.actors[0])?.name : '',
              count: gallery?.imageCount
            }),
            "numberOfItems": gallery?.imageCount,
            "datePublished": gallery?.date,
            "image": images.slice(0, 5).map(img => `${window.location.origin}${img.fullSrc}`),
            ...(gallery?.actors?.length > 0 && {
              "about": gallery.actors.map(actorId => ({
                "@type": "Person",
                "name": actors.find(a => a.id === actorId)?.name
              }))
            })
          })}
        </script>
      </Helmet>
      {gallery && (
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h4">
              {gallery.title}
            </Typography>
            <Tooltip title={isGalleryFavorite ? t('favorites.remove') : t('favorites.add')} arrow>
              <IconButton
                onClick={handleGalleryFavorite}
                sx={{
                  color: isGalleryFavorite ? 'primary.main' : 'inherit',
                  '&:hover': {
                    backgroundColor: 'rgba(216, 158, 0, 0.08)'
                  }
                }}
              >
                {isGalleryFavorite ? <FavoriteIcon /> : <FavoriteOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </Box>

          {/* Add date display */}
          {gallery.date && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              {new Date(gallery.date).toLocaleDateString(lng === 'de' ? 'de-DE' : 'en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </Typography>
          )}
          
          {/* Actors Section */}
          {gallery.actors?.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                {t('galleryView.featuring')}:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {gallery.actors.map((actorId, index) => {
                  const actor = actors.find(a => a.id === actorId);
                  const slug = generateActorSlug(actor);
                  return (
                    <Box
                      key={actorId}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/${lng}/actor/${slug}`); // Use slug instead of ID
                      }}
                      sx={{
                        color: 'primary.main',
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'primary.light',
                          textDecoration: 'underline',
                        },
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {actor?.name}
                      {index < gallery.actors.length - 1 && (
                        <Typography component="span" sx={{ color: 'text.primary', mx: 0.5 }}>
                          •
                        </Typography>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          <Typography variant="body1" color="text.secondary" gutterBottom>
            {t('galleryView.imageCount', { count: gallery.imageCount })}
          </Typography>
        </Box>
      )}
      
      {/* Controls and metadata bar */}
      <Box 
        sx={{ 
          mb: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 2,
          bgcolor: 'rgba(255, 255, 255, 0.03)',
          p: 2,
          borderRadius: 1
        }}
      >
        {/* Left side - Grid size control */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {t('galleryView.gridSize')}:
          </Typography>
          <ToggleButtonGroup
            value={gridCols}
            exclusive
            onChange={(e, newCols) => {
              if (newCols !== null) {
                setGridCols(newCols);
                setStoredGridSize(newCols);
              }
            }}
            size="small"
          >
            <ToggleButton value={2}>2x</ToggleButton>
            <ToggleButton value={3}>3x</ToggleButton>
            <ToggleButton value={4}>4x</ToggleButton>
            <ToggleButton value={6}>6x</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Right side - Related videos */}
        {relatedVideos.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body2" color="text.secondary">
              {t('galleryView.relatedVideos')}:
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {relatedVideos.map((video) => (
                <Button
                  key={video.id}
                  size="small"
                  startIcon={<PlayArrowIcon sx={{ color: 'primary.main' }} />}
                  onClick={() => handleVideoClick(video)}
                  sx={{
                    textTransform: 'none',
                    color: 'text.primary',
                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                    }
                  }}
                >
                  {video.title}
                </Button>
              ))}
            </Box>
          </Box>
        )}
      </Box>

      <ImageList
        variant="quilted"
        cols={gridCols}
        gap={8}
        sx={{
          '& .MuiImageListItem-root': {
            position: 'relative', // Add this
            '&::before': {
              content: '""',
              paddingTop: '100%',
              display: 'block'
            },
            '& .imageContainer': {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }
          }
        }}
      >
        {visibleImages.map((image, index) => (
          <React.Fragment key={index}>
            <ImageListItem 
              onMouseEnter={() => setHoveredImage(index)}
              onMouseLeave={() => setHoveredImage(null)}
              onClick={() => handleImageClick(index)}
              sx={{ 
                cursor: 'pointer',
                overflow: 'hidden',
                '& img': {
                  transform: 'scale(1)',
                  transition: 'transform 0.3s ease' // Move transition here
                },
                '&:hover img': {
                  transform: 'scale(1.1)',
                  // Remove transition from here since it's now in base styles
                }
              }}
            >
              <div className="imageContainer">
                <LazyLoadImage
                  src={image.src}
                  alt={`Gallery image ${index + 1}`}
                  effect="opacity"
                  style={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Forces image to cover entire container
                    position: 'absolute', // Add this
                    top: '0',           // Add this
                    left: '0',          // Add this
                  }}
                  threshold={100}
                  placeholder={
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      bgcolor: 'rgba(0,0,0,0.1)'
                    }}>
                      <CircularProgress size={40} />
                    </Box>
                  }
                />
              </div>
              
              {/* Favorite Button Overlay */}
              {hoveredImage === index && (
                <IconButton
                  onClick={(e) => toggleFavorite(image, e)}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(0, 0, 0, 6)',
                    color: checkIsFavorite(image.fullSrc) ? 'primary.main' : 'white',
                    display: { xs: 'none', sm: 'flex' }, // Hide on xs (mobile), show on sm and up
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                  }}
                >
                  {checkIsFavorite(image.fullSrc) ? <FavoriteIcon /> : <FavoriteOutlinedIcon />}
                </IconButton>
              )}
            </ImageListItem>

            {/* Insert ad banner after every 2 rows */}
            {adsConfig.isAdsEnabled() && (index + 1) % AD_INTERVAL === 0 && (
              <ImageListItem 
                cols={gridCols}
                sx={{ 
                  height: { 
                    xs: '100px !important', 
                    md: '90px !important'  // Added !important
                  },
                  gridColumn: '1 / -1',
                  my: 2,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box
                  sx={{
                    width: { xs: '300px', md: '728px' },
                    height: { 
                      xs: '100px !important', 
                      md: '90px !important'  // Added !important
                    },
                    bgcolor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: 1,
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden'
                  }}
                >
                  <Box
                    ref={(el) => {
                      if (!el || el.hasAttribute('data-ad-initialized')) return;
                      
                      // Cleanup old ad scripts
                      el.querySelectorAll('script[data-ad-script]').forEach(script => script.remove());
                      el.querySelectorAll('ins.eas6a97888e2, ins.eas6a97888e10').forEach(ins => ins.remove());
                      
                      // Clear container
                      el.innerHTML = '';
                      
                      // Add provider script
                      const providerScript = document.createElement('script');
                      providerScript.src = 'https://a.magsrv.com/ad-provider.js';
                      providerScript.async = true;
                      providerScript.setAttribute('data-ad-script', 'gallery-provider');
                      el.appendChild(providerScript);
                      
                      // Add ad tag
                      const ins = document.createElement('ins');
                      const isMobile = window.innerWidth < 768;
                      ins.className = isMobile ? 'eas6a97888e10' : 'eas6a97888e2';
                      ins.dataset.zoneid = isMobile ? '5533894' : '5533892';
                      el.appendChild(ins);
                      
                      // Add initializer script
                      const initScript = document.createElement('script');
                      initScript.setAttribute('data-ad-script', 'gallery-initializer');
                      initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
                      el.appendChild(initScript);
                      
                      // Mark as initialized
                      el.setAttribute('data-ad-initialized', 'true');
                    }}
                  />
                </Box>
              </ImageListItem>
            )}
          </React.Fragment>
        ))}

        {/* Ladeindikator und Intersection Observer Target */}
        {visibleImages.length < images.length && (
          <Box
            ref={loadMoreRef}
            sx={{
              gridColumn: '1 / -1',
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </ImageList>

      <ImageViewer
        open={selectedImage !== null}
        onClose={handleClose}
        images={images}
        selectedIndex={selectedImage}
        onIndexChange={setSelectedImage}
        t={t}
      />

      {showScrollTop && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            bgcolor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.8)',
            },
            zIndex: 1000,
            transition: 'all 0.3s ease',
            animation: 'fadeIn 0.3s',
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
                transform: 'translateY(20px)'
              },
              '100%': {
                opacity: 1,
                transform: 'translateY(0)'
              }
            }
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default GalleryViewPage;