// src/components/OnlyFansPostPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // Add useLocation
import { Box, Typography, Card, CardContent, Avatar, Button, Modal, List, ListItem, ListItemIcon, ListItemText, IconButton, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Add useTranslation hook
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import XIcon from '@mui/icons-material/Twitter';
import RedditIcon from '@mui/icons-material/Reddit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Add ArrowBackIcon
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { useInView } from 'react-intersection-observer'; // Add this import at the top
import { Helmet } from 'react-helmet-async';
import { WEBSITE_NAME } from '../App';
import { getLocale } from '../i18n/locales';
import adsConfig from '../config/adsConfig';
import { getOnlyFans, getActors } from '../utils/api';
import { getThumbnailPath } from '../App';

// Helper functions (move these BEFORE the component)
const getStoredGridSize = () => {
  const stored = localStorage.getItem('postGridSize');
  return stored ? parseInt(stored) : 3; // Default to 3 columns
};

const setStoredGridSize = (size) => {
  localStorage.setItem('postGridSize', size.toString());
};

function OnlyFansPostPage() {
  const navigate = useNavigate();
  const location = useLocation(); // Add this
  const { actorSlug, postId, lng } = useParams();
  const { t } = useTranslation(); // Add useTranslation hook
  const [post, setPost] = useState(null);
  const [actor, setActor] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [gridCols, setGridCols] = useState(getStoredGridSize()); // Add this state
  const [selectedImage, setSelectedImage] = useState(null); // Add this state
  const IMAGES_PER_CHUNK = 12; // Number of images to load at a time
  const [visibleImages, setVisibleImages] = useState([]);
  const [adInitialized, setAdInitialized] = useState(false);

  // Add intersection observer hook
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '100px 0px' // Load earlier before reaching bottom
  });

  // Check if post is in favorites on mount
  useEffect(() => {
    const favoritesPosts = JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');
    setIsFavorite(favoritesPosts.includes(parseInt(postId)));
  }, [postId]);

  // Handle share modal
  const handleShareClick = (e) => {
    e.preventDefault();
    setShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setShareModalOpen(false);
  };

  // Handle share functions
  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      handleCloseShareModal();
    });
  };

  const handleActorClick = (e) => {
    e.preventDefault();
    navigate(`/${lng}/actor/${actorSlug}`);
  };

  const handleShareX = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`);
    handleCloseShareModal();
  };

  const handleShareReddit = () => {
    window.open(`https://reddit.com/submit?url=${encodeURIComponent(window.location.href)}`);
    handleCloseShareModal();
  };

  // Handle favorite toggle
  const handleFavoriteClick = (e) => {
    e.preventDefault();
    const favoritesPosts = JSON.parse(localStorage.getItem('favoriteOnlyFansPosts') || '[]');
    const postIdNum = parseInt(postId);
    
    let newFavorites;
    if (isFavorite) {
      newFavorites = favoritesPosts.filter(id => id !== postIdNum);
    } else {
      newFavorites = [...favoritesPosts, postIdNum];
    }
    
    localStorage.setItem('favoriteOnlyFansPosts', JSON.stringify(newFavorites));
    setIsFavorite(!isFavorite);

    // Dispatch event for cross-tab updates
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'favoriteOnlyFansPosts',
      newValue: JSON.stringify(newFavorites)
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [postsData, actorsData] = await Promise.all([
          getOnlyFans(),
          getActors()
        ]);

        const foundActor = actorsData.find(a => 
          a.name.toLowerCase().replace(/[^a-z0-9]+/g, '-') === actorSlug
        );

        if (foundActor) {
          const foundPost = postsData.posts.find(p => 
            p.id === parseInt(postId) && p.user_id === foundActor.id
          );
          if (foundPost) {
            // Apply getThumbnailPath to actor photo
            setActor({
              ...foundActor,
              photo: getThumbnailPath(foundActor.photo)
            });
            // Apply getThumbnailPath to post images and thumbnail if they exist
            setPost({
              ...foundPost,
              images: foundPost.images?.map(img => getThumbnailPath(img)),
              thumbnail: foundPost.thumbnail ? getThumbnailPath(foundPost.thumbnail) : undefined
            });
          }
        }
      } catch (error) {
        console.error('Error loading OnlyFans post data:', error);
        setPost(null);
        setActor(null);
      }
    };

    fetchData();
  }, [actorSlug, postId]);

  const handleBackClick = () => {
    // If we have a stored scroll position, navigate back with state
    if (location.state?.from === 'timeline' && location.state?.scrollPosition) {
      navigate(`/${lng}/onlyfans`, { 
        state: { 
          scrollPosition: location.state.scrollPosition 
        }
      });
    } else {
      // If no stored position, just go back to timeline
      navigate(`/${lng}/onlyfans`);
    }
  };

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handlePrevious = () => {
    setSelectedImage((prev) => (prev > 0 ? prev - 1 : post.images.length - 1));
  };

  const handleNext = () => {
    setSelectedImage((prev) => (prev < post.images.length - 1 ? prev + 1 : 0));
  };

  // Initial load of first chunk of images
  useEffect(() => {
    if (post?.images && post.images.length > 0 && visibleImages.length === 0) {
      setVisibleImages(post.images.slice(0, IMAGES_PER_CHUNK));
    }
  }, [post]);

  // Load more images when scrolling
  useEffect(() => {
    if (inView && post?.images && post.images.length > 0 && visibleImages.length < post.images.length) {
      const nextChunk = post.images.slice(
        visibleImages.length,
        visibleImages.length + IMAGES_PER_CHUNK
      );
      setVisibleImages(prev => [...prev, ...nextChunk]);
    }
  }, [inView, post, visibleImages.length]);

  useEffect(() => {
    return () => {
      setAdInitialized(false);
    };
  }, []);

  if (!post || !actor) {
    return <Box sx={{ p: 2 }}>
      <Typography>Post nicht gefunden</Typography>
    </Box>;
  }

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 2 }}>
      <Helmet>
        <title>{`${post.text 
          ? t('meta.onlyFans.post.titleWithText', {
              text: post.text.substring(0, 60),
              name: actor.name
            }) 
          : t('meta.onlyFans.post.titleNoText', {
              name: actor.name
            })} - ${WEBSITE_NAME}`}</title>
        <meta name="description" content={t('meta.onlyFans.post.description', {
          text: post.text,
          name: actor.name
        })} />
        <meta name="author" content={actor.name} />
        <meta name="date" content={post.date} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${post.text 
          ? t('meta.onlyFans.post.titleWithText', {
              text: post.text.substring(0, 60),
              name: actor.name
            })
          : t('meta.onlyFans.post.titleNoText', {
              name: actor.name
            })} - ${WEBSITE_NAME}`} />
        <meta property="og:description" content={t('meta.onlyFans.post.description', {
          text: post.text,
          name: actor.name
        })} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={getLocale(lng)} />
        <meta property="og:site_name" content={WEBSITE_NAME} />
        <meta property="og:image" content={`${window.location.origin}${post.images?.[0] || post.thumbnail || '/images/og-image.png'}`} />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content={post.images?.[0] || post.thumbnail ? "summary_large_image" : "summary"} />
        <meta name="twitter:title" content={`${post.text 
          ? t('meta.onlyFans.post.titleWithText', {
              text: post.text.substring(0, 60),
              name: actor.name
            })
          : t('meta.onlyFans.post.titleNoText', {
              name: actor.name
            })} - ${WEBSITE_NAME}`} />
        <meta name="twitter:description" content={t('meta.onlyFans.post.description', {
          text: post.text,
          name: actor.name
        })} />
        <meta name="twitter:image" content={`${window.location.origin}${post.images?.[0] || post.thumbnail || '/images/og-image.png'}`} />
        <meta name="twitter:creator" content={`@${actor.name.replace(/\s+/g, '')}`} />

        {/* Schema.org SocialMediaPosting markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SocialMediaPosting",
            "headline": t('meta.onlyFans.post.schemaHeadline', {
              text: post.text?.substring(0, 110),
              name: actor.name
            }),
            "datePublished": post.date,
            "text": post.text,
            "author": {
              "@type": "Person",
              "name": actor.name,
              "url": `${window.location.origin}/${lng}/actor/${actorSlug}`
            },
            "publisher": {
              "@type": "Organization",
              "name": "OnlyFans",
              "logo": {
                "@type": "ImageObject",
                "url": `${window.location.origin}/images/onlyfans_approved.svg`
              }
            },
            "sharedContent": [
              ...(post.images?.map(img => ({
                "@type": "ImageObject",
                "url": `${window.location.origin}${img}`
              })) || []),
              ...(post.videos?.map(video => ({
                "@type": "VideoObject",
                "name": post.text || `${actor.name}'s OnlyFans Video`,
                "description": post.text || `OnlyFans video post by ${actor.name}`,
                "uploadDate": post.date,
                "thumbnailUrl": post.thumbnail 
                  ? `${window.location.origin}${post.thumbnail}` 
                  : `${window.location.origin}${post.images?.[0] || '/images/video-placeholder.jpg'}`,
                "url": `${window.location.origin}${video}`,
                "contentUrl": `${window.location.origin}${video}`,
                "author": {
                  "@type": "Person",
                  "name": actor.name,
                  "url": `${window.location.origin}/${lng}/actor/${actorSlug}`
                }
              })) || [])
            ],
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": window.location.href
            }
          })}
        </script>
      </Helmet>
      {/* Add Back Button */}
      <Button
        onClick={handleBackClick}
        sx={{
          mb: 2,
          color: '#4dadea',
          textTransform: 'none',
          '&:hover': {
            bgcolor: 'rgba(77, 173, 234, 0.08)'
          }
        }}
        startIcon={<ArrowBackIcon />}
      >
        {t('onlyFans.backToTimeline')}
      </Button>

      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box
              component="a"
              href={`/${lng}/actor/${actorSlug}`}
              onClick={handleActorClick}
              sx={{ 
                mr: 2,
                cursor: 'pointer',
                textDecoration: 'none'
              }}
            >
              <Avatar 
                src={actor.photo} 
                alt={actor.name}
                sx={{ width: 48, height: 48 }}
              />
            </Box>
            <Box>
              <Box
                component="a"
                href={`/${lng}/actor/${actorSlug}`}
                onClick={handleActorClick}
                sx={{
                  color: '#00AFF0', // OnlyFans blue color
                  textDecoration: 'none',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 0.5
                  }}
                >
                  {actor.name}
                  <Box
                    component="img"
                    src="/images/onlyfans_approved.svg"
                    alt="Verified"
                    sx={{ 
                      width: 16,
                      height: 16,
                      opacity: 0.8
                    }}
                  />
                </Typography>
              </Box>
              <Typography variant="caption" color="text.secondary">
                {format(new Date(post.date), 'MMMM dd, yyyy', { 
                  locale: lng === 'de' ? de : undefined
                })}
              </Typography>
            </Box>
          </Box>

          <Typography sx={{ mb: 2 }}>{post.text}</Typography>

          {post.images && post.images.length > 0 && (
            <Box sx={{ mb: 2 }}>
              {post.images.length > 2 && (
                <Box sx={{ 
                  mb: 2, 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2,
                  bgcolor: 'rgba(255, 255, 255, 0.03)',
                  p: 2,
                  borderRadius: 1
                }}>
                  <Typography variant="body2" color="text.secondary">
                    {t('galleryView.gridSize')}:
                  </Typography>
                  <ToggleButtonGroup
                    value={gridCols}
                    exclusive
                    onChange={(e, newCols) => {
                      if (newCols !== null) {
                        setGridCols(newCols);
                        setStoredGridSize(newCols);
                      }
                    }}
                    size="small"
                  >
                    <ToggleButton value={2}>2x</ToggleButton>
                    <ToggleButton value={3}>3x</ToggleButton>
                    <ToggleButton value={4}>4x</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}
              
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: post.images.length <= 2 
                  ? `repeat(${post.images.length}, 1fr)` 
                  : `repeat(${gridCols}, 1fr)`,
                gap: 2
              }}>
                {visibleImages.map((img, index) => (
                  <Box
                    key={index}
                    onClick={() => handleImageClick(index)}
                    sx={{
                      position: 'relative',
                      paddingTop: '100%',
                      cursor: 'pointer',
                      '&:hover': {
                        '& img': {
                          opacity: 0.8,
                        }
                      }
                    }}
                  >
                    <Box
                      component="img"
                      src={img}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 1,
                        transition: 'transform 0.2s ease-in-out'
                      }}
                    />
                  </Box>
                ))}
              </Box>

              {/* Loading indicator and intersection observer target */}
              {visibleImages.length < post?.images?.length && (
                <Box
                  ref={loadMoreRef}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 2
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Box>
          )}

          {post.videos?.length > 0 && (
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              {post.videos.map((video, index) => (
                <Box key={index} sx={{ width: '100%' }}>
                  <Plyr
                    source={{
                      type: "video",
                      sources: [
                        {
                          src: video,
                          type: "video/mp4"
                        }
                      ]
                    }}
                    options={{
                      controls: [
                        "play-large",
                        "play",
                        "progress",
                        "current-time",
                        "duration", 
                        "mute",
                        "volume",
                        "settings",
                        "fullscreen"
                      ],
                      settings: ["quality", "speed"],
                      quality: {
                        default: 1080,
                        options: [4320, 2880, 2160, 1440, 1080, 720, 480, 360, 240]
                      },
                      fullscreen: {
                        enabled: true,
                        fallback: true,
                        iosNative: true
                      },
                      playsinline: true,
                      clickToPlay: true
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}

          {/* Add buttons */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
            gap: 1, 
            mt: 1.5 
          }}>
            <Button
              startIcon={<ShareIcon />}
              onClick={handleShareClick}
              sx={{
                color: 'text.secondary',
                textTransform: 'none',
                minWidth: { xs: '36px', sm: 'auto' }, // Smaller width on mobile
                padding: { xs: '6px', sm: '6px 16px' }, // Less padding on mobile
                '& .MuiButton-startIcon': {
                  margin: { xs: '0', sm: '0 8px 0 -4px' }, // No margin on mobile
                },
                '&:hover': {
                  color: '#4dadea',
                  bgcolor: 'rgba(77, 173, 234, 0.08)'
                }
              }}
            >
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {t('videoPage.share.button')}
              </Box>
            </Button>

            <Button
              startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              onClick={handleFavoriteClick}
              sx={{
                color: isFavorite ? '#4dadea' : 'text.secondary',
                textTransform: 'none',
                minWidth: { xs: '36px', sm: 'auto' }, // Smaller width on mobile
                padding: { xs: '6px', sm: '6px 16px' }, // Less padding on mobile
                '& .MuiButton-startIcon': {
                  margin: { xs: '0', sm: '0 8px 0 -4px' }, // No margin on mobile
                },
                '&:hover': {
                  color: '#4dadea',
                  bgcolor: 'rgba(77, 173, 234, 0.08)'
                }
              }}
            >
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {isFavorite ? t('favorites.remove') : t('favorites.add')}
              </Box>
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Ad Container */}
      {adsConfig.isAdsEnabled() && (
        <Box
          sx={{
            width: { xs: '300px', md: '728px' }, // 300px on mobile, 728px on desktop
            height: { xs: '100px', md: '90px' }, // 100px on mobile, 90px on desktop
            maxWidth: '100%',
            mx: 'auto',
            mt: 3,
            mb: 2,
            bgcolor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: 1,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box
            ref={(el) => {
              if (!el || adInitialized) return;
              
              // Cleanup old ad scripts first
              document.querySelectorAll('script[data-ad-script="post-provider"]').forEach(script => script.remove());
              document.querySelectorAll('script[data-ad-script="post-initializer"]').forEach(script => script.remove());
              document.querySelectorAll('ins.eas6a97888e').forEach(ins => ins.remove());
              
              // Clear container
              el.innerHTML = '';
              
              // Add provider script
              const providerScript = document.createElement('script');
              providerScript.src = 'https://a.magsrv.com/ad-provider.js';
              providerScript.async = true;
              providerScript.setAttribute('data-ad-script', 'post-provider');
              el.appendChild(providerScript);
              
              // Get window width for responsive ad class and zone
              const isMobile = window.innerWidth < 960; // md breakpoint is 960px
              
              // Add ad tag with responsive class and zone
              const ins = document.createElement('ins');
              ins.className = isMobile ? 'eas6a97888e10' : 'eas6a97888e2';
              ins.dataset.zoneid = isMobile ? '5535254' : '5535252'; // Updated zone IDs
              ins.style.display = 'block';
              ins.style.position = 'absolute';
              ins.style.top = '50%';
              ins.style.left = '50%';
              ins.style.transform = 'translate(-50%, -50%)';
              el.appendChild(ins);
              
              // Add initializer script
              const initScript = document.createElement('script');
              initScript.setAttribute('data-ad-script', 'post-initializer');
              initScript.text = '(AdProvider = window.AdProvider || []).push({"serve": {}});';
              el.appendChild(initScript);
              
              setAdInitialized(true);
            }}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%'
            }}
          />
        </Box>
      )}

      {/* Share Modal */}
      <Modal 
        open={shareModalOpen} 
        onClose={handleCloseShareModal}
        aria-labelledby="share-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: '90%', sm: 400 },
            bgcolor: 'background.paper',
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.02))',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
            p: 3,
            outline: 'none'
          }}
        >
          <Typography 
            id="share-modal-title" 
            variant="h6" 
            component="h2" 
            gutterBottom
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: '#4dadea',
              fontWeight: 500,
              pb: 2,
              borderBottom: 1,
              borderColor: 'divider'
            }}
          >
            <ShareIcon /> {t("onlyFans.share.title")}
          </Typography>

          <List sx={{ pt: 1 }}>
            {[
              { 
                icon: <ContentCopyIcon />, 
                text: t("videoPage.share.copyLink"),
                onClick: handleCopyLink 
              },
              { 
                icon: <XIcon />, 
                text: t("videoPage.share.shareOnX"),
                onClick: handleShareX 
              },
              { 
                icon: <RedditIcon />, 
                text: t("videoPage.share.shareOnReddit"),
                onClick: handleShareReddit 
              }
            ].map((option, index) => (
              <ListItem 
                key={index}
                button 
                onClick={option.onClick}
                sx={{ 
                  borderRadius: 1,
                  mb: index < 2 ? 1 : 0,
                  '&:hover': {
                    bgcolor: 'rgba(77, 173, 234, 0.08)',
                    '& .MuiListItemIcon-root': {
                      color: '#4dadea'
                    }
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                  {option.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={option.text}
                  primaryTypographyProps={{
                    sx: { fontWeight: 500 }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>

      {/* Image Modal */}
      <Modal
        open={selectedImage !== null}
        onClose={handleClose}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)' // Anpassen der Transparenz (0.9 = 90% Deckkraft)
          }
        }}
      >
        <Box 
          onClick={(e) => {
            // Schließe nur wenn direkt auf den Container geklickt wurde, nicht auf Kind-Elemente
            if (e.target === e.currentTarget) {
              handleClose();
            }
          }}
          sx={{ 
            position: 'relative', 
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // Optional: Glaseffekt für den Inhalt
            backdropFilter: 'blur(5px)',
            bgcolor: 'transparent' // Transparenter Hintergrund für den Box-Container
          }}
        >
          {/* Left Navigation Button */}
          {selectedImage > 0 && post.images && (
            <IconButton
              onClick={handlePrevious}
              sx={{
                position: 'fixed',
                left: 16,
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(0,0,0,0.3)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.5)'
                }
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: 40 }} />
            </IconButton>
          )}

          {/* Right Navigation Button */}
          {post.images && selectedImage < post.images.length - 1 && (
            <IconButton
              onClick={handleNext}
              sx={{
                position: 'fixed',
                right: 16,
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(0,0,0,0.3)',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.5)'
                }
              }}
            >
              <ChevronRightIcon sx={{ fontSize: 40 }} />
            </IconButton>
          )}

          {/* Main Image */}
          {selectedImage !== null && post.images && post.images[selectedImage] && (
            <Box
              component="img"
              src={post.images[selectedImage]}
              sx={{
                maxHeight: '90vh',
                maxWidth: '90vw',
                objectFit: 'contain'
              }}
            />
          )}

          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'fixed',
              top: 16,
              right: 16,
              backgroundColor: 'rgba(0,0,0,0.3)',
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.5)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
}

export default OnlyFansPostPage;