import React, { useState, useEffect, useRef, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  IconButton,
  Card,
  CardMedia,
  Tooltip
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CAROUSEL_CONFIG } from '../App';

const VideoCarousel = ({ videos, lng, actors }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInWatchLater, setIsInWatchLater] = useState(false);
  const [fade, setFade] = useState(true);
  const [showPreviews, setShowPreviews] = useState(() => {
    return JSON.parse(localStorage.getItem('carouselShowPreviews') || 'false');
  });
  const [isPaused, setIsPaused] = useState(false);

  const handlePreviewToggle = () => {
    const newValue = !showPreviews;
    setShowPreviews(newValue);
    localStorage.setItem('carouselShowPreviews', JSON.stringify(newValue));
  };

  const getUniqueActorVideos = (videos, count) => {
    const selectedVideos = [];
    const selectedActors = new Set();
  
    for (const video of videos) {
      const hasNewActor = video.actors.some(actorId => !selectedActors.has(actorId));
  
      if (hasNewActor || selectedVideos.length === 0) {
        selectedVideos.push(video);
        video.actors.forEach(actorId => selectedActors.add(actorId));
      }
  
      if (selectedVideos.length >= count) break;
    }
  
    return selectedVideos;
  };
  
  const carouselVideos = useMemo(() => {
    return getUniqueActorVideos(videos, CAROUSEL_CONFIG.VIDEO_COUNT);
  }, [videos]);

  useEffect(() => {
    const watchLaterList = JSON.parse(localStorage.getItem('watchLater') || '[]');
    setIsInWatchLater(watchLaterList.some(v => v.id === carouselVideos[currentIndex]?.id));
  }, [currentIndex, carouselVideos]);

  useEffect(() => {
    if (isPaused) return;

    const interval = setInterval(() => {
      handleNext();
    }, CAROUSEL_CONFIG.SLIDE_DURATION);

    return () => clearInterval(interval);
  }, [carouselVideos.length, isPaused]);

  const handleNext = () => {
    setFade(false);
    
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselVideos.length);
      setFade(true);
    }, 400);
  };

  const handlePrev = () => {
    setFade(false);
    
    setTimeout(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === 0 ? carouselVideos.length - 1 : prevIndex - 1
      );
      setFade(true);
    }, 400);
  };

  const handleDotClick = (index) => {
    if (index === currentIndex) return;
    
    setFade(false);
    
    setTimeout(() => {
      setCurrentIndex(index);
      setFade(true);
    }, 400);
  };

  const handleVideoClick = (video) => {
    if (video.isAdSlide) return;

    const slug = video.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    navigate(`/${lng}/video/${slug}`);
  };

  const handleWatchLater = (e, video) => {
    e.stopPropagation();
    const watchLaterList = JSON.parse(localStorage.getItem('watchLater') || '[]');
    const isAlreadyInList = watchLaterList.some(v => v.id === video.id);
    
    if (!isAlreadyInList) {
      const videoToStore = {
        id: video.id,
        title: video.title,
        thumbnail: video.thumbnail,
        actors: video.actors || [],
        duration: video.duration,
        uploadDate: video.uploadDate
      };
      watchLaterList.push(videoToStore);
      setIsInWatchLater(true);
    } else {
      const index = watchLaterList.findIndex(v => v.id === video.id);
      watchLaterList.splice(index, 1);
      setIsInWatchLater(false);
    }
    localStorage.setItem('watchLater', JSON.stringify(watchLaterList));
  };

  const handleMouseEnter = () => {
    if (window.matchMedia('(min-width: 900px)').matches) {
      setIsPaused(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.matchMedia('(min-width: 900px)').matches) {
      setIsPaused(false);
    }
  };

  const currentVideo = carouselVideos[currentIndex] || {};

  const generateActorSlug = (actor) => {
    return actor?.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  return (
    <Box 
      sx={{ position: 'relative' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 2
        }}
      >
        <Tooltip title={showPreviews ? t('videoCarousel.disablePreviews') : t('videoCarousel.enablePreviews')}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handlePreviewToggle();
            }}
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.6)',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.8)'
              },
              padding: 1
            }}
          >
            {showPreviews ? (
              <VideoSettingsIcon sx={{ fontSize: 20, color: 'primary.main' }} />
            ) : (
              <VideocamOffIcon sx={{ fontSize: 20, color: 'white' }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>

      <Box 
        sx={{ 
          display: { xs: 'flex', md: 'none' },
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 2,
          gap: 1
        }}
      >
        <Tooltip title={showPreviews ? t('videoCarousel.disablePreviews') : t('videoCarousel.enablePreviews')}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handlePreviewToggle();
            }}
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.6)',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.8)'
              },
              padding: 1
            }}
          >
            {showPreviews ? (
              <VideoSettingsIcon sx={{ fontSize: 20, color: 'primary.main' }} />
            ) : (
              <VideocamOffIcon sx={{ fontSize: 20, color: 'white' }} />
            )}
          </IconButton>
        </Tooltip>
        
      </Box>

      <Box
        sx={{
          opacity: fade ? 1 : 0,
          transition: 'opacity 0.4s ease',
          position: 'relative'
        }}
      >
        <Card 
          onClick={() => handleVideoClick(currentVideo)} // Add click handler here
          sx={{ 
            display: 'flex', 
            mb: 2,
            height: { 
              xs: 'auto',
              md: '400px'
            },
            flexDirection: { xs: 'column', md: 'row' },
            cursor: 'pointer', // Add pointer cursor
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(10px)',
            borderRadius: { xs: 1, md: 2 },
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
            overflow: 'hidden'
          }}
        >
          {currentVideo.isAdSlide ? (
            <Box 
              sx={{ 
                width: '100%',
                height: { xs: '320px', md: '100%' },
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center', 
                  justifyContent: 'center'
                }}
              >
                <Box 
                  sx={{ 
                    display: { xs: 'none', md: 'block' },
                    width: '900px',
                    height: '250px'
                  }}
                >
                </Box>

                <Box
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    width: '300px',
                    height: '100px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box 
                onClick={() => handleVideoClick(currentVideo)} // Add click handler here
                sx={{ 
                  position: 'relative',
                  width: { xs: '100%', md: '50%' },
                  height: { xs: '220px', md: '100%' },
                  aspectRatio: { xs: '16/9', md: 'auto' },
                  flexShrink: 0,
                  cursor: 'pointer', // Add pointer cursor
                  '&:hover': {
                    '& .playButton': {
                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                      transform: 'translate(-50%, -50%) scale(1.1)'
                    }
                  }
                }}
              >
                {(showPreviews && currentVideo.previewVideo) ? (
                  <video
                    key={currentVideo.id}
                    autoPlay
                    playsInline
                    muted
                    loop
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  >
                    <source src={currentVideo.previewVideo} type="video/mp4" />
                  </video>
                ) : (
                  <CardMedia
                    component="img"
                    sx={{ 
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    image={currentVideo.thumbnail}
                    alt={currentVideo.title}
                  />
                )}
                
                <IconButton
                  className="playButton"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: 48, md: 80 },
                    height: { xs: 48, md: 80 },
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    border: '4px solid white',
                    transition: 'all 0.2s ease',
                    '& .MuiSvgIcon-root': {
                      fontSize: { xs: 32, md: 60 },
                      color: 'primary.main'
                    }
                  }}
                >
                  <PlayArrowIcon />
                </IconButton>
              </Box>
    
              <Box 
                onClick={() => handleVideoClick(currentVideo)} // Add click handler here
                sx={{ 
                  flex: 1, 
                  p: { xs: 1.5, md: 3 },
                  display: 'flex', 
                  flexDirection: 'column',
                  gap: { xs: 0.5, md: 1 },
                  background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.1))',
                  cursor: 'pointer' // Add pointer cursor
                }}
              >
                <Typography 
                  onClick={() => handleVideoClick(currentVideo)} // Add click handler here
                  variant="h4" 
                  sx={{
                    fontSize: { xs: '1.1rem', md: '2.25rem' },
                    fontWeight: 600,
                    lineHeight: { xs: 1.2, md: 1.3 },
                    mb: { xs: 0.5, md: 1 },
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: { xs: 2, md: 3 },
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer' // Add pointer cursor
                  }}
                >
                  {currentVideo.title}
                </Typography>
    
                <Typography 
                  variant="h6"
                  sx={{ 
                    color: 'primary.main',
                    fontSize: { xs: '0.875rem', md: '1.25rem' },
                    lineHeight: 1.4,
                    mb: { xs: 0.5, md: 1 },
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {currentVideo.actors?.map((actorId, index) => {
                    const actor = actors.find(a => a.id === actorId);
                    if (!actor) return null;
                    
                    return (
                      <React.Fragment key={actorId}>
                        <Box
                          component="span"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/${lng}/actor/${generateActorSlug(actor)}`);
                          }}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {actor?.name}
                        </Box>
                        {index < currentVideo.actors.length - 1 && ', '}
                      </React.Fragment>
                    );
                  })}
                </Typography>
    
                <Typography 
                  onClick={() => handleVideoClick(currentVideo)} // Add click handler here
                  variant="body2"
                  sx={{ 
                    display: '-webkit-box',
                    WebkitLineClamp: { xs: 1, md: 3 },
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    mb: { xs: 1, md: 2 },
                    pr: { md: 6 },
                    fontSize: { xs: '0.75rem', md: '1rem' },
                    color: 'text.secondary',
                    opacity: { xs: 0.8, md: 1 },
                    cursor: 'pointer' // Add pointer cursor
                  }}
                >
                  {currentVideo.description}
                </Typography>
    
                <Box 
                  sx={{ 
                    display: { xs: 'flex', md: 'none' },
                    gap: 1,
                    mt: 'auto',
                    pt: 1
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => handleVideoClick(currentVideo)}
                    sx={{
                      height: "32px",
                      backgroundColor: "primary.main",
                      borderRadius: "8px",
                      textTransform: "none",
                      transition: "all 0.2s ease",
                      flex: 1,
                      fontSize: '0.875rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: 0,
                      px: 1,
                      "&:hover": {
                        backgroundColor: "primary.dark"
                      }
                    }}
                  >
                    {t('videoCarousel.play')}
                  </Button>
              
                  <Button
                    variant={isInWatchLater ? "contained" : "outlined"}
                    startIcon={<WatchLaterIcon />}
                    onClick={(e) => handleWatchLater(e, currentVideo)}
                    sx={{
                      height: "32px",
                      backgroundColor: isInWatchLater ? "primary.main" : "transparent",
                      borderColor: isInWatchLater ? "primary.main" : "rgba(255, 255, 255, 0.2)",
                      borderRadius: "8px",
                      color: isInWatchLater ? "black" : "white",
                      textTransform: "none",
                      flex: 1,
                      fontSize: '0.875rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: 0,
                      px: 1,
                      "&:hover": {
                        backgroundColor: isInWatchLater ? "primary.dark" : "rgba(216, 158, 0, 0.1)",
                        borderColor: "primary.main"
                      }
                    }}
                  >
                    {isInWatchLater ? t('videoCarousel.addedToWatchLater') : t('videoCarousel.watchLater')}
                  </Button>
                </Box>
    
                <Box 
                  sx={{ 
                    mt: 'auto',
                    display: { xs: 'none', md: 'flex' },
                    gap: 2,
                    width: '100%'
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => handleVideoClick(currentVideo)}
                    sx={{
                      height: "36px",
                      backgroundColor: "primary.main",
                      borderRadius: "8px",
                      textTransform: "none",
                      transition: "all 0.2s ease",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%',
                      px: 2,
                      "&:hover": {
                        backgroundColor: "primary.dark",
                        transform: "translateY(-1px)"
                      }
                    }}
                  >
                    {t('videoCarousel.play')}
                  </Button>
                  <Button
                    variant={isInWatchLater ? "contained" : "outlined"}
                    startIcon={<WatchLaterIcon />}
                    onClick={(e) => handleWatchLater(e, currentVideo)}
                    sx={{
                      height: "36px",
                      backgroundColor: isInWatchLater ? "primary.main" : "transparent",
                      borderColor: isInWatchLater ? "primary.main" : "rgba(255, 255, 255, 0.2)",
                      borderRadius: "8px",
                      color: isInWatchLater ? "black" : "white",
                      textTransform: "none",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%',
                      px: 2,
                      "&:hover": {
                        backgroundColor: isInWatchLater ? "primary.dark" : "rgba(216, 158, 0, 0.1)",
                        borderColor: "primary.main"
                      }
                    }}
                  >
                    {isInWatchLater ? t('videoCarousel.addedToWatchLater') : t('videoCarousel.watchLater')}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Card>
      </Box>

      <Box sx={{ 
        position: 'absolute',
        top: { xs: '110px', md: '50%' },
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        px: { xs: 1, md: 2 },
        transform: { xs: 'translateY(0)', md: 'translateY(-50%)' },
        pointerEvents: 'none'
      }}>
        <IconButton
          onClick={handlePrev}
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            '&:hover': {
              bgcolor: currentVideo.isAdSlide ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)',
            },
            opacity: currentVideo.isAdSlide ? 0.5 : 1,
            pointerEvents: currentVideo.isAdSlide ? 'none' : 'auto'
          }}
        >
          <ChevronLeftIcon sx={{ fontSize: { xs: 20, md: 24 } }} />
        </IconButton>
        
        <IconButton
          onClick={handleNext}
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            '&:hover': {
              bgcolor: currentVideo.isAdSlide ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)',
            },
            opacity: currentVideo.isAdSlide ? 0.5 : 1,
            pointerEvents: currentVideo.isAdSlide ? 'none' : 'auto'
          }}
        >
          <ChevronRightIcon sx={{ fontSize: { xs: 20, md: 24 } }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default VideoCarousel;